import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

import { IoMdAdd } from "react-icons/im";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";

import Star from "../../asset/img/star1.png";
import StarFill from "../../asset/img/star_fill.png";
import Plus from "../../asset/img/plus1.png";
import Minus from "../../asset/img/minus1.png";
import TeamCard from "./TeamCard";
import {
  getGamedata,
  getOddsList,
  getGamedatas,
} from "../actions/gameSettings";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../lib/isEmpty";

import SingelEvents from "./SingelEvents";

function CustomToggle({ children, eventKey, icon, handleIconm }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <div type="button" onClick={decoratedOnClick}>
      <span onClick={handleIconm}>{children}</span>
    </div>
  );
}

function AllEvents({ SelectDisplay, handleselect }) {
  const [icon, setIcon] = useState(true);
  const dispatch = useDispatch();
  const { selectedSport } = useSelector((state) => state.sportData);
  useEffect(() => {
    fetchGameData();
  }, []);

  const [isOpenn, setIsOpenn] = useState();
  const [getGameData, setGameData] = useState([]);
  const [getGames, setGames] = useState([]);
  const [eventMessage, seteventMessage] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState([]);
  const [checkInd, setcheckInd] = useState([]);

  const handleSelectAll = (e, item, index) => {
    let IsCheckAll = isCheckAll;
    let CheckInd = [...checkInd];
    if (e.target.checked) {
      IsCheckAll.push(index);
      let ind = CheckInd;
      // item.Child.map((val, index) => {
      //   let isExist = ind.find((val1) => val1 == val.key);
      //   if (isEmpty(isExist)) {
      //     ind.push(val.key);
      //   }
      // });
      item.map((val, index) => {
        let isExist = ind.find((val1) => val1 == val);
        if (isEmpty(isExist)) {
          ind.push(val);
        }
      });
      setcheckInd(ind);
      handleselect(true);
      console.log(ind,"handleSelectAll")
      fetchOddsDetails({ sport_key: ind });
    } else {
      let fidIndx = IsCheckAll.findIndex((val) => val == index);
      IsCheckAll.splice(fidIndx, 1);
      // item.Child.map((it, index) => {
      //   let fidIndxx = CheckInd.findIndex((val) => val == it.key);
      //   CheckInd.splice(fidIndxx, 1);
      //   setcheckInd(CheckInd);
      // });
      item.map((it, index) => {
        let fidIndxx = CheckInd.findIndex((val) => val == it);
        CheckInd.splice(fidIndxx, 1);
        setcheckInd(CheckInd);
      });
      fetchOddsDetails({ sport_key: CheckInd });
      // let ind = [];
      setIsCheckAll(IsCheckAll);

      // setcheckInd(ind);
    }
  };

  function handleChange(e, item, index) {
    let CheckInd = [...checkInd];
    let IsCheckAll = [...isCheckAll];
    if (e.target.checked) {
      CheckInd.push(item.key);
      setcheckInd(CheckInd);
      handleselect(true);
      fetchOddsDetails({ sport_key: CheckInd });
    } else {
      let fidIndxx = IsCheckAll.findIndex((val) => val == index);
      IsCheckAll.splice(fidIndxx, 1);
      setIsCheckAll(IsCheckAll);
      let fidIndx = CheckInd.findIndex((val) => val == item.key);
      CheckInd.splice(fidIndx, 1);
      setcheckInd(CheckInd);
      handleselect(true);
      fetchOddsDetails({ sport_key: CheckInd });
    }
  }

  const toggleAccordionn = (id) => {
    if (isOpenn == id) {
      setIsOpenn();
    } else {
      setIsOpenn(id);
    }
  };

  const handleIconss = () => {
    setIcon(!icon);
  };

  const fetchGameData = async () => {
    try {
      let { status, message, result } = await getGamedatas(dispatch);
      console.log(
        "🚀 ~ fetchGameData ~ status, message, result:",
        status,
        message,
        result
      );
      if (status == true) {
        setGameData(result);
      }
      else{
        seteventMessage(message)
      }
    } catch (err) {
      console.log("err", err);
    }
  };

 

  const fetchOddsDetails = async (data) => {
    try {
      let { status, message, result } = await getOddsList(data, dispatch);
      console.log(
        "🚀 ~ fetchOddsDetails ~ status, message, result:",
        status,
        message,
        result
      );
      if (status == true) {
        // setGameData(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="parlay_tabs all_events mb-5 ">
      <div className="games_sec">
        {getGameData && getGameData.length > 0
          ? getGameData?.map((item, index) => {
              {
                if (selectedSport == item._id) {
                  return (
                    <div className="row mx-auto">
                      <div className="col-xxl-5 col-xl-6 col-lg-5 col-md-7 col-sm-8 col-12">
                        <Accordion
                          // defaultActiveKey={0}
                          defaultActiveKey={SelectDisplay ? 1 : 0}
                          className="custom_accordio custom_all_event_accor"
                          eventKey={item.index}>
                          <Card>
                            <Card.Header className="accor_header">
                              <CustomToggle
                                eventKey={item.index}
                                // onClick={handleIcon()}
                                className="open_close_icon"
                                icon
                                handleIconm={handleIconss}>
                                <div
                                  // onClick={handleIcon()}
                                  onClick={() => toggleAccordionn(item.index)}
                                  className="accor_collapse">
                                  {isOpenn == item.index ? (
                                    <img
                                      src={Minus}
                                      alt="icons"
                                      className="img-fluid plus_minus_icon"
                                    />
                                  ) : (
                                    <img
                                      src={Plus}
                                      alt="icons"
                                      className="img-fluid plus_minus_icon"
                                    />
                                  )}
                                </div>
                              </CustomToggle>

                              <div className="d-flex align-items-center accor_heading_title">
                                <span>{item._id}</span>
                              </div>
                              <span>{item.count}</span>
                            </Card.Header>

                            <Accordion.Collapse eventKey={item.index}>
                              <Card.Body>
                                <div className="accor_body">
                                  <div className="d-flex align-items-center mb-2 check_box">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        handleSelectAll(e, item, index);
                                      }}
                                      checked={
                                        isCheckAll.find(
                                          (val) => val == index
                                        ) != undefined
                                          ? true
                                          : false
                                      }
                                      id={index}
                                      // checked={SelectDisplay && SelectDisplay}
                                    />
                                    <span className="ps-2">
                                      All {item.count}
                                    </span>
                                  </div>

                                  {/* {item.Child &&
                                  item.Child?.map((item, i) => {
                                    return (
                                      <div className="d-flex align-items-center mb-2 check_box">
                                        <input
                                          type="checkbox"
                                          checked={
                                            checkInd &&
                                              !isEmpty(
                                                checkInd.find(
                                                  (val) => val == item.key
                                                )
                                              )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            handleChange(e, item, index);
                                          }}
                                          id={`child${i}`}
                                        />
                                        <span className="ps-2">
                                          {item.title}
                                        </span>
                                      </div>
                                    );
                                  })} */}
                                  <SingelEvents
                                    gameType={item._id}
                                    handleselect={handleselect}
                                  />

                                  {/* <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                // checked={SelectDisplay ? true : false}
                              />
                              <span className="ps-2">{item.subtext2}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                // checked={SelectDisplay ? true : false}
                              />
                              <span className="ps-2">{item.subtext3}</span>
                            </div> */}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    </div>
                  );
                }
                if (selectedSport == "All") {
                  return (
                    <div className="row mx-auto">
                      <div className="col-xxl-5 col-xl-6 col-lg-5 col-md-7 col-sm-8 col-12">
                        <Accordion
                          // defaultActiveKey={0}
                          defaultActiveKey={SelectDisplay ? 1 : 0}
                          className="custom_accordio custom_all_event_accor"
                          eventKey={item.index}>
                          <Card>
                            <Card.Header className="accor_header">
                              <CustomToggle
                                eventKey={item.index}
                                // onClick={handleIcon()}
                                className="open_close_icon"
                                icon
                                handleIconm={handleIconss}>
                                <div
                                  // onClick={handleIcon()}
                                  onClick={() => toggleAccordionn(item.index)}
                                  className="accor_collapse">
                                  {isOpenn == item.index ? (
                                    <img
                                      src={Minus}
                                      alt="icons"
                                      className="img-fluid plus_minus_icon"
                                    />
                                  ) : (
                                    <img
                                      src={Plus}
                                      alt="icons"
                                      className="img-fluid plus_minus_icon"
                                    />
                                  )}
                                </div>
                              </CustomToggle>

                              <div className="d-flex align-items-center accor_heading_title">
                                <span>{item._id}</span>
                              </div>
                              <span>{item.count}</span>
                            </Card.Header>

                            <Accordion.Collapse eventKey={item.index}>
                              <Card.Body>
                                <div className="accor_body">
                                  {/* <div className="d-flex align-items-center mb-2 check_box">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleSelectAll(e, item, index);
                                    }}
                                    checked={
                                      isCheckAll.find((val) => val == index) !=
                                      undefined
                                        ? true
                                        : false
                                    }
                                    id={index}
                                    // checked={SelectDisplay && SelectDisplay}
                                  />
                                  <span className="ps-2">All {item.count}</span>
                                </div> */}

                                  {/* {item.Child &&
                                  item.Child?.map((item, i) => {
                                    return (
                                      <div className="d-flex align-items-center mb-2 check_box">
                                        <input
                                          type="checkbox"
                                          checked={
                                            checkInd &&
                                              !isEmpty(
                                                checkInd.find(
                                                  (val) => val == item.key
                                                )
                                              )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            handleChange(e, item, index);
                                          }}
                                          id={`child${i}`}
                                        />
                                        <span className="ps-2">
                                          {item.title}
                                        </span>
                                      </div>
                                    );
                                  })} */}
                                  <SingelEvents
                                    gameType={item._id}
                                    handleselect={handleselect}
                                  />

                                  {/* <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                // checked={SelectDisplay ? true : false}
                              />
                              <span className="ps-2">{item.subtext2}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                // checked={SelectDisplay ? true : false}
                              />
                              <span className="ps-2">{item.subtext3}</span>
                            </div> */}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    </div>
                  );
                }
              }
            })
          : eventMessage}
      </div>
    </div>
  );
}

export default AllEvents;
