
import { isEmpty } from "../lib/isEmpty";

export const twoFAvalidation = (value) => {
    let errors = {};
    if (isEmpty(value.secret)) {
        errors.secret = "Security Code is required"
    }

    if (isEmpty(value.uri)) {
        errors.uri = "QR Code is required"
    }

    if (isEmpty(value.code)) {
        errors.code = "2FA Code is required"
    }
     else if (isNaN(value.code) || value.code.length > 6) {
        errors.code = "Invalid Code"
    }

    return errors;
}

