import React from "react";
import Sidebar from "../dashboard/Sidebar";
import Header from "../dashboard/Header";
import ChangePasswordContent from "./ChangePasswordContent";

const ChangePassword = () => {
  return (
    <div className="dashboard_sec">
      <div className="container-fluid d-flex px-0">
        <div className="left_side_sec">
          <Sidebar />
        </div>
        <div className="right_side_sec px-2 py-2 px-sm-4 py-sm-4">
          <Header />
          <ChangePasswordContent />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
