import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

import footballVideo from "../../asset/video/football.mp4";

function ModalVideoPlay({ show, handleClose, team }) {
  //   const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="wager_modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{team}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex video_mbl_view justify-content-center align-items-center">
          <ReactPlayer
            url={footballVideo}
            playing={true}
            loop={true}
            controls={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="close_btn common_bg_full_btn"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalVideoPlay;
