import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";


export const getSupportCategory = async () => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.getSupportCategory,
            'method': 'get'
        });
        // console.log("respData",respData)

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'getSupportCategory_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const userTicketList = async () => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.getticket,
            'method': 'get'
        });

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'userTicketList_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}
export const createNewTicket = async (data, dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.createNewTicket,
            'method': 'post',
            'data': data
        });

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'createNewTicket_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}
export const usrReplyMsg = async (data, dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.usrReplyMsg,
            'method': 'post',
            'data': data
        });

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'usrReplyMsgs_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}
export const closeTicket = async (data, dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.closeTicket,
            'method': 'post',
            'data': data
        });

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'closeTicket_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}