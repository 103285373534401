import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import DashboardContent from "./DashboardContent";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";

import Offcanvas from "react-bootstrap/Offcanvas";
import { FaArrowLeft } from "react-icons/fa6";
import DashNew from "./DashNew";
import Loader from "../Loader";

function Dashboard() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [sidebarTransition, setSidebarTransition] = useState(true)

  const [showSidebar, setShowSidebar] = useState(true)

  const handleShowTicket = () => {
    setShowSidebar(!showSidebar)
  }


  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowSidebar(!showSidebar)
  //   }, 1000);
  // }, [showSidebar])

  console.log('sidebarTransition', sidebarTransition)
  return (
    <div className={`dashboard_sec dashboard_sec_new ${!showSidebar ? "dash_transition" : ""}`}>
      <div className="container-fluid d-flex ps-0 pe-0">
        <div className={`left_side_sec ${showSidebar ? "sidebar_view" : "sidebar_hide"}`}>
          {/* <div className={`left_side_sec`}> */}
          <Sidebar />
        </div>
        <div className="right_side_sec ">
          <div className="px-2 py-2 px-sm-4 py-sm-4">
            <Header />
          </div>
          {/* <DashboardContent /> */}
          <DashNew
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            handleShowTicket={handleShowTicket} />
        </div>
      </div>
    </div >


  );
}

export default Dashboard;
