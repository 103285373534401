// Authentication
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION';


// Current User
export const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
export const SET_SPORT_DATA = 'SET_SPORT_DATA'
export const SET_GAME_DATA = 'SET_GAME_DATA';
export const SET_CMS_DATA = 'SET_CMS_DATA';
export const SET_SETTINGS_DATA = "SET_SETTINGS_DATA"
export const SET_ODDS_DATA = "SET_ODDS_DATA"
export const SET_ORDER_DATA = "SET_ORDER_DATA"

export const SELECT_SPORT = 'SELECT_SPORT'
