import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// import images

import logo from "../../asset/svg/logo.svg";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function HomeHeader() {
  const [active, setActive] = useState(null);

  const accountData = useSelector((state) => state.auth);
  // console.log(accountData, accountData.isAuth, "accountData");

  useEffect(() => {
    const scrollActive = () => {
      setActive(window.scrollY > 20);
    };
    window.addEventListener("scroll", scrollActive);
    return () => window.removeEventListener("scroll", scrollActive);
  }, [active]);
  return (
    <Navbar
      expand="xl"
      className={`${active ? "header_nav active" : "header_nav"} `}>
      <Container className="custom_container">
        {/* start of mobile view */}
        <Navbar.Brand href="#" className="d-xl-none">
          <img src={logo} alt="logo" className="img-fluid header_logo" />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="d-xl-none hh_mob_toggle"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="d-xl-none">
          <Nav className="d-xl-none">
            <Nav className="hh_mob_nav">
              <NavLink to="/">Home</NavLink>
              {/* <NavLink to="/">Football</NavLink> */}
              {/* <NavLink to="/">Basketball</NavLink> */}
              <NavLink to="/dashboard">Dashboard</NavLink>
              <NavLink to="/livemobius">Live Mobius</NavLink>
              <NavLink to="/faq">Faq</NavLink>
              <NavLink to="/contact_us">Contact Us</NavLink>
            </Nav>

            {accountData && accountData.isAuth ? (
              ""
            ) : (
              <div className="d-flex align-items-center gap-3 flex-column flex-sm-row mt-2 mt-sm-3 mb-4">
                {/* <Link to="/signup">
                  <button className="img_btn img_btn_fw">Sign up</button>
                </Link> */}
                <Link to="/login">
                  <button className="img_btn img_btn_fw">Login Now</button>
                </Link>
                {/* <Button className="blue_btn">Login Now</Button> */}
              </div>
            )}
          </Nav>
        </Navbar.Collapse>

        {/* end of mobile view */}

        {/* start of web view */}

        <div className="d-none d-xl-flex justify-content-between align-items-center w-100">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" className="img-fluid header_logo" />
          </Navbar.Brand>
          <Nav className="hh_nav">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/">Football</NavLink> */}
            {/* <NavLink to="/">Basketball</NavLink> */}
            <NavLink to="/dashboard">Dashboard</NavLink>
            <NavLink to="/livemobius">Live Mobius</NavLink>
            <NavLink to="/faq">Faq</NavLink>
            <NavLink to="/contact_us">Contact Us</NavLink>
          </Nav>


          {accountData && accountData.isAuth ? (
              ""
            ) : (
              <div className="d-flex align-items-center gap-3 flex-column flex-sm-row mt-2 mt-sm-3 mb-4">
                {/* <Link to="/signup">
                  <button className="img_btn img_btn_fw">Sign up</button>
                </Link> */}
                <Link to="/login">
                  <button className="img_btn img_btn_fw">Login Now</button>
                </Link>
                {/* <Button className="blue_btn">Login Now</Button> */}
              </div>
            )}





          {/* <div className="d-flex align-items-center gap-1 gap-xl-4">
            
            <Link to="/signup">
              <button className="img_btn img_btn_fw">Sign up</button>
            </Link>
            <Link to="/login">
              <button className="img_btn img_btn_fw">Login Now</button>
            </Link>
          </div> */}



        </div>
      </Container>
    </Navbar>
  );
}

export default HomeHeader;
