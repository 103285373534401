import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import logo1 from "../../asset/img/team_logo1.png";
import logo2 from "../../asset/img/team_logo2.png";
import { momentFormat } from "../lib/dateFormat";

function MobiusCard({ Teams }) {
  // const Teams = [
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  // ];



  return (
    <div className="live_mobius mt-4">
      <div class="card_sec">
       

        {
          Teams && Teams.length > 0 ?
          Teams?.map((item, index) => {
            return (
              <>
                <div class="card_details mb-3">
                  <div className="mobius_card" key={index}>
                    {/* <div className="team_logos">
                      <img src={item?.logo1} alt="logo" className="img-fluid" />
                      <span>VS</span>
                      <img src={item?.logo2} alt="logo" className="img-fluid" />
                    </div> */}
                    <div className="details">
                      <div className="mt-3">
                        {/* <p className="mb-0 half">{item?.half}</p>
                        <ProgressBar now={60} className="prog" /> */}
                        <p className="mb-0 leaguage">{item?.sport_title}</p>
                      </div>
                      <div className="my-2 names">
                        <p className="mb-0 d-flex justify-content-between">
                          <span>{item?.home_team}</span>
                          <span>{item?.away_team}</span>
                        </p>
                        <p className="mb-0 d-flex justify-content-between">
                          {/* <span>{item?.away_team}</span> */}
                          {/* <span>{item?.name2No}</span> */}
                        </p>
                      </div>
                      <div className="">
                        <p className="mb-0 date_time mb-2">
                          <span>{momentFormat(item?.commence_time)}</span>
                          {/* <span>{item?.dateNo}</span>
                          <span>{item?.dateName}</span> */}
                        </p>
                        {/* <p className="mb-0 numbers">
                          <span>{item?.bookmakers[0].markets}</span>
                          <span>{item?.number11}</span>
                          <span>{item?.number111}</span>
                        </p> */}
                      </div>
                      {/* <div className="mt-2">
                        <p className="mb-0 date_time mb-2">
                          <span>{item?.commence_time}</span>
                          <span>{item?.timeNo}</span>
                          <span>{item?.timeName}</span>
                        </p>
                        <p className="mb-0 numbers">
                          <span>{item?.commence_time}</span>
                          <span>{item?.number22}</span>
                          <span>{item?.number222}</span>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })
          
          
          
          :"No games found"
        }
      </div>
    </div>
  );
}

export default MobiusCard;
