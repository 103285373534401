// import lib
import { isEmpty } from "../lib/isEmpty";
var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i

const validation = (value, callFrom) => {
    if (callFrom == 'createTicket') {
        return createTicket(value)
    } else if (callFrom == 'replyMsg') {
        return replyMsg(value)
    }
}

export const createTicket = value => {
    let errors = {};

    if (isEmpty(value.categoryId)) {
        errors.categoryId = "Category field is required"
    }

    if (isEmpty(value.message)) {
        errors.message = "Message field is required"
    }

    //    if(value.attachment && value.attachment.size){
    //     if(value.attachment.size > 25000000){
    //         errors.attachment="Please ensure that the file size does not exceed 25MB"
    //     }else if(!imageFormat.test(value.attachment.name))
    //     {
    //         errors.attachment = "INVALID_IMAGE2";

    //     }  
    //    }else{
    //     errors.attachment="REQUIRED";
    //    }

    if (isEmpty(value?.attachment?.name?.match(allowedExtensions))) {
        errors.attachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
    }
    if (value?.attachment !== "" && typeof value.attachment == 'object') {
        if (isEmpty(value?.attachment?.name?.match(allowedExtensions))) {
            errors.attachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
        }
        else {
            var filesize = value?.attachment?.size

            if (filesize > 0.5 * 1024 * 1024) {
                errors.attachment = "Image size should be less than 500 Kb"
            }
        }
    }


    return errors;
}

export const replyMsg = value => {
    let errors = {};

    if (isEmpty(value.rplyMessage)) {
        errors.rplyMessage = "Message field is required"
    }


    // if (isEmpty(value?.rplyattachment?.name?.match(allowedExtensions))) {
    //     errors.rplyattachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
    // }
    if (value?.rplyattachment !== "" && typeof value.rplyattachment == 'object') {
        if (isEmpty(value?.rplyattachment?.name?.match(allowedExtensions))) {
            errors.rplyattachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
        }
        else {
            var filesize = value?.rplyattachment?.size

            if (filesize > 0.5 * 1024 * 1024) {
                errors.rplyattachment = "Image size should be less than 500 Kb"
            }
        }
    }

    return errors;
}

export default validation;