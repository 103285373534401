import axios from 'axios';
import  key  from '../config/config';
import * as api from "../routes/userroutefront";


export const ForgotPassword = async (data) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.forgetPassword,
            'method': 'post',
            'data': data
        });
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'forgetPassword_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
            
        }
    }
}

export const verifyUser = async (data) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.verifyUser,
            'method': 'post',
            'data': data
        });
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'verifyUser_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}


export const resetPassword = async (data) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.resetPassword,
            'method': 'post',
            'data': data
        });
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'resetPassword_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}