import React, { useEffect, useState } from "react";
import Sidebar from "./dashboard/Sidebar";
import Header from "./dashboard/Header";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { MdOutlineFileUpload } from "react-icons/md";
import Emptylogo from "../../asset/img/empty_user.png";
import logo from "../../asset/img/userlogo.jpg";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { isEmpty } from "../lib/isEmpty";
import { toastAlert } from "../lib/toastAlert";
import config from "../config/config";
import { momentFormat } from "../lib/dateFormat";
import { IoMdAdd } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import adminImg from "../../asset/img/logo.png";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";

import InputGroup from "react-bootstrap/InputGroup";

import {
  getSupportCategory,
  userTicketList,
  createNewTicket,
  usrReplyMsg,
  closeTicket,
} from "../actions/supportTicket";
import validation from "../validations/supportTicket";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return <span onClick={decoratedOnClick}>{children}</span>;
}

const initialFormValue = {
  categoryId: " ",
  message: "",
  attachment: "",
};

const rplyinitialValue = {
  rplyMessage: "",
  rplyattachment: "",
};
const SupportTicket = () => {
  const [isOpenn, setIsOpenn] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [ticketList, setTicketList] = useState();
  const [sender, setSender] = useState({});
  const [receiver, setReceiver] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const [rplyFormValue, setRplyFormValue] = useState(rplyinitialValue);

  const [validateError, setValidateError] = useState("");
  const [errors, setErrors] = useState("");
  const { categoryId, message, attachment } = formValue;
  const { rplyMessage, rplyattachment } = rplyFormValue;
  const [account, setAccount] = useState({});

  const accountData = useSelector((state) => state.account);

  useEffect(() => {
    if (accountData) {
      setAccount(accountData);
    }
  }, [accountData]);

  const toggleAccordionn = (id) => {
    if (!isOpenn.includes(id)) {
      setIsOpenn([...isOpenn, id]);
    } else if (isOpenn.includes(id)) {
      setIsOpenn(isOpenn.filter((item) => item !== id));
    }
  };

  const SupportTicket = [
    {
      id: 0,
      subject: "Others",
      ticketid: "#3125467",
      status: "Open",
      createon: "03-11-2023 07:00",
      user: [
        {
          logo: Emptylogo,
          name: "User",
          description: "Test",
        },
      ],
      supportteam: [
        {
          logo: logo,
          name: "Support team",
          reply: "12-07-2024 13:40",
          description: "abc",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
      ],
    },
    {
      id: 1,
      subject: "Withdraw Issue",
      ticketid: "#7265343",
      status: "Closed",
      createon: "16-01-2023 05:10",
      user: [
        {
          logo: Emptylogo,
          name: "User",
          description: "Test",
        },
      ],
      supportteam: [
        {
          logo: logo,
          name: "Support team",
          reply: "12-07-2024 13:40",
          description: "abc",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
      ],
    },
    {
      id: 3,
      subject: "Kyc Issue",
      ticketid: "#5472345",
      status: "Open",
      createon: "26-05-2023 03:20",
      user: [
        {
          logo: Emptylogo,
          name: "User",
          description: "Test",
        },
      ],
      supportteam: [
        {
          logo: logo,
          name: "Support team",
          reply: "12-07-2024 13:40",
          description: "abc",
        },
      ],
    },
    {
      id: 4,
      subject: "Deposit Issue",
      ticketid: "#0963744",
      status: "Closed",
      createon: "15-07-2023 04:45",
      user: [
        {
          logo: Emptylogo,
          name: "User",
          description: "Test",
        },
      ],
      supportteam: [
        {
          logo: logo,
          name: "Support team",
          reply: "12-07-2024 13:40",
          description: "abc",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
      ],
    },
    {
      id: 5,
      subject: "Trading Issue",
      ticketid: "#2765901",
      status: "Open",
      createon: "06-03-2023 12:00",
      user: [
        {
          logo: Emptylogo,
          name: "User",
          description: "Test",
        },
      ],
      supportteam: [
        {
          logo: logo,
          name: "Support team",
          reply: "12-07-2024 13:40",
          description: "abc",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
        {
          logo: logo,
          name: "Support team",
          reply: "13-07-2024 02:04",
          description: "xyz",
        },
      ],
    },
  ];

  const fetchCategory = async () => {
    try {
      const { status, loading, error, result } = await getSupportCategory();
      if (status == true) {
        setCategoryList(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getticketList = async () => {
    try {
      const { status, loading, error, result } = await userTicketList();

      if (status == true) {
        setTicketList(result);
        setSender(result.sender);
        setReceiver(result?.receiver);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchCategory();
    getticketList();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handlerplyChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...validateError, ...{ [name]: "" } };
    setValidateError(error);
    let formData = { ...rplyFormValue, ...{ [name]: value } };
    setRplyFormValue(formData);
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const handlerplyFile = async (e) => {
    const { name, files } = e.target;
    let error = { ...validateError, ...{ [name]: "" } };
    setValidateError(error);
    let formData = { ...rplyFormValue, ...{ [name]: files[0] } };
    setRplyFormValue(formData);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let reqData = {
      categoryId,
      message,
      attachment,
    };
    let validationError = validation(reqData, "createTicket");
    if (!isEmpty(validationError)) {
      setErrors(validationError);
      return;
    }

    let formData = new FormData();
    formData.append("categoryId", categoryId);
    formData.append("message", message);
    formData.append("attachment", attachment);
    try {
      const { status, loading, message, error } = await createNewTicket(
        formData
      );
      if (status == true) {
        setFormValue(initialFormValue);
        // listRef.current.listData()
        fetchCategory();
        toastAlert("success", message, "createTicket");
        if (formValue.attachment != "") {
          setFormValue(initialFormValue);
          // refreshPage()
        }
      } else {
        if (error) {
          setErrors(error);
          return;
        }
        toastAlert("error", message, "createTicket");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const closeTicketFun = (status) => {
    setTicketList((prev) => {
      return { ...prev, status: status };
    });
    window.location.reload();
  };

  const handleCloseTicket = async (ticketid) => {
    // e.preventDefault()
    // ticketList?.ticketList?.length > 0 && ticketList?.ticketList?.map((item, index) => {
    // })
    let reqData = {
      ticketId: ticketid,
    };
    try {
      const { status, loading, error, message, result } = await closeTicket(
        reqData
      );
      if (status == true) {
        toastAlert("success", message, "supportTicket");
        getticketList();

        // setTimeout(() => {
        //   closeTicketFun(result.status)
        // }, 1000)
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const replyChatFun = (replyMsg) => {
    setTicketList({ ...ticketList?.ticketList, ...{ reply: replyMsg } });
  };

  const handlerplySubmit = async (ticketid, adminId) => {
    let reqData = {
      rplyMessage: rplyMessage,
      rplyattachment: rplyattachment,
    };
    let validationError = validation(reqData, "replyMsg");
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      return;
    }
    // var receiverId
    //   ticketList?.ticketList?.length > 0 && ticketList?.ticketList?.map((item, index) => {
    //   item?.reply?.map((el,i)=>{
    //     receiverId =el.receiverId
    //   })
    // })

    let formData = new FormData();
    formData.append("message", rplyMessage);
    formData.append("receiverId", adminId);
    formData.append("ticketId", ticketid);
    formData.append("attachment", rplyattachment);

    try {
      if (isEmpty(validationError)) {
        const { status, loading, error, result } = await usrReplyMsg(formData);
        if (status == true) {
          setRplyFormValue(rplyinitialValue);
          getticketList();
          // replyChatFun(result)
          // file = ''

          // setValidateError(validation(initialFormValue, 'replyMsg'))
        }
        if (error) {
          setValidateError(error);
        }
      } else {
        setValidateError(validationError);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="dashboard_sec">
      <div className="container-fluid d-flex px-0">
        <div className="left_side_sec">
          <Sidebar />
        </div>
        <div className="right_side_sec px-4 py-4">
          <Header />
          <section>
            <div className="support_ticket">
              <h4 className="add_edit_currency_txt mb-4 mt-4">
                Support Ticket
              </h4>
              <div className="create_support">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 grid-margin stretch-card mb-4">
                    <div className="card">
                      <div className="card-body">
                        <form className="forms-sample">
                          <Form.Group>
                            <label htmlFor="symbol" className="mb-1">
                              Ticket For
                            </label>

                            <Dropdown
                              className="support_ticket_drop"
                              onSelect={(e) => {
                                let formData = formValue;
                                formData = { ...formValue, ["categoryId"]: e };
                                setFormValue(formData);
                                setErrors({ ...errors, ["categoryId"]: "" });
                              }}>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                className="custom_support_dropdown"
                                value={categoryId}
                                name={"categoryId"}>
                                {categoryId && !isEmpty(categoryId) ? (
                                  <small>
                                    {
                                      categoryList?.find(
                                        (val) => val._id == categoryId
                                      ).categoryName
                                    }
                                  </small>
                                ) : (
                                  <small>Select Issue </small>
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {categoryList &&
                                  categoryList.length > 0 &&
                                  categoryList.map((item, key) => {
                                    return (
                                      <Dropdown.Item
                                        eventKey={item._id}
                                        value={item._id}>
                                        {item.categoryName}
                                      </Dropdown.Item>
                                    );
                                  })}

                                {/* <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                            {errors.categoryId && (
                              <p className="error-message text-danger mt-2 mb-0">
                                {errors.categoryId}
                              </p>
                            )}
                          </Form.Group>
                          <Form.Group className="mt-3">
                            <label htmlFor="name" className="mb-1">
                              Message to Support Team
                            </label>
                            <Form.Control
                              type="text"
                              id="message"
                              placeholder="Enter Message"
                              value={message}
                              name={"message"}
                              onChange={handleChange}
                            />
                            {errors.message && (
                              <p className="error-message text-danger mt-2 mb-0">
                                {errors.message}
                              </p>
                            )}
                          </Form.Group>

                          <Form.Group className="mt-3">
                            <label className="mb-2">Attachment</label>

                            <div class="upload-btn-wrapper">
                              <button className="btn">
                                <span>
                                  {" "}
                                  {attachment && attachment.name ? (
                                    <small>{attachment.name}</small>
                                  ) : (
                                    <small>Attachment</small>
                                  )}
                                </span>
                                <span className="upload_icon">
                                  <MdOutlineFileUpload size={20} />
                                </span>
                              </button>
                              <input
                                type="file"
                                name="attachment"
                                onChange={(e) => {
                                  handleFile(e);
                                }}
                              />
                            </div>
                            {errors.attachment && (
                              <p className="error-message text-danger mt-2 mb-0">
                                {errors.attachment}
                              </p>
                            )}
                          </Form.Group>

                          <div className="d-flex justify-content-start mt-4">
                            <div className="d-flex">
                              <Link
                                to="/create-ticket"
                                className="text-decoration-none">
                                <button
                                  className="btn img_btn"
                                  value="Create Ticket"
                                  onClick={(e) => {
                                    handleFormSubmit(e);
                                  }}>
                                  Create Ticket
                                </button>
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="more_support">
                      <h5 className="mb-0">More About Support</h5>
                      <hr />
                      <div className="detail_data">
                        {categoryList && categoryList?.length > 0
                          ? categoryList?.map((item, i) => {
                              return (
                                <ul>
                                  <li>
                                    <a
                                      href="/support_ticket#/action-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFormValue({
                                          ...formValue,
                                          ...{ ["categoryId"]: item._id },
                                        });
                                      }}
                                      className="spt_act_lnk">
                                      {i + 1} {item.categoryName}{" "}
                                    </a>
                                  </li>
                                </ul>
                              );
                            })
                          : "No more support category"}
                        {/* <p className="mb-2">1. Deposit Issue</p>
                      <p className="mb-2">2. Withdraw Issue</p>
                      <p className="mb-2">3. Kyc Issue</p>
                      <p className="mb-2">4. Trading Issue</p>
                      <p className="mb-2">5. Others</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="support_details mt-5">
                <h4 className="add_edit_currency_txt mb-4 mt-4">
                  Support Details
                </h4>
                <div className="row ">
                  {/* {SupportTicket.map((item, index) => { */}
                  {/* return ( */}
                  {ticketList && ticketList?.ticketList?.length > 0 ? (
                    ticketList?.ticketList?.map((item, index) => {
                      return (
                        <>
                          <div className="col-12 mb-3" key={index}>
                            {/* <Accordion
                                defaultActiveKey="1"
                                className="mb-3 accordion_sec"
                              >
                                <Card>
                                  <Card.Header className="acc-toggle">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-start align-items-center">
                                        <p className="mb-0 sec">
                                          <p className="mb-0 heading">
                                            Subject
                                          </p>
                                          <span className="content">
                                            {item.categoryName}
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-center align-items-center">
                                        <p className="mb-0 sec">
                                          <p className="mb-0 heading">
                                            Ticket ID
                                          </p>
                                          <span className="content">
                                            {item.tickerId}
                                          </span>
                                        </p>
                                      </div>

                                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-center align-items-center">
                                        <p className="mb-0 sec">
                                          <p className="mb-0 heading">Status</p>
                                          <span className="status">
                                            {item.status}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-end align-items-center">
                                        <CustomToggle eventKey={index}>
                                          <div
                                            onClick={() =>
                                              toggleAccordionn(index)
                                            }
                                            className="plus_minus_icon"
                                          >
                                            {isOpenn.includes(index) ? (
                                              <span className="minus_icon">
                                                <AiOutlineMinus />
                                              </span>
                                            ) : (
                                              <span className="add_icon">
                                                <AiOutlinePlus />
                                              </span>
                                            )}
                                          </div>
                                        </CustomToggle>
                                      </div>
                                    </div>
                                  </Card.Header>

                                  <Accordion.Collapse eventKey={index}>
                                    <Card.Body className="acc_body">
                                      <div className="accor_body_sec">
                                        <p className="create-date mb-0">
                                          Create on:{" "}
                                          {momentFormat(item.createdAt)}
                                        </p>
                                        <hr />
                                      
                                        {!isEmpty(item) &&
                                          item?.reply &&
                                          item?.reply?.length > 0 &&
                                          item?.reply?.map((items, index) => {
                                           
                                            if (items.senderId == sender._id) {
                                              return (
                                                <div className="row user_detail_sec">
                                                  <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
                                                    <div className="d-flex flex-column align-items-center">
                                                      <img
                                                        className="img-xs rounded-circle "
                                                        src={
                                                          account.profile
                                                            ? account.profile
                                                            : Emptylogo
                                                        }
                                                        alt="profile"
                                                      />
                                                      <p className="mb-0">
                                                        User
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-11 col-lg-11 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                                    <div className="">
                                                      <div class="custom_attachment_btn">
                                                        {!isEmpty(
                                                          items?.attachment
                                                        ) && (
                                                          <button className="btn">
                                                            <div>
                                                              <a
                                                                href={`${config.API_URL}/public/support/${items.attachment}`}
                                                                target="_blank"
                                                              >
                                                                <span className="upload_icon me-1">
                                                                  <GrAttachment
                                                                    size={13}
                                                                  />
                                                                  Attachment
                                                                </span>
                                                              </a>
                                                            </div>

                                                          </button>
                                                        )}
                                                      
                                                      </div>
                                                      <p className="mb-0">
                                                        {items?.message}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );

                                            
                                            } else {
                                              return (
                                                <>
                                                  <div
                                                    className="row user_detail_sec mt-4"
                                                    key={index}
                                                  >
                                                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
                                                      <div className="d-flex align-items-center flex-column">
                                                        <img
                                                          className="img-xs rounded-circle "
                                                          src={Emptylogo}
                                                          alt="profile"
                                                        />
                                                        <p className="mb-0 text-center">
                                                          Support Team
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-11 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                                      <div className="w-100">
                                                        <p className="mb-0 adm_rply">
                                                          <span>
                                                            Admin reply on:{" "}
                                                          </span>
                                                          &nbsp;
                                                          <span>
                                                            {momentFormat(
                                                              items?.createdAt
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-0">
                                                          {items?.message}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })}

                                        {item?.status == "open" && (
                                          <div className="admin_reply mt-4">
                                            <h6 className="heading">
                                              Reply to admin
                                            </h6>
                                            <div className="custom_input_upload_file">
                                              <input
                                                type="text"
                                                className="reply-text"
                                                onChange={handlerplyChange}
                                                name="rplyMessage"
                                                value={rplyMessage}
                                              />
                                              {validateError.rplyMessage && (
                                                <p className="error-message text-danger mt-2 mb-0">
                                                  {validateError.rplyMessage}
                                                </p>
                                              )}
                                              <div class="admin_file_attachmentt">
                                                <button className="btn">
                                                  <GrAttachment />
                                                </button>
                                                <input
                                                  type="file"
                                                  name="rplyattachment"
                                                  id="file-input"
                                                  onChange={handlerplyFile}
                                                />
                                              </div>
                                              {rplyattachment &&
                                              rplyattachment.name ? (
                                                <p>{rplyattachment.name}</p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            {validateError.rplyattachment && (
                                              <p className="error-message text-danger mt-2 mb-0">
                                                {validateError.rplyattachment}
                                              </p>
                                            )}

                                            <div className="reply_btn mt-4">
                                              <button
                                                className="reply img_btn"
                                                onClick={() => {
                                                  handlerplySubmit(
                                                    item?._id,
                                                    item?.adminId
                                                  );
                                                }}
                                              >
                                                Reply
                                              </button>
                                              <div
                                                className="navi_links"
                                                onClick={() => {
                                                  handleCloseTicket(item?._id);
                                                }}
                                              >
                                                <a href="#">
                                                  Satisfied,Close this ticket
                                                </a>
                                                &nbsp;
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion> */}
                            <Accordion
                              defaultActiveKey="1"
                              className="mb-3 accordion_sec spt_acc">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <Card className="spc_crd_hds">
                                    <Card.Header className="acc-toggle">
                                      <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 d-flex justify-content-start align-items-center">
                                          <p className="mb-0 sec">
                                            <p className="mb-0 heading">
                                              Subject
                                            </p>
                                            <span className="content">
                                              {item.categoryName}
                                            </span>
                                          </p>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-center align-items-center">
                                          <p className="mb-0 sec">
                                            <p className="mb-0 heading">
                                              Ticket ID
                                            </p>
                                            <span className="content">
                                              {item.tickerId}
                                            </span>
                                          </p>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-center align-items-center">
                                          <p className="mb-0 sec">
                                            <p className="mb-0 heading">
                                              Status
                                            </p>
                                            <span className="status">
                                              {item.status}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-end align-items-center">
                                          <CustomToggle eventKey={index}>
                                            <div
                                              onClick={() =>
                                                toggleAccordionn(index)
                                              }
                                              className="plus_minus_icon">
                                              {isOpenn.includes(index) ? (
                                                <span className="minus_icon">
                                                  <AiOutlineMinus />
                                                </span>
                                              ) : (
                                                <span className="add_icon">
                                                  <AiOutlinePlus />
                                                </span>
                                              )}
                                            </div>
                                          </CustomToggle>
                                        </div>
                                      </div>
                                    </Card.Header>
                                  </Card>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Card className="spc_body_card">
                                    <Card.Body className="acc_body">
                                      <div className="accor_body_sec">
                                        <p className="create-date mb-0">
                                          Create on:{" "}
                                          {momentFormat(item.createdAt)}
                                        </p>
                                        <hr />
                                        {/* User */}

                                        {/* {                                
} */}
                                        {!isEmpty(item) &&
                                          item?.reply &&
                                          item?.reply?.length > 0 &&
                                          item?.reply?.map((items, index) => {
                                            if (items.senderId == sender._id) {
                                              return (
                                                <div className="row user_detail_sec mb-3">
                                                  <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
                                                    <div className="d-flex flex-column align-items-center">
                                                      <img
                                                        className="img-xs rounded-circle "
                                                        src={
                                                          account.profile
                                                            ? account.profile
                                                            : Emptylogo
                                                        }
                                                        alt="profile"
                                                      />
                                                      <p className="mb-0">
                                                        User
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-xl-11 col-lg-11 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                                    <div className="">
                                                      <div class="custom_attachment_btn">
                                                        {!isEmpty(
                                                          items?.attachment
                                                        ) && (
                                                          <button className="btn">
                                                            <div>
                                                              <a
                                                                href={`${config.API_URL}/public/support/${items.attachment}`}
                                                                target="_blank">
                                                                <span className="upload_icon me-1">
                                                                  <GrAttachment
                                                                    size={13}
                                                                  />
                                                                  Attachment
                                                                </span>
                                                              </a>
                                                            </div>

                                                            {/* <span>Attachment</span> */}
                                                          </button>
                                                        )}
                                                        {/* <input type="file" name="myfile" /> */}
                                                      </div>
                                                      <p className="mb-0">
                                                        {items?.message}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );

                                              {
                                                /* Support Team */
                                              }
                                            } else {
                                              return (
                                                <>
                                                  <div
                                                    className="row user_detail_sec mt-4"
                                                    key={index}>
                                                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3">
                                                      <div className="d-flex align-items-center flex-column">
                                                        <img
                                                          className="img-xs rounded-circle "
                                                          src={Emptylogo}
                                                          alt="profile"
                                                        />
                                                        <p className="mb-0 text-center">
                                                          {/* {item?.name} */}
                                                          Support Team
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-11 col-md-10 col-sm-10 col-9 d-flex align-items-center">
                                                      <div className="w-100">
                                                        <p className="mb-0 adm_rply">
                                                          <span>
                                                            Admin reply on:{" "}
                                                          </span>
                                                          &nbsp;
                                                          <span>
                                                            {momentFormat(
                                                              items?.createdAt
                                                            )}
                                                          </span>
                                                        </p>
                                                        {/* <hr className="my-2" /> */}
                                                        <p className="mb-0">
                                                          {items?.message}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })}

                                        {/*admin reply */}
                                        {item?.status == "open" && (
                                          <div className="admin_reply mt-4">
                                            <h6 className="heading">
                                              Reply to admin
                                            </h6>
                                            <div className="custom_input_upload_file">
                                              <input
                                                type="text"
                                                className="reply-text"
                                                onChange={handlerplyChange}
                                                name="rplyMessage"
                                                value={rplyMessage}
                                              />

                                              <div
                                                class="admin_file_attachmentt"
                                                style={{ cursor: "pointer" }}>
                                                <button className="btn">
                                                  <GrAttachment />
                                                </button>
                                                <input
                                                  type="file"
                                                  name="rplyattachment"
                                                  id="file-input"
                                                  onChange={handlerplyFile}
                                                />
                                              </div>
                                            </div>
                                            {rplyattachment &&
                                            rplyattachment.name ? (
                                              <p className="mt-2 mb-3">
                                                {rplyattachment.name}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {validateError.rplyMessage && (
                                              <p className="error-message text-danger mt-2 mb-0">
                                                {validateError.rplyMessage}
                                              </p>
                                            )}
                                            {validateError.rplyattachment && (
                                              <p className="error-message text-danger mt-2 mb-0">
                                                {validateError.rplyattachment}
                                              </p>
                                            )}

                                            <div className="reply_btn mt-4">
                                              <button
                                                className="reply img_btn"
                                                onClick={() => {
                                                  handlerplySubmit(
                                                    item?._id,
                                                    item?.adminId
                                                  );
                                                }}>
                                                Reply
                                              </button>
                                              <div
                                                className="navi_links"
                                                onClick={() => {
                                                  handleCloseTicket(item?._id);
                                                }}>
                                                <a href="#">
                                                  Satisfied,Close this ticket
                                                </a>
                                                &nbsp;
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="col-xs-12"> No more support tickets</div>
                  )}
                  {/* );
                })} */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
