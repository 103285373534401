import React, { useEffect, useState } from "react";
import {
    useParams,
    useHistory,
    useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

// import action
import {
    sentVerifyLink,
    verifyNewEmail,
    confirmMail
} from '../actions/editProfile';

import { toastAlert } from "../lib/toastAlert";

// import lib
// import { toastAlert } from "../lib/toastAlert";

const EmailVerification = (props) => {
    const { authToken } = useParams();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    // state
    const [page, setPage] = useState('loading')

    const verifOldEmail = async () => {
        const data = {
            token: authToken
        }
        const verify = await sentVerifyLink(data);
        if (verify?.status) {
            history.push("/login");
            toastAlert("success", verify?.message, "verifOldEmail");
        } else {
            setPage('error');
            toastAlert("error", verify?.error, "verifOldEmail");
        }
    }

    const verifNewEmail = async () => {
        const data = {
            token: authToken
        }
        const newEmail = await verifyNewEmail(data);
        if (newEmail?.status) {
            history.push("/login");
            toastAlert("success", newEmail?.message, "verifNewEmail");
        } else {
            setPage('error');
            toastAlert("error", newEmail?.error, "verifNewEmail");
            history.push("/login");
        }
    }

    const emailActivation = async () => {
        const data = {
            token: authToken
        }

        const { status, message,error } = await confirmMail(data);
        if (status == true) {
            history.push("/login");
            toastAlert('success', message, 'emailActivation');
        } else if (status == false) {
            history.push("/login");
            toastAlert('error', message, 'emailActivation');
        }
        else {
            setPage('error');
        }
    }

    useEffect(() => {
        let pathname = location.pathname;
        if (pathname == '/verify-old-email/' + authToken) {
            verifOldEmail();
        } else if (pathname == '/verify-new-email/' + authToken) {
            verifNewEmail();
        }
        else if (pathname == '/email-verification/' + authToken) {
            emailActivation()
        }
    }, [authToken])

    return (
        <>
            {
                page == 'loading' && <p>{t('LOADING')}</p>
            }
            {
                page == 'error' && <p>{t('INVALID_URL')}</p>
            }
        </>
    )


}

export default EmailVerification;