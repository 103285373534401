import CryptoJS from 'crypto-js';
import config from '../config/config';



export const encryptObject = (encryptValue) => {
    // console.log('minessss')
    try {
        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(encryptValue), config.CRYPTO_SECRET_KEY).toString();
        return ciphertext
    }
    catch (err) {
        return ''
    }
}