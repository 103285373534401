import React from 'react'
import Lottie from "lottie-react";
import loaderAnimation from '../../asset/json/loader.json';


const Loader = () => {
    return (
        <div className='loading_page'>
            <Lottie
                animationData={loaderAnimation}
                loop={true}
                className="loader_json"
            />
        </div>
    )
}

export default Loader