import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import ReactPlayer from "react-player";

import LiveCards from "../../Cards/LiveCards";

import footballVideo from "../../../asset/video/football.mp4";
import livelogo from "../../../asset/svg/livelogo.svg";
import secondTeamLogo from "../../../asset/svg/secondteamlogo.svg";
import playIcon from "../../../asset/svg/play.svg";
import ModalVideoPlay from "../ModalVideoPlay";
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
import { getLiveOdds } from "../../actions/gameSettings";
import { useSelector } from "react-redux";

const liveScheduleTable = [
  {
    id: 1,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    firstTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
  {
    id: 2,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    firstTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
  {
    id: 3,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    firstTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
  {
    id: 4,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    firstTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
  {
    id: 5,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    firstTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
  {
    id: 6,
    firstTeamLogo: livelogo,
    firstTeamTitle: "New Your",
    firstTeamName: "GIANTS",
    fisrtTeamWin: 3,
    firstTeamOppWin: 0,
    firstTeamCount: "03",
    secondTeamCount: "10",
    secondTeamOppWin: 1,
    secondTeamWin: 2,
    secondTeamTitle: "Cincinnati",
    secondTeamName: "BENGALS",
    secondTeamLogo: secondTeamLogo,
    stadiumName: "Paul Brown stadium",
  },
];

const LiveContent = () => {
  const [show, setShow] = useState(false);
  const [team, setTeam] = useState("");
  const[liveOdds,setliveOdds] = useState()

  const handleClose = () => setShow(false);


  const handleShow = (team) => {
    setShow(true);
    setTeam(team);
  };

  const accountData = useSelector((state) => state.account);
  useEffect(() => {
    fetchLiveOdds();
  }, [accountData]);

  const fetchLiveOdds = async () => {
    try {
      let { status, message, result } = await getLiveOdds();
      if (status == true) {
        setliveOdds(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="mt-5">
      <p className="p_sm_tle">
        Live <span className="p_sm_gray">Matches</span>
      </p>

      <Row>
        <Col xs={12} xxl={6}>
          <div className="custom_react_player">
            <ReactPlayer
              url={footballVideo}
              playing={true}
              loop={true}
              controls={true}
            />
          </div>
        </Col>
        <Col xs={12} xxl={6}>
          <div>
            <LiveCards />
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} xl={6}>
          <Card className="custom_card ">
            <Card.Body className="px-0">
              <div className="px-3 card_tle_holder">
                <p className="p_md_tle mb-0">2023 NFL Schedule</p>
                <p className="p_sm_lgt_gray mb-2">Monday, December 23</p>
              </div>

              <div>
                <Table responsive className="live_table">
                  <tbody>
                    {liveScheduleTable.map((value) => (
                      <tr key={value.id} className="">
                        <td className="">
                          <div className="d-flex align-items-center gap-3">
                            <div className="logo_bg_cle d-flex align-items-center justify-content-center">
                              <img src={value.firstTeamLogo} alt="logo" />
                            </div>
                            <div className="position-relative ">
                              <p className="p_xs_gray_lft  position-absolute mb-0">
                                {value.firstTeamTitle}
                              </p>
                              <h6 className="text-uppercase mb-0">
                                {value.firstTeamName}
                              </h6>
                            </div>
                            <div>
                              <p className="mb-0 p_sm_white">
                                ({value.firstTeamWin} - {value.firstTeamOppWin})
                              </p>
                            </div>
                            <div className="cnt_br_lft position-relative">
                              <p className="mb-0 p_md_white ps-3">
                                {value.firstTeamCount}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className="text-center">
                          <button className="final_btn">Final</button>
                        </td>

                        <td className="">
                          <div className="d-flex align-items-center gap-3">
                            <div className="position-relative cnt_br_rgt">
                              <p className="mb-0 p_md_white pe-3">
                                {value.secondTeamCount}
                              </p>
                            </div>
                            <div>
                              <p className="mb-0 p_sm_white">
                                ({value.secondTeamWin} -{" "}
                                {value.secondTeamOppWin})
                              </p>
                            </div>

                            <div className="position-relative">
                              <p className="p_xs_gray_rgt position-absolute mb-0">
                                {value.secondTeamTitle}
                              </p>
                              <h6 className="text-uppercase mb-0">
                                {value.firstTeamName}
                              </h6>
                            </div>
                            <div className="logo_bg_cle d-flex align-items-center justify-content-center">
                              <img src={value.secondTeamLogo} alt="logo" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <div className="logo_bg_cle ply_icn d-flex align-items-center justify-content-center">
                              <img src={playIcon} alt="logo" />
                            </div>
                            <p className="mb-0">{value.stadiumName}</p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} xl={6} className="mt-4 mt-xl-0">
          <div>
            <p className="p_md_tle mb-0">2023 NFL Schedule</p>
            <Row className="mt-3">
              <Col md={6} xl={12} xxl={6}>
                <div className="custom_react_player_right">
                  {" "}
                  <ReactPlayer
                    url={footballVideo}
                    // playing={true}
                    // loop={true}
                    // controls={true}
                  />
                  <div className="team_section">
                    <p className="name mb-0">Barcelona</p>
                    <p className="date mb-0">03 match</p>
                  </div>
                  <div
                    className="play_pause_btn_modal"
                    onClick={() => handleShow("Barcelona")}
                  >
                    <Button className="d-flex justify-content-center align-items-center">
                      <div className="cus_h">
                        <FaCirclePlay
                          className="play_pause_icon me-2"
                          size={13}
                        />
                        <span className="play_pause_txt">Play Now</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                xl={12}
                xxl={6}
                className="mt-4 mt-md-0 mt-xl-4 mt-xxl-0"
              >
                <div className="custom_react_player_right">
                  {" "}
                  <ReactPlayer
                    url={footballVideo}
                    // playing={true}
                    // loop={true}
                    // controls={true}
                  />
                  <div className="team_section">
                    <p className="name mb-0">Real Madrid</p>
                    <p className="date mb-0">10 match</p>
                  </div>
                  <div
                    className="play_pause_btn_modal"
                    onClick={() => handleShow("Real Madrid")}
                  >
                    <Button className="d-flex justify-content-center align-items-center">
                      <div className="cus_h">
                        <FaCirclePlay
                          className="play_pause_icon me-2"
                          size={13}
                        />
                        <span className="play_pause_txt">Play Now</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ModalVideoPlay show={show} handleClose={handleClose} team={team} />
    </div>
  );
};

export default LiveContent;
