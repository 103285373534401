import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { userLogin, sentOtp } from "../actions/userlogin";
import { validateEmail, validateLogin } from "../validations/login";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";
import { toastAlert } from "../lib/toastAlert";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

const initialFormValue = {
  emailId: "",
  password: "",
  otp: "",
  twoFACode: "",
};

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordView, setPasswordView] = useState(false);
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");
  const [Show2fa, setShow2fa] = useState(false);
  const { emailId, password, otp, twoFACode } = formValue;

  const accountData = useSelector((state) => state.account);

  useEffect(() => {
    if (accountData) {
      setFormValue(accountData);
    }
  }, [accountData]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const GetcodeFn = async () => {
    let value = {
      emailId: emailId,
    };
    var validate = validateEmail(value);

    let encryptToken = {
      token: encryptObject(value),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await sentOtp(encryptToken);
      if (status == true) {
        toastAlert("success", message, "getcode");
      } else {
        if (error) {
          setErrors(error);
        }
        toastAlert("error", message, "getcode");
      }
    } else {
      setErrors(validate);
    }
  };

  const Login = async () => {
    let value = {
      emailId: emailId,
      password: password,
      otp: otp,
      twoFACode: twoFACode,
    };
    var validate = validateLogin(value);
    console.log("🚀 ~ Login ~ validate:", validate)

    let encryptToken = {
      token: encryptObject(value),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await userLogin(encryptToken, dispatch);
      if (status == true) {
        setFormValue(initialFormValue);
        setErrors(validateLogin(initialFormValue));
        toastAlert("success", message, "login");
        history.push("/dashboard");
      } else {
        if (error) {
          setErrors(error);
        }
        if (error?.twoFACode == "Please Enter Your 2 FA Code") {
          setShow2fa(true);
        }
        toastAlert("error", error, "login");
      }
    } else {
      setErrors(validate);
    }
  };

  const clear = () => {
    setFormValue(initialFormValue);
  };

  const handlePasswordIconChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setPasswordView(!passwordView);
  };

  return (
    <section>
      <HomeHeader />

      <div className="container">
        <div className="signup login allsection h-100vh">
          <div className="row">
            <div className="col-md-10 col-lg-10 mx-auto">
              <h4 className="text-center mb-3">LOGIN NOW</h4>
              <div className="signborder">
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <div class="mb-sm-3 position-relative">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Email ID
                      </label>
                      <Row className="align-items-end ">
                        <Col sm={8} lg={12}>
                          <div>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Enter your Email ID"
                              name="emailId"
                              value={emailId}
                              onChange={(e) => handleChange(e)}
                            />{" "}
                            {errors.emailId && (
                              <p className="text-danger mt-2 mb-0 d-sm-none">
                                {errors.emailId}
                              </p>
                            )}
                          </div>
                        </Col>
                        {/* <Col
                          sm={4}
                          lg={3}
                          className="mt-4 mt-sm-0 d-flex justify-content-center">
                          {" "}
                          <button
                            className="btn clear_btn "
                            onClick={() => {
                              GetcodeFn();
                            }}>
                            Get Code
                          </button>
                        </Col> */}
                      </Row>

                      {errors.emailId && (
                        <p className="text-danger mt-2 mb-0 d-none d-sm-block">
                          {errors.emailId}
                        </p>
                      )}
                    </div>
                    {/* <input
                      type="text"
                      class="form-control mt-4"
                      id="name"
                      placeholder="Enter code"
                      name="otp"
                      value={otp}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.otp && (
                      <p className="error-message text-danger mt-2 mb-0">
                        {errors.otp}
                      </p>
                    )} */}
                  </div>
            
                  <div className="col-md-12 mt-4 mt-sm-4">
                    <div class="">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Your Password
                      </label>
                      <Row className="mx-auto">
                        <Col xs={10} className="px-0">
                          <input
                            type={passwordType}
                            class="form-control"
                            id="inputPassword"
                            placeholder="Enter Your Password"
                            name="password"
                            value={password}
                            onChange={(e) => handleChange(e)}
                          />
                        </Col>
                        <Col
                          className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0 pe-3 pe-md-4"
                          xs={2}>
                          <div onClick={handlePasswordIconChange}>
                            {passwordView ? (
                              <FaEye className="" />
                            ) : (
                              <FaEyeSlash className="" />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {errors.password && (
                      <p className="error-message text-danger mt-2 mb-0">
                        {errors.password}
                      </p>
                    )}

                    {Show2fa && (
                      <div className="col-md-12 mt-4 mt-sm-4">
                        <div class="">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Enter Your 2FA Code
                          </label>
                          <input
                            type="text"
                            class="form-control mt-2"
                            id="name"
                            placeholder="Enter 2FA code"
                            name="twoFACode"
                            value={twoFACode}
                            onChange={(e) => handleChange(e)}
                          />
                          {errors.twoFACode && (
                            <p className="error-message text-danger mt-2 mb-0">
                              {errors.twoFACode}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <Link className="forgot mt-2" to="/otpverification">
                      Forgot Password
                    </Link>
                  </div>

                  <div className="col-md-12 text-center">
                    <div class="mt-0">
                      <button
                        className="btn clear_btn  mt-3 mt-sm-3 me-2 me-sm-4"
                        onClick={() => {
                          clear();
                        }}>
                        Clear
                      </button>
                      {/* <Link to="/dashboard"> */}
                      <button
                        className="btn img_btn mt-3 mt-sm-3 me-2"
                        onClick={() => {
                          Login();
                        }}>
                        Login Now
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
