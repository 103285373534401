import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GiHamburgerMenu } from "react-icons/gi";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import userlogo from "../../../asset/img/user_logo.png";
import logo from "../../../asset/img/logo.png";

import Offcanvas from "react-bootstrap/Offcanvas";
import { FaArrowLeft } from "react-icons/fa6";

import Sidebar from "./Sidebar";
import { logout } from "../../actions/logout";
import { getWalletDetails } from "../../actions/gameSettings";

function Header() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const Location = location.pathname;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProfile = () => {
    history.push("/profile");
  };
  const handleChangePassword = () => {
    history.push("/changePassword");
  };
  const handleTwoFactorAuth = () => {
    history.push("/twofactorauth");
  };

  const handleWallet = () => {
    history.push("/wallet");
  };

  const handleSignout = () => {
    logout(history, dispatch);
  };

  const accountData = useSelector((state) => state.account);
  const [walletData, setwalletData] = useState([]);

  useEffect(() => {
    if (accountData) {
      fetchWalletData();
    }
  }, [accountData]);

  const fetchWalletData = async () => {
    try {
      let { status, message, result } = await getWalletDetails({
        email: accountData.emailId,
      });
      if (status == true) {
        setwalletData(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="dashboard_header">
      <Navbar expand="xl" className="d-block d-flex">
        <Container fluid className="position-relative justify-content-between">
          <div className="header_mbl_logo">
            <img src={logo} alt="logo" className="img-fluid" />
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbar_tog">
            <span className="custom_hamburger">
              <GiHamburgerMenu />
            </span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav" className="mob_view ">
            <div className="container-fluid">
              <Nav className="d-flex justify-content-between align-items-center navbar_sec">
                <div className="fst_sec">
                  <NavLink
                    to="/openbet"
                    className={
                      Location == "/openbet"
                        ? "dash_header_active head_txt"
                        : "head_txt"
                    }>
                    Open Bets
                  </NavLink>
                  <NavLink
                    to="/fillopen"
                    className={
                      Location == "/fillopen"
                        ? "head_txt dash_header_active"
                        : "head_txt"
                    }>
                    Fill Open
                  </NavLink>
                  <NavLink
                    to="/history"
                    className={
                      Location == "/history"
                        ? "head_txt dash_header_active"
                        : "head_txt"
                    }>
                    History
                  </NavLink>
                  <NavLink
                    to="/livemobius"
                    className={
                      Location == "/livemobius"
                        ? "head_txt dash_header_active"
                        : "head_txt"
                    }>
                    Live Mobius
                  </NavLink>
                  <Dropdown className="available">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Available($) {walletData?.walletData?.userBalance?.toFixed(2)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="" className="availabel_balance">
                        <Link to="/history" className="text-decoration-none">
                          BALANCE($) :{" "}
                          <span className="value">
                            {walletData?.walletData?.userBalance.toFixed(2)}
                          </span>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="" className="availabel_risk">
                        <Link to="/history" className="text-decoration-none">
                          AT RISK($):{" "}
                          <span className="value">
                            {walletData?.walletData?.riskAmount.toFixed(2)}
                          </span>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* <div className="snd_sec">
                  <Dropdown className="language_pic text-center">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      ENG
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">SPANISH</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">ARABIC</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="trd_sec">
                  <Dropdown className="user_detail_drop text-center">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img
                        src={userlogo}
                        alt="user logo"
                        className="img-fluid user_logo"
                      />
                      {/* Profile */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Link to="/profile" className="text-decoration-none"> */}
                      <Dropdown.Item onClick={handleProfile}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleChangePassword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleWallet}>
                        Wallet
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleTwoFactorAuth}>
                        2FA
                      </Dropdown.Item>
                      {/* </Link> */}
                      {/* <Link to="/" className="text-decoration-none"> */}
                      <Dropdown.Item onClick={handleSignout}>
                        Sign Out
                      </Dropdown.Item>
                      {/* </Link> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Button
        variant="primary"
        onClick={handleShow}
        className="offcanvas_arrow">
        {/* <FaArrowLeft /> */}
        <GiHamburgerMenu />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="offcanvas_mbl_sidebar">
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body>
          <Sidebar onhide={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Header;
