import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory } from "react-router-dom";
import { userRegister } from "../actions/userlogin";
import { validateRegister } from "../validations/register";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";
import { toastAlert } from "../lib/toastAlert";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";

const initialFormValue = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  emailId: "",
  password: "",
  confirmPassword: "",
};

const Signup = () => {
  // password view and hide
  const [passwordType, setPasswordType] = useState("password");
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);


  const calenderClick = useRef(null);
  const handleCalenderClick = () => {
    calenderClick.current.input.focus();
  };

  const handlePasswordIconChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setPasswordView(!passwordView);
  };
  const handleConfirmPasswordIconChange = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
    setConfirmPasswordView(!confirmPasswordView);
  };

  const history = useHistory();
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");
  const {
    firstName,
    lastName,
    dateOfBirth,
    emailId,
    password,
    confirmPassword,
  } = formValue;

  // useEffect(() => {

  // }, []);

  var register = async () => {
    let value = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      emailId: emailId,
      password: password,
      confirmPassword: confirmPassword,
    };
    var validate = validateRegister(value);
    let data = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      emailId: emailId,
      password: password,
    };

    let encryptToken = {
      token: encryptObject(data),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await userRegister(encryptToken);
      if (status == true) {
        history.push("/login");
        // window.location.href ='/login'
        setFormValue(initialFormValue);
        setErrors(validateRegister(initialFormValue));
        toastAlert("success", message, "signup");
       

      } else {
        if (error) {
          setErrors(error);
        }
        toastAlert("error", message, "signup");
      }
    } else {
      setErrors(validate);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const clear = () => {
    setFormValue(initialFormValue);
    // setErrors(validateRegister(initialFormValue))
  };

  return (
    <section>
      <HomeHeader />

      <div className="container">
        <div className="signup allsection h-100vh">
          <div className="row pb-4">
            <div className="col-md-8 col-lg-7 mx-auto">
              <h4 className="text-center mb-3">SIGNUP</h4>
              <div className="signborder">
                <div className="row">
                  <div className="col-md-6 col-sm-6 mt-4 col-12">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter First Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        name="firstName"
                        value={firstName}
                        placeholder="Enter First Name"
                        onChange={(e) => handleChange(e)}
                      />
                      {errors.firstName && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.firstName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 mt-4">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Last Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        value={lastName}
                        name="lastName"
                        placeholder="Enter Last Name"
                        onChange={(e) => handleChange(e)}
                      />
                      {errors.lastName && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-sm-6 mt-4">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Email ID
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Email ID"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6 col-6 mt-4 custom_date_picker">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Your Date of Birth
                      </label>
                      <br></br>
                      <div className="position-relative">
                        <DatePicker
                          ref={calenderClick}
                          showIcon
                          selected={dateOfBirth}
                          name="dateOfBirth"
                          maxDate={new Date()}
                          // value={dateOfBirth}
                          onChange={(date) => {
                            let newDate = new Date(date);
                            newDate.setMilliseconds(0);
                            let error = { ...errors, ...{ dateOfBirth: "" } };
                            setErrors(error);
                            let formdata = formValue;
                            formdata = {
                              ...formValue,
                              dateOfBirth: newDate.getTime(),
                            };
                            setFormValue(formdata);
                          }}
                        />
                        <div
                          className="position-absolute cst_rct_cld_icn"
                          onClick={handleCalenderClick}
                        >
                          <FaCalendar />
                        </div>
                      </div>
                      {errors.dateOfBirth && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.dateOfBirth}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 mt-4 col-12">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Email ID
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Email ID"
                        name="emailId"
                        value={emailId}
                        onChange={(e) => handleChange(e)}
                      />
                      {errors.emailId && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.emailId}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 mt-4 col-12">
                    <div class="">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Your Password
                      </label>
                      <Row className="mx-auto">
                        <Col xs={10} className="px-0">
                          <input
                            type={passwordType}
                            class="form-control"
                            id="inputPassword"
                            placeholder="Enter Your Password"
                            name="password"
                            value={password}
                            onChange={(e) => handleChange(e)}
                          />
                        </Col>
                        <Col
                          className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-center px-0"
                          xs={2}
                        >
                          <div onClick={handlePasswordIconChange}>
                            {passwordView ? (
                              <FaEye className="" />
                            ) : (
                              <FaEyeSlash className="" />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {errors.password && (
                      <p className="error-message text-danger mt-2 mb-0">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6 mt-4 col-12">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Re-Enter Your Password
                      </label>
                      <Row className="mx-auto">
                        <Col xs={10} className="px-0">
                          <input
                            type={confirmPasswordType}
                            class="form-control"
                            id="inputPassword"
                            placeholder="Re-Enter Your Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => handleChange(e)}
                          />
                        </Col>
                        <Col
                          className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-center px-0"
                          xs={2}
                        >
                          <div onClick={handleConfirmPasswordIconChange}>
                            {confirmPasswordView ? (
                              <FaEye className="" />
                            ) : (
                              <FaEyeSlash className="" />
                            )}
                          </div>
                        </Col>
                      </Row>

                      {errors.confirmPassword && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.confirmPassword}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <div class="mt-3 d-flex d-sm-block justify-content-center custom_sign_up_clear_btn">
                      <button
                        className="btn clear_btn  mt-1 me-4"
                        onClick={() => {
                          clear();
                        }}
                      >
                        Clear
                      </button>

                      {/* <Link to="/login" className="text-decoration-none"> */}
                      <button
                        className="btn img_btn mt-1"
                        onClick={() => {
                          register();
                        }}
                      >
                        Sign up
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
