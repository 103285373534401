// import constant
import {
    SET_CMS_DATA
    
} from '../constants';

const initialValue = {
    isAuthenticated: false,
    cmsData:[]
}
const cmsData = (state = initialValue, action) => {
    switch (action.type) {

        case SET_CMS_DATA:
            return {
                ...state,
                cmsData:action.data
            };

        default:
            return state;
    }
}

export default cmsData;