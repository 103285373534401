import { isEmpty } from '../lib/isEmpty';



export const validateProfile = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    var letters = /^[A-Za-z\s]*$/;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i


    if (isEmpty(value.firstName)) {
        errors.firstName = "First Name field is required"
    } else if (!(letters.test(value.firstName))) {
        errors.firstName = "Invalid First Name"
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "Last Name field is required"
    } else if (!(letters.test(value.lastName))) {
        errors.lastName = "Invalid Last Name"
    }

    // if (isEmpty(value.emailId)) {
    //     errors.emailId = "Email ID field is required"
    // } else if (!(emailRegex.test(value.emailId))) {
    //     errors.emailId = "Invalid Email ID"
    // }
    if (value?.profile !== "" && typeof value.profile  == 'object') {
        if (isEmpty(value?.profile?.name?.match(allowedExtensions))) {
            errors.profile = "Please upload file having extensions  .png,.jpeg,.jpg only."
        }
        else {
            var filesize = value?.profile?.size

            if (filesize > 0.5 * 1024 * 1024) {
                errors.profile = "Image size should be less than 100 Kb"
            }
        }
    }

    return errors;
}


export const validatechangePassword = (value) => {
    let errors = {};
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;

    if (isEmpty(value.password)) {
        errors.password = "Password field is required"
    }else if ((value.password.length < 6) || (value.password.length > 18)) {
        errors.password = "Password must be between 6-18 characters"
    }else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, one lowercase, one number, one special character"
    } 

    if (isEmpty(value.newPassword)) {
        errors.newPassword = "New password field is required"
    } else if (!(isEmpty(value.newPassword)) && value.password == value.newPassword) {
        errors.newPassword = "New password should differ from old password"
    }
    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm Password field is required"
    } else if (!(isEmpty(value.confirmPassword)) && value.newPassword != value.confirmPassword) {
        errors.confirmPassword = "New password and confirm password should match"
    }

    return errors;
}