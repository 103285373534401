import React, { useEffect, useState } from "react";
import HomeHeader from "../../common-components/HomeHeader";
import HomeFooter from "../../common-components/HomeFooter";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import WagerModal from "../WagerModal";
import { getAllOrders } from "../../actions/gameSettings";
import { useSelector } from "react-redux";
import { momentFormat } from "../../lib/dateFormat";
import { useDispatch } from "react-redux";

const Openbettable = () => {
  const accountData = useSelector((state) => state.account);
  const [show, setShow] = useState(false);
  const [allOrders, setallOrders] = useState([]);
  const [orderItem, setOrderItem] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAllOrders();
  }, [accountData]);

  const fetchAllOrders = async () => {
    try {
      let { status, message, result } = await getAllOrders(
        {
          email: accountData.emailId,
        },
        dispatch
      );
      if (status == true) {
        setallOrders(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <section>
      <div className="dashboard_content_sec">
        <div className="teams_table_sec openbet mt-5">
          <div className="match_heading">OPEN WAGERS</div>
          <Table responsive>
            {allOrders && allOrders.length > 0 ? (
              <thead>
                <tr>
                  <th className="team_heading_txt">GAMEDATE</th>
                  {/* <th>USER/PHONE</th> */}
                  <th>DATE PLACED</th>
                  <th>SPORT</th>
                  <th className="team_heading_txt">DESCRIPTION</th>
                  <th>RISK/WIN($)</th>
                </tr>
              </thead>
            ) : (
              ""
            )}


            {allOrders && allOrders.length > 0 ? (
              allOrders?.map((item, index) => {
                return (
                  <>
                    <tbody>
                      <tr className="match_collections">
                        <td>
                          <div>
                            <Button
                              onClick={(e) => {
                                handleShow();
                                setOrderItem(item);
                              }}
                              className="open_wager_ticket_btn">
                              TICKET #:{item.ticketId}
                            </Button>
                            <div className="mt-3">
                              <p> {momentFormat(item.gameTime)}</p>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <p> INTERNET / -1</p>
                        </td> */}
                        <td>
                          <p>{momentFormat(item.createdAt)}</p>
                        </td>
                        {item.betType == "Straight" ? (
                          <td>
                            <p>{item.title}</p>
                          </td>
                        ) : (
                          <td>
                            {item.bettingData &&
                              item.bettingData?.map((bettingItem, index) => {
                                return <p>{bettingItem.sport_title}</p>;
                              })}
                          </td>
                        )}

                        {item.betType == "Straight" ? (
                          <td>
                            <p>
                              {item.betType}
                              <br></br>
                              {item.homeTeam} {item.price}
                              <br></br>
                              {item.point ? item.point : ""} {item.pointType}
                              <br></br>
                            </p>
                          </td>
                        ) : (
                          <td>
                            {item.bettingData &&
                              item.bettingData?.map((bettingItem1, index) => {
                                return (
                                  <p>
                                    {bettingItem1.betType}
                                    {bettingItem1.homeTeam} {bettingItem1.price}
                                    <br></br>
                                    {bettingItem1.point
                                      ? bettingItem1.point
                                      : ""}{" "}
                                    {bettingItem1.pointType}
                                  </p>
                                );
                              })}
                          </td>
                        )}

                        <td>
                          <p>
                            {" "}
                            {item.betType == "Straight"
                              ? `${item.wagerAmount} / ${item.payOut}`
                              : `${item.totalLossamount} / ${item.totalWinamount}`}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan={12}>
                          {" "}
                          <div className="d-flex justify-content-between match_total">
                            <h5 className="mb-0">OPEN BETS</h5>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    <div className="text-end">
                      <button
                        className="btn img_btn getbtn mt-4"
                        onClick={handleShow}>
                        Print
                      </button>
                    </div>
                  </>
                );
              })
            ) : (
              <th className="text-center">NO OPEN BETS</th>
            )}
          </Table>
        </div>
      </div>
      <WagerModal show={show} handleClose={handleClose} item={orderItem} />
    </section>
  );
};

export default Openbettable;
