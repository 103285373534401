import jwt from "jsonwebtoken";

import {
    SET_AUTHENTICATION,
    REMOVE_AUTHENTICATION
} from '../constants';


import config from '../config/config';

import { removeAuthorization } from '../config/axios'
import { removeAuthToken } from '../lib/localStorage'

// import lib
import {isEmpty} from "../lib/isEmpty";
import {createSocketUser} from '../config/socketConnectivity'
export const decodeJwt = (token, dispatch) => {
    // console.log("token",token)
    try {
        if (!isEmpty(token)) {
            // console.log("If_token",token,config.CRYPTO_SECRET_KEY)
            token = token.replace('Bearer ', '')
            // console.log("If_token2",token,config.CRYPTO_SECRET_KEY)
            const decoded = jwt.verify(token, config.CRYPTO_SECRET_KEY);
            console.log("decoded------------->",decoded);
            createSocketUser(decoded.id)
            if (decoded) {
                dispatch({
                    type: SET_AUTHENTICATION,
                    authData: {
                        isAuth: true,
                        userId: decoded._id,
                        
                    }
                })
            }
        }
    } catch (err) {
        // console.log("decodeJwt_err",err)
        removeAuthToken()
        removeAuthorization()
        dispatch({
            type: REMOVE_AUTHENTICATION,
            authData: {
                isAuth: false,
            }
        })
    }
}

export const tokenVerify = (token) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt.verify(token, config.CRYPTO_SECRET_KEY);
            if (decoded) {
                return true
            }
        }
        return false
    } catch (err) {
        return false
    }
}