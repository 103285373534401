// import constant
import {
    SET_SETTINGS_DATA
    
} from '../constants';

const initialValue = {
    isAuthenticated: false,
    settingData:[]
}
const settingsData = (state = initialValue, action) => {
    switch (action.type) {

        case SET_SETTINGS_DATA:
            return {
                ...state,
                settingData:action.data
            };

        default:
            return state;
    }
}

export default settingsData;