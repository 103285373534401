// import constant
import {
    SET_SPORT_DATA,
    SELECT_SPORT
    
} from '../constants';

const initialValue = {
    isAuthenticated: false,
    selectedSport:"All"
}
const sportData = (state = initialValue, action) => {
    switch (action.type) {
        case SET_SPORT_DATA:
            return {
                ...state,
                ...action.data
            };
            case SELECT_SPORT:
                return {
                    ...state,
                    selectedSport:action.data
                };
        default:
            return state;
    }
}

export default sportData;