import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MobiusCard from "../MobiusCard";
import WagerType from "../WagerType";
import { Button, Offcanvas } from "react-bootstrap";
import Teams from "../Teams";
import Amount from "../Amount";
import ConfirmWager from "../ConfirmWager";
import Review from "../Review";
import CommonPrevNextBtn from "../CommonPrevNextBtn";
// const ActiveState = ["first", "second", "third"];

import logo1 from "../../../asset/img/team_logo1.png";
import logo2 from "../../../asset/img/team_logo2.png";
import Mobius from "./Mobius";
import AllEvents from "../AllEvents";
import TeamCard from "../TeamCard";
import Ticket from "../Ticket";
import SelectDisplay from "../SelectDisplay";
import { addOrder } from "../../actions/gameSettings";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isEmpty } from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getSingleOpenSpots } from "../../actions/gameSettings";
import { fillOpenSpots } from "../../actions/gameSettings";
import { toast } from "react-toastify";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { getPointGames } from "../../actions/gameSettings";

function DashNew(props) {
  // const [selectId,setSelectId] = useState()
  const [isChecked, setIschecked] = useState(false);
  const [isSelect, setIsselect] = useState([]);
  const [selectValue, setSelectValue] = useState(false);

  const [SelectTeam, setSelectTeam] = useState([]);
  const [errors, setErrors] = useState("");
  const [ticketArr, setticketArr] = useState(0);
  const [selectTicket, setselectTicket] = useState(0);
  const accountData = useSelector((state) => state.account);
  const History = useHistory();
  const { id } = useParams();
  const [openSpot, setOpenSpot] = useState();
  const [ticketId, setTicketId] = useState();

  const { showSidebar, handleShowTicket } = props;

  const [ticketCanvas, setTicketCanvas] = useState(false);

  const handleShowTicketCanvas = () => {
    setTicketCanvas(true);
  };

  const handleCloseTicketCanvas = () => {
    setTicketCanvas(false);
  };

  // const [betArray,setBetArray]=useState([])

  const addNewOrder = async (data) => {
    try {
      if (!isEmpty(id) && !isEmpty(ticketId)) {
        let vald = await ticketValidaion(data);
        if (isEmpty(vald)) {
          let oj = {
            data: data,
            betType: isSelect,
            email: accountData.emailId,
            openSpots: openSpot,
            _id: id,
          };
          let { status, message, result } = await fillOpenSpots(oj);
          if (status == true) {
            History.push("/fillopen");
            toastAlert("success", "Bet Placed Successfully");
          } else {
            toastAlert("error", message);
            // History.push("/fillopen");
          }
        }
      } else {
        let vald = await ticketValidaion(data);
        if (isEmpty(vald)) {
          let oj = {
            data: data,
            betType: isSelect,
            email: accountData.emailId,
            openSpots: openSpot,
          };
          let { status, message, result } = await addOrder(oj);
          if (status == true) {
            console.log(openSpot, "openSpotopenSpot");
            History.push("/openbet");
            toastAlert("success", "Bet Placed Successfully");
          } else {
            toastAlert("error", message);
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const ticketValidaion = async (data) => {
    try {
      let errors = {};
      let datas = [];
      if (isEmpty(isSelect)) {
        errors["betType"] = "betType shouldn't be empty";
        toastAlert("error", "betType shouldn't be empty");
      }
      for (let i = 0; i < data?.length; i++) {
        for (let j = 0; j < data[i]?.length; i++) {
          if (isEmpty(data[i][j]?.Price)) {
            let obj = {};
            obj["Price"] = "bet Amount shouldn't be empty";
            datas[i] = obj;
            errors["data"] = datas;
            // toastAlert("error", "bet Amount shouldn't be empty");
          }
        }
      }
      setErrors(errors);
      return errors;
    } catch (err) {
      console.log(err, "ticketValidaion");
    }
  };

  const handleChecked = (e, index) => {
    if (e == true && index == 0) {
      setIschecked(true);
    } else {
      setIschecked(false);
    }
  };

  const handleselect = (val) => {
    let IsSelect = [...isSelect];
    IsSelect[selectTicket] = val;
    setIsselect(IsSelect);
  };

  const setOpenSpotsCount = (val) => {
    let OpenSpot = [];
    // OpenSpot[selectTicket] = val;
    OpenSpot.push(val);
    setOpenSpot(OpenSpot);
  };

  const handleselect2 = (val) => {
    setSelectValue(val);
  };
  const [isCheckAll, setIsCheckAll] = useState([]);
  const [checkInd, setcheckInd] = useState([]);
  const [isBuyPoints, setIsBuyPoints] = useState();

  const teamsSelect = async (e, item) => {
    let CheckInd = [...checkInd];
    let IsCheckAll = [...isCheckAll];

    if (e.target.checked) {
      let BuyPoints = await fetchPointGames(item.sport_title);
      console.log("🚀 ~ teamsSelect ~ BuyPoints:", BuyPoints)
      let checkArr = isEmpty(CheckInd[selectTicket])
        ? []
        : CheckInd[selectTicket];
      checkArr.push({ ...item, ["buypoint"]: BuyPoints });
      CheckInd[selectTicket] = checkArr;
      console.log(CheckInd, "CheckInd+++++++++++");
      setcheckInd(CheckInd);
    } else {
      let fidIndxx = checkInd[selectTicket].findIndex(
        (val) => val.teamName == item.teamName && val.type == item.type
      );
      CheckInd[selectTicket].splice(fidIndxx, 1);
      setcheckInd(CheckInd);
    }
  };

  const fetchPointGames = async (gameTitle) => {
    try {
      let { status, message, result } = await getPointGames({
        title: gameTitle,
      });
      console.log(status, message, result, "fetchPointGames");
      if (status == true) {
        // setIsBuyPoints(true);
        return true;
      } else {
        // setIsBuyPoints(false);
        return false;
      }
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  const options = [
    { value: "", label: "Select Bet Type" },
    { value: "Straight", label: "Straight" },
    { value: "Parlay", label: "Parlay" },
    // { value: "Parlay", label: "Parlay" },
  ];
  const getOpenSpotDetails = async () => {
    try {
      let { status, message, result } = await getSingleOpenSpots({ _id: id });
      let betArray = [];
      let array = [];
      let opArray = [];
      for (let i = 0; i < result.bettingData.length; i++) {
        let data = {
          CheckId: "00",
          awayTeam: result.bettingData[i].awayTeam,
          gameTime: result.bettingData[i].gameTime,
          homeTeam: result.bettingData[i].homeTeam,
          id: result.bettingData[i].gameId,
          price: result.bettingData[i].price,
          sport_key: result.bettingData[i].keyName,
          sport_title: result.bettingData[i].title,
          teamName: result.bettingData[i].teamName,
          type: result.bettingData[i].oddType,
          Price: result.bettingData[i].wagerAmount,
          payout: result.bettingData[i].payOut,
        };
        betArray.push(data);
      }
      array.push(betArray);
      setcheckInd(array);
      let opt = options.find((val) => val.value == result.betType);
      opArray.push(opt);
      setIsselect(opArray);
      setOpenSpot(result.openSpots);
      setTicketId(result.ticketId);
      // setTicket={() => {
      setticketArr(ticketArr + 1);
      setselectTicket(ticketArr);
      // }}
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      getOpenSpotDetails();
    }
  }, [id]);

  return (
    <>
      <div className="dashboard_content_sec d-flex ">
        {/* <div className="col-xxl-8 col-xl-7 col-lg-8"> */}

        <div
          className={`px-2 py-2 px-sm-4 py-sm-4 dash_content_ticket ${
            showSidebar ? "hide" : "show"
          }`}>
          <h2 className="header_txt my-4">Betting Process</h2>

          {/* start of web screen */}

          <button
            onClick={handleShowTicket}
            className="ticket_canvas_open_button ">
            {showSidebar ? <FaArrowLeft /> : <FaArrowRight />}
          </button>

          {!showSidebar && (
            <button className="sidebar_enable" onClick={handleShowTicket}>
              <GiHamburgerMenu />
            </button>
          )}

          {/* end of web screen */}

          {/* start of mobile screen */}

          <button
            onClick={handleShowTicketCanvas}
            className="ticket_canvas_open_button d-xl-none">
            <FaArrowLeft />
          </button>

          {/* end of mobile screen */}

          <div className="tabs_section">
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
              <Nav variant="pills" className="nav_tabs_bg">
                <Nav.Item>
                  <Nav.Link
                    // eventKey="first"
                    eventKey={0}>
                    All Events
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link
                    // eventKey="second"
                    eventKey={1}>
                    Display All
                  </Nav.Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                  <Nav.Link
                    // eventKey="third"
                    eventKey={2}>
                    Select Display
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey={0}>
                  <AllEvents
                    handleselect={handleselect2}
                    // SelectDisplay={false}
                  />
                  <TeamCard
                    selectValue={checkInd}
                    handleChecked={handleChecked}
                    isChecked={isChecked}
                    isSelect={selectValue}
                    teamsSelect={teamsSelect}
                  />
                </Tab.Pane>
                {/* <Tab.Pane eventKey={1}></Tab.Pane> */}

                <Tab.Pane eventKey={2}>
                  {/* <AllEvents
                  // SelectDisplay={true}
                  /> */}
                  <SelectDisplay />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <div
          className={`dash_ticket_wrapper ${
            showSidebar ? "hideTicket" : "showTicket"
          }`}>
          <Ticket
            isChecked={isChecked}
            handleChecked={handleChecked}
            handleselect={handleselect}
            isSelect={isSelect}
            checkInd={checkInd}
            addNewOrder={addNewOrder}
            errors={errors}
            ticket={ticketArr}
            openSpot={openSpot}
            setOpenSpotsCount={setOpenSpotsCount}
            setTicket={() => {
              setticketArr(ticketArr + 1);
              setselectTicket(ticketArr);
            }}
            setselectTicket={(index) => {
              setselectTicket(index);
            }}
          />
        </div>

        {/* <div className="col-xxl-4 col-xl-5 col-lg-4">
            <Ticket
              isChecked={isChecked}
              handleChecked={handleChecked}
              handleselect={handleselect}
              isSelect={isSelect}
              checkInd={checkInd}
              addNewOrder={addNewOrder}
              errors={errors}
              ticket={ticketArr}
              openSpot={openSpot}
              setOpenSpotsCount={setOpenSpotsCount}
              setTicket={() => {
                setticketArr(ticketArr + 1);
                setselectTicket(ticketArr);
              }}
              setselectTicket={(index) => {
                setselectTicket(index);
              }}
            />
          </div> */}
      </div>

      <Offcanvas
        show={ticketCanvas}
        placement="end"
        onHide={handleCloseTicketCanvas}
        className="ticket_offcanvas d-xl-none">
        <Offcanvas.Body>
          <button
            className="ticket_canvas_close_button"
            onClick={handleCloseTicketCanvas}>
            <IoClose />
          </button>

          <Ticket
            isChecked={isChecked}
            handleChecked={handleChecked}
            handleselect={handleselect}
            isSelect={isSelect}
            checkInd={checkInd}
            addNewOrder={addNewOrder}
            errors={errors}
            ticket={ticketArr}
            openSpot={openSpot}
            setOpenSpotsCount={setOpenSpotsCount}
            setTicket={() => {
              setticketArr(ticketArr + 1);
              setselectTicket(ticketArr);
            }}
            setselectTicket={(index) => {
              setselectTicket(index);
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default DashNew;
