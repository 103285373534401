import React from "react";
import Sidebar from "../dashboard/Sidebar";
import Header from "../dashboard/Header";
import TwoFactorAuthContent from "./TwoFactorAuthContent";
import EditProfileContent from "../EditProfile.js/EditProfileContent";

const EditProfile = () => {
  return (
    <div className="dashboard_sec">
      <div className="container-fluid d-flex px-0 ">
        <div className="left_side_sec">
          <Sidebar />
        </div>
        <div className="right_side_sec px-4 py-4">
          <Header />
          <TwoFactorAuthContent />
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
