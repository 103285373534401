import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getWalletDetails,
  getbetHistoryData,
} from "../../actions/gameSettings";
import { useState, useEffect } from "react";

import Select from "react-select";
import { isEmpty } from "../../lib/isEmpty";
import { momentFormat } from "../../lib/dateFormat";

const optionsOne = [
  { value: "today", label: "Today" },
  { value: "thisWeek", label: "This Week" },
  { value: "thisMonth", label: "This Month" },
];

const placedDataDetails = [
  {
    id: 1,
    userOrPhone: "WIN/LOSS",
    datePlaced: "",
    sport: "Football",
    description: "blackjack",
    riskOrWin: "",
    winOrLoss: "",
    result: "-15",
    datePlacedTwo: "11/30/202306:58 AM",
  },
  {
    id: 2,
    userOrPhone: "WIN/LOSS",
    datePlaced: "",
    sport: "Football",
    description: "blackjack",
    riskOrWin: "",
    winOrLoss: "",
    result: "33",
    datePlacedTwo: "11/30/202306:59 AM",
  },
  {
    id: 3,
    userOrPhone: "WIN/LOSS",
    datePlaced: "",
    sport: "XFER",
    description: "TRANSFER TO AGENT: MICHAEL31",
    riskOrWin: "",
    winOrLoss: "",
    result: "-18",
    datePlacedTwo: "11/30/202306:58 AM",
  },
];

const HistoryContent = () => {
  const accountData = useSelector((state) => state.account);

  const [walletData, setwalletData] = useState([]);
  const [betHistory, setbetHistory] = useState([]);
  const [period, setPeriod] = useState([]);
  useEffect(() => {
    fetchWalletData();
    if (accountData) {
      fetchbetHistoryData();
    }
  }, [accountData]);

  const fetchWalletData = async () => {
    try {
      let { status, message, result } = await getWalletDetails({
        email: accountData.emailId,
      });
      if (status == true) {
        setwalletData(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchbetHistoryData = async (period) => {
    try {
      let { status, message, result } = await getbetHistoryData({
        email: accountData.emailId,
        period: period ? period : "today",
      });
      if (status == true) {
        setbetHistory(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#004062"
          : isSelected
          ? "#004062"
          : isFocused
          ? "#004062"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#ffffff"
          : isFocused
          ? "#ffffff"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 10px",
      // width: "180px",
      backgroundColor: "#070707",
      color: "#ffffff",
      border: "1px solid #0077b6",
      borderRadius: 5,
      fontSize: "16px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#0077b6",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#0077b6",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: "#070707",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "red",
      };
    },
  };
  return (
    <>
      <div className="mt-5">
        <div className="fo_tle_bg py-2 px-2 text-center">
          <p className="mb-0 ">ACCOUNT FIGURES</p>
        </div>

        {/* table */}

        <div>
          <Table responsive className="custom_table">
            <thead>
              <tr>
                <th>CURRENT BALANCE($)</th>
                <th>AVAILABLE BALANCE($)</th>
                <th className="">AMOUNT AT RISK($)</th>
                <th className="">LAST WEEK`S WIN/LOSS FIGURE($)</th>
                <th className="">THIS WEEK`S WIN/LOSS FIGURE($)</th>
                {/* <th className="">BONUS POINTS</th> */}
                {/* <th className="">FREE PLAYS</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {walletData?.walletData?.userBalance &&
                    walletData?.walletData?.userBalance?.toFixed(2)}
                </td>
                <td>
                  {walletData?.walletData?.userBalance &&
                    walletData?.walletData?.userBalance?.toFixed(2)}
                </td>
                <td>
                  {walletData?.walletData?.riskAmount &&
                    walletData?.walletData?.riskAmount?.toFixed(2)}
                </td>
                <td>
                  {walletData?.lastWeekFigure &&
                    walletData?.lastWeekFigure?.toFixed(2)}
                </td>
                <td>
                  {walletData?.thisWeekFigure &&
                    walletData?.thisWeekFigure?.toFixed(2)}
                </td>
                {/* <td>0</td>
                <td>0</td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className="mt-5">
        <Select
          styles={stylesgraybg}
          // menuIsOpen={true}
          options={optionsOne}
          className="react_slt select_dropdown_modal"
          placeholder="Today"
          onChange={(selectedOption) => {
            setPeriod(selectedOption.value);
            fetchbetHistoryData(selectedOption.value);
          }}
        />{" "}
      </div>

      <div className="mt-5">
        <div className="fo_tle_bg py-2 px-2 text-center">
          <p className="mb-0 ">
            HISTORY | FROM {momentFormat(betHistory?.startDate)} TO{" "}
            {momentFormat(betHistory?.endDate)}
          </p>
        </div>

        {/* table */}

        <div>
          <Table responsive className="custom_table history_table">
            <thead>
              <tr>
                <th></th>
                <th className="text-center">BEGINNING OF WEEK</th>
                <th>MON</th>
                <th className="">TUE</th>
                <th className="">WED</th>
                <th className="">THU</th>
                <th className="">FRI</th>
                <th className="">SAT</th>
                {/* <th className="">TOTAL</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>WIN/LOSS ($)</td>
                <td className="text-center">
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 0))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 0)
                        ?.winorloss}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 1))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 1)
                        ?.winorloss}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 2))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 2)
                        ?.winorloss}{" "}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 3))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 3)
                        ?.winorloss}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 4))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 4)
                        ?.winorloss}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 5))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 5)
                        ?.winorloss}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.weekDaysData?.find((val) => val.day == 6))
                    ? 0
                    : betHistory?.weekDaysData?.find((val) => val.day == 6)
                        ?.winorloss}
                </td>
                {/* <td>0</td> */}
                {/* <td>
                  {" "}
                  {betHistory?.dayString == "sunday"
                    ? betHistory.winorloss
                    : "-"}
                </td> */}
              </tr>
              <tr>
                <td>CASH IN ($)</td>
                <td className="text-center">
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 0))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 0)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 1))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 1)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 2))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 2)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 3))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 3)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 4))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 4)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 5))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 5)?.inAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashIn?.find((val) => val.day == 6))
                    ? 0
                    : betHistory?.cashIn?.find((val) => val.day == 6)?.inAmount}
                </td>
                {/* <td>0</td> */}
              </tr>
              <tr>
                <td>CASH OUT ($)</td>
                <td className="text-center">
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 0))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 0)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 1))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 1)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 2))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 2)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 3))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 3)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 4))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 4)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 5))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 5)
                        ?.outAmount}
                </td>
                <td>
                  {betHistory &&
                  isEmpty(betHistory?.cashOut?.find((val) => val.day == 6))
                    ? 0
                    : betHistory?.cashOut?.find((val) => val.day == 6)
                        ?.outAmount}
                </td>
                {/* <td>0</td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className="mt-5">
        <div>
          <Table responsive className="custom_table historyDls_table">
            <thead>
              <tr>
                <th className="">SPORT</th>

                <th className="">TEAM NAME</th>
                {/* <th className="">DESCRIPTION</th> */}
                <th className="">ODD TYPE</th>
                {/* <th className="">WIN ($)</th> */}

                <th className="">WIN/LOSS</th>
                <th className="">RISK/WIN($)</th>
                <th className="">AMOUNT($)</th>
                {/* <th className="">SCORE</th> */}
                <th className="">GAME TIME</th>
                <th>DATE PLACED</th>
              </tr>
            </thead>
            <tbody>
              {/* {betHistory?.totalData?.length > 0 &&
                betHistory?.totalData?.map((value) => (
                  <tr>
                    <td>{value?.teamName}</td>
                    <td>{momentFormat(value?.createdAt)}</td>
                    <td>{value?.title}</td>
                    <td>{value?.keyName}</td>
                    <td>
                      {value?.winStatus == "WIN"
                        ? value.payOut
                        : value.wagerAmount}
                    </td>
                    <td>{value?.winStatus}</td>
                    <td>
                      {value?.teamName == value?.homeTeam
                        ? value.score1
                        : value.score2}
                    </td>
                    <td>{momentFormat(value?.gameTime)}</td>
                  </tr>
                ))} */}

              {betHistory && betHistory?.totalData?.length > 0 ? (
                betHistory?.totalData?.map((value) => (
                  <tr>
                    <td>{value?.title}</td>
                    <td>{value?.teamName}</td>

                    {/* <td>{value?.keyName}</td> */}
                    <td>{value?.oddType}</td>

                    {/* <td>{value.wagerAmount}</td> */}

                    <td>
                      {value?.winStatus == "" ? "Pending" : value?.winStatus}
                    </td>
                    <td> {value.wagerAmount + "/" + value.payOut}</td>
                    {/* <td>
                      {value?.teamName == value?.homeTeam
                        ? value.score1
                        : value.score2}
                    </td> */}

                    <td>
                      {" "}
                      {value.winStatus == "WIN"
                        ? value.payOut
                        : value.winStatus == "LOSS"
                        ? -value.wagerAmount
                        : "PENDING"}
                    </td>

                    <td>{momentFormat(value?.gameTime)}</td>
                    <td>{momentFormat(value?.createdAt)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center p-3">
                    No History To Show
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default HistoryContent;
