import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAllOrders } from "../../actions/gameSettings";
import { getParlayOpenSpots } from "../../actions/gameSettings";
import { Link, useNavigate, useHistory } from "react-router-dom";

const FillOpenContent = () => {
  const accountData = useSelector((state) => state.account);

  const [allOrders, setallOrders] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchAllOrders();
  }, [accountData]);

  const fetchAllOrders = async () => {
    try {
      let { status, message, result } = await getParlayOpenSpots({
        email: accountData.emailId,
      });
      if (status == true) {
        setallOrders(result);
        console.log(result, "result+++++++++");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const moveDash = async (id) => {
    try {
      history.push(`/dashboard/${id}`);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="mt-5">
      <div className="fo_tle_bg py-2 px-2 text-center">
        <p className="mb-0 ">PLEASE SELECT THE BET TO FILL</p>
      </div>

      {/* table */}

      <div>
        <Table responsive className="custom_table">
          {allOrders && allOrders.length > 0 ? (
            <thead>
              <tr>
                <th>Sport</th>
                <th>Description</th>
                <th className="text-center">Risk/win($)</th>
                <th className="text-center"></th>
              </tr>
            </thead>
          ) : (
            ""
          )}

          <tbody>
            {allOrders && allOrders?.length > 0 ? (
              allOrders?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      {item?.betType == "Straight" ? (
                        <p>{item?.title}</p>
                      ) : (
                        item?.bettingData &&
                        item?.bettingData.map((bettingItem, index) => {
                          return <p>{bettingItem?.title}</p>;
                        })
                      )}
                    </td>
                    <td>
                      <p>
                        {item?.betType}{" "}
                        {item?.bettingData ? item?.bettingData.length : ""}
                      </p>
                      {item?.bettingData &&
                        item?.bettingData.map((bettingItem1, index) => {
                          return (
                            <>
                              {bettingItem1.homeTeam}
                              <p>
                                {bettingItem1.type}
                                <br></br>
                                {bettingItem1.price}{" "}
                                {bettingItem1.point ? bettingItem1.point : ""}{" "}
                                {bettingItem1.pointType}
                                <br></br>({bettingItem1.homeTeam} VRS{" "}
                                {bettingItem1.awayTeam})
                              </p>
                            </>
                          );
                        })}
                    </td>
                    <td className="text-center">
                      <p>
                        {item?.wagerAmount} / {item?.payOut}
                      </p>
                    </td>
                    <td className="text-center">
                      {item.isOpenFilled == false ? (
                        <div>
                          <button
                            className="img_btn"
                            onClick={() => moveDash(item._id)}>
                            Select
                          </button>
                        </div>
                      ) : item.isOpenScore == false ? (
                        "Pending"
                      ) : (
                        `Completed (${item.winStatus})`
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <th className="text-center">NO BETS TO FILL</th>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default FillOpenContent;
