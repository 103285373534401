import React from "react";
import logo1 from "../../asset/img/teamlogo_4.png";
import logo2 from "../../asset/img/teamlogo_5.png";
import logo3 from "../../asset/img/teamlogo_7.png";
import logo4 from "../../asset/img/teamlogo_6.png";
import logo5 from "../../asset/img/teamlogo_8.png";
import logo6 from "../../asset/img/teamlogo_3.png";

function Review() {
  const Teams = [
    {
      logo: logo1,
      name: "NFL [303] SEATTLE SEAHAWKS +340 (-107)",
      amount: "-$1.070",
      average: "low",
    },
    {
      logo: logo2,
      name: "DALLAS COWBOYS +2 (-102)",
      amount: "$1.000",
    },
    {
      logo: logo3,
      name: "Oklahoma -5 (-112)",
      amount: "$1.000",
    },
    {
      logo: logo4,
      name: "Wisconsin +5 (-107)",
      amount: "$1.000",
    },
    {
      logo: logo6,
      name: "Syracuse -4% (-107)",
      amount: "$1.000",
    },
    {
      logo: logo5,
      name: "Boise St -4 (-107)",
      amount: "$1.000",
    },
  ];
  return (
    <div className="review_tabs mt-5">
      <div className="card_sec">
        <div className="header">December 12th</div>
        {Teams.map((item) => {
          return (
            <div className="content">
              <div className="teams_list">
                <div className="team_logo">
                  <img src={item.logo} alt="logo" className="img-fluid " />
                </div>
                <span className="name">{item.name}</span>
              </div>
              <span className={item.average ? "amount_low" : "amount_average"}>
                {item.amount}
              </span>
            </div>
          );
        })}
        <div className="content_total">
          <span className="total_wagers">Total Wagers: 6</span>
          <div className="">
            <span className="day_totals me-3">Day Total:</span>
            <span className="amount">$4.930</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
