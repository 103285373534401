import React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { validatechangePassword } from "../../validations/editProfile";
import { isEmpty } from "../../lib/isEmpty";
import { encryptObject } from "../../lib/crypto";
import { toastAlert } from "../../lib/toastAlert";
import {changePassword} from "../../actions/editProfile"
import { removeAuthToken } from "../../lib/localStorage";


const initialFormValue = {

  password: "",
  newPassword: "",
  confirmPassword:""
};


const ChangePasswordContent = () => {
  const history = useHistory();
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordView, setPasswordView] = useState(false);
  const [newPasswordView, setNewPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);
  const [errors, setErrors] = useState("");
  const {
    password,
    newPassword,
    confirmPassword
  } = formValue;


  const handlePasswordIconChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setPasswordView(!passwordView);
  };

  const handleNewPasswordIconChange = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
    } else {
      setNewPasswordType("password");
    }
    setNewPasswordView(!newPasswordView);
  };

  const handleConfirmPasswordIconChange = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
    setConfirmPasswordView(!confirmPasswordView);
  };


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };



  var changePassFn = async () => {
    let value = {
      password: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword
    };
    var validate = validatechangePassword(value);
   
    let data = {
      password: password,
      newPassword: newPassword,
    };

    let encryptToken = {
      token: encryptObject(data),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await changePassword(encryptToken);
      if (status == true) {
        // setErrors(validatechangePassword(initialFormValue));
        toastAlert("success", 'Password Changed,please login with new password', "changePassword");
        removeAuthToken()
        history.push("/login");
      } else {
        if (error) {
          console.log("errr", error)
          setErrors(error);
        }
        toastAlert("error", error, "changePassword");
      }
    } else {
      setErrors(validate);
    }
  };

  const clear = () => {
    setFormValue(initialFormValue);
  };

  return (
    <div className="container">
      <div className="signup login allsection h-100vh">
        <div className="row">
          <div className="col-md-10 col-lg-10 mx-auto">
            <h4 className="text-center mb-3">CHANGE PASSWORD</h4>
            <div className="signborder">
              <div className="row">
                <div className="col-md-12 mt-4 mt-sm-4">
                  <div class="">
                    <label for="exampleFormControlInput1" class="form-label">
                      Old Password
                    </label>
                    <Row className="mx-auto">
                      <Col xs={10} sm={11} className="px-0">
                        <input
                          type={passwordType}
                          class="form-control"
                          id="inputPassword"
                          placeholder="Enter your password"
                          name="password"
                          value={password}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col
                        className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0 pe-3 pe-md-4"
                        xs={2}
                        sm={1}
                      >
                        <div onClick={handlePasswordIconChange}>
                          {passwordView ? (
                            <FaEye className="" />
                          ) : (
                            <FaEyeSlash className="" />
                          )}
                        </div>
                      </Col>
                    </Row>

                  </div>
                
                  <p className="error-message text-danger mt-2 mb-0"> {errors.password}</p>
                  {/* <Link className="forgot mt-2" to="/otpverification">
                    Forgot Password
                  </Link> */}
                </div>
                <div className="col-md-12 mt-4 mt-sm-4">
                  <div class="">
                    <label for="exampleFormControlInput1" class="form-label">
                      New Password
                    </label>
                    <Row className="mx-auto">
                      <Col xs={10} sm={11} className="px-0">
                        <input
                          type={newPasswordType}
                          class="form-control"
                          id="inputPassword"
                          placeholder="Enter your new password"
                          name="newPassword"
                          value={newPassword}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col
                        className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0 pe-3 pe-md-4"
                        xs={2}
                        sm={1}
                      >
                        <div onClick={handleNewPasswordIconChange}>
                          {newPasswordView ? (
                            <FaEye className="" />
                          ) : (
                            <FaEyeSlash className="" />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <p className="error-message text-danger mt-2 mb-0">{errors.newPassword}</p>
                  {/* <Link className="forgot mt-2" to="/otpverification">
                    Forgot Password
                  </Link> */}
                </div>



                <div className="col-md-12 mt-4 mt-sm-4">
                  <div class="">
                    <label for="exampleFormControlInput1" class="form-label">
                      Confirm Password
                    </label>
                    <Row className="mx-auto">
                      <Col xs={10} sm={11} className="px-0">
                        <input
                          type={confirmPasswordType}
                          class="form-control"
                          id="inputPassword"
                          placeholder="Enter your Confirm password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col
                        className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0 pe-3 pe-md-4"
                        xs={2}
                        sm={1}
                      >
                        <div onClick={handleConfirmPasswordIconChange}>
                          {confirmPasswordView ? (
                            <FaEye className="" />
                          ) : (
                            <FaEyeSlash className="" />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <p className="error-message text-danger mt-2 mb-0">{errors.confirmPassword}</p>
                 
                </div>


                <div className="col-md-12 text-center">
                  <div class="mt-0 d-flex flex-column-reverse align-items-center flex-sm-row justify-content-sm-end">
                    <button className="btn clear_btn  mt-3 mt-sm-3 me-2 me-sm-4" onClick={()=>{clear()}}>
                      Clear
                    </button>
                    <button className="btn img_btn mt-3 mt-sm-3 me-2" onClick={()=>{changePassFn()}}>
                     Changepassword
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordContent;
