import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import socialone from "../../asset/svg/social1.svg";
import socialtwo from "../../asset/svg/social2.svg";
import socialthree from "../../asset/svg/social3.svg";
import socialfour from "../../asset/svg/social4.svg";
import config from '../config/config';

const HomeFooter = () => {

  const [cmsData, setCmsData] = useState({});
  const [siteSetting, setSiteSetting] = useState({});

  const Data = useSelector((state) => state.cmsData);
  // console.log("setCmsData-------->", Data?.cmsData);

  useEffect(() => {
    if (Data) {
      setCmsData(Data?.cmsData)
    }

  }, [Data]);

  const siteData = useSelector((state) => state.settingsData);
  // console.log("siteData-------->", siteData?.settingData);

  useEffect(() => {
    if (siteData) {
      setSiteSetting(siteData?.settingData)
    }

  }, [siteData]);


  return (
    <div className="footer_bg mt-5 pt-5">
      <Container className="">
        <Row>
          {cmsData?.length > 0 && cmsData?.map((item) => {
            // console.log("item", item)
            if (item?.identifier == 'About us') {
              return (
                <>
                  <Col xs={12} sm={6} lg={4} xl={3}>
                    <h3>{item?.title} </h3>
                    {/* About us */}
                    {/* {cmsData.length > 0 && cmsData?.find((val)=>(val.identifier === 'About us')).title}
            { console.log("val",cmsData?.length > 0 && cmsData?.find((val)=>(val.identifier == 'About us')).title)} */}

                    <p>
                      {ReactHtmlParser(item?.content)}
                      {/* Whether you're a sports bettor, a horse racing fan
                ,mobiusgames.com is your number one source of the online betting
                gambling entertainment. From our industry-leading odds,
                world-class sportsbook and feature-rich casino games, to our
                fully-loaded racebook, we've been providing players from around
                the world with an unparalleled gaming experience for several
                years. */}

                    </p>
                  </Col>
                </>
              )
            }

            if (item?.identifier == 'Why Play With Us') {
              return (
                <>

                  <Col xs={12} sm={6} lg={4} xl={3} className="mt-3 mt-sm-0">
                    <h3>
                      {/* Why Play With Us */}
                      {item?.title}
                    </h3>
                    <p>
                      {ReactHtmlParser(item?.content)}
                      {/* Mobiusgames.com is the gold standard in online gaming, dedicated
                      to provide its customers with a rewarding, exciting and secure
                      environment for online wagering. With top-of-the-line software and
                      unique gaming opportunities, mobiusgames.com takes its players to
                      the next level in online gambling. */}
                    </p>
                  </Col>
                </>
              )
            }
            if (item?.identifier == 'Our Services') {
              return (
                <>
                  <Col xs={12} sm={6} lg={4} xl={3} className="mt-3 mt-lg-0">
                    <h3>
                      {item?.title}
                      {/* Our Services */}
                    </h3>
                    <p>
                      {ReactHtmlParser(item?.content)}
                      {/* Our Sportsbook page offers the best odds in all major sports and
                      leagues including the NBA, NHL, MLB, NCAA basketball and football,
                      golf, soccer, boxing and much more. Our Racebook page is the best
                      in the world, with up-to-post-time odds and the most major/minor
                      track listings offered in the industry. */}
                    </p>
                  </Col>
                </>
              )
            }
            if (item.identifier == 'Contact Us') {
              return (
                <>
                  <Col xs={12} sm={6} lg={4} xl={3} className="mt-3 mt-xl-0">
                    <h3>
                      {ReactHtmlParser(item?.title)}
                      {/* Contact Us */}
                    </h3>
                    {ReactHtmlParser(item?.content)}

                    {/* <p>Wagering:</p>
                    <p>1-800-735-3965, 1-855-848-3289</p> */}

                    <div className="d-flex align-items-center gap-3 mb-3">
                      <a href={siteSetting && siteSetting.linkedinLink} target="_blank">
                        <img src={`${config.API_URL}/public/settings/${siteSetting.linkedinIcon}`} alt="" className="img_hvr_eft" />
                      </a>
                      <a href={siteSetting && siteSetting.skypeLink} target="_blank">
                        <img src={`${config.API_URL}/public/settings/${siteSetting.skypeIcon}`} alt="" className="img_hvr_eft" />
                      </a>
                      <a href={siteSetting && siteSetting.twitterLink} target="_blank">
                        <img src={`${config.API_URL}/public/settings/${siteSetting.twitterIcon}`} alt="" className="img_hvr_eft" />
                      </a>
                      <a href={siteSetting && siteSetting.telegramLink} target="_blank">
                        <img src={`${config.API_URL}/public/settings/${siteSetting.telegramIcon}`} alt="" className="img_hvr_eft" />
                      </a>
                    </div>
                  </Col>
                </>
              )
            }
          })}
        </Row>
      </Container>
      <div className="copyright py-2">
        <Container>
          <p className="text-center mb-0">
            Mobiusgames.com © 2023 All Rights Reserved.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default HomeFooter;
