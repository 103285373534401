import { combineReducers } from "redux";
import authReducer from "./authReducers";
import account from "./account";
import gameData from "./gameData";
import sportData from "./sportData";
import cmsData from "./cmsData";
import settingsData from "./siteSettings";
import oddsData from "./oddsData";
import ordersData from "./ordersData";

export default combineReducers({
  auth: authReducer,
  account,
  gameData,
  sportData,
  cmsData,
  settingsData,
  oddsData,
  ordersData});
