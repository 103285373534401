import key from "../config/config";
import * as api from "../routes/userroutefront";
import axios from "../config/axios";
import { SET_GAME_DATA, SET_ODDS_DATA, SET_ORDER_DATA } from "../constants";

export const setGameData = (data) => {
  // console.log("dats----------------------->", data);
  return {
    type: SET_GAME_DATA,
    data: data,
  };
};

export const setOddsData = (data, message) => {
  // console.log("setOddsDatasetOddsData", data);
  return {
    type: SET_ODDS_DATA,
    data: data,
    message: message,
  };
};
export const setOrdersData = (data) => {
  // console.log("setOrdersData", data);
  return {
    type: SET_ORDER_DATA,
    data: data,
  };
};

export const getGamedata = async (data, dispatch) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getGamedata,
      method: "post",
      data,
    });
    // dispatch(setGameData(respData?.data?.result));
    // console.log(respData?.data?.result, "respData?.data?.result");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getGamedata_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getGamedatas = async (dispatch) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getGamedatas,
      method: "post",
    });
    dispatch(setGameData(respData?.data?.result));
    // console.log(respData?.data?.result, "respData?.data?.result");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getGamedata_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getOddsList = async (data, dispatch) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getSportsOddList,
      method: "post",
      data: data,
    });
    dispatch(setOddsData(respData?.data?.result,""));
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    console.log(err,err?.response?.data?.message, "getGamedata_error");
    dispatch(setOddsData([], err?.response?.data?.message));
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const addOrder = async (data) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.addbetOrder,
      method: "post",
      data: data,
    });
    // console.log("sportData", respData.data.data);
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.data,
    };
  } catch (err) {
    // console.log(err, "sportData_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getAllOrders = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getbetOrder,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getWalletDetails = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getWallet,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getbetHistoryData = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getbetHistory,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getLiveOdds = async () => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getLiveOdds,
      method: "post",
      // data: data,
    });

    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getParlayOrders = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getParlayBettingOrders,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getParlayOpenSpots = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getAllOpenSpots,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getSingleOpenSpots = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getOneOpenSpots,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const fillOpenSpots = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.addOpenSpots,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getPointGames = async (data) => {
  // console.log(data, "datagetAllOrders");
  try {
    let respData = await axios({
      url: key.API_URL + api.getPointsGames,
      method: "post",
      data: data,
    });

    // console.log(respData, "respDatarespDatarespData");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, "getAllOrders_error");
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      result: err?.response?.data?.result,
      error: err?.response?.data?.errors,
    };
  }
};
