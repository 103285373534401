import React from "react";
import logo1 from "../../asset/img/team_logo1.png";
import logo2 from "../../asset/img/team_logo2.png";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { momentFormat } from "../lib/dateFormat";
import Loader from "./Loader";
import { getPointGames } from "../actions/gameSettings";
function TeamCard({
  handleChecked,
  isChecked,
  isSelect,
  teamsSelect,
  selectValue,
}) {
  // const {gameData} = useSelector((state) => state.gameData);
  const [pageLoading, setPageLoading] = useState(false);
  const { oddData, oddMessage } = useSelector((state) => state.oddsData);

  console.log(oddData,"oddMessage++++++++++")

  return (
    <div className="team_card">
      {isSelect && oddData ? (
        <div className="mg_table_wrapper">
          <div className="mg_table_card mb-4">
            <div className="mg_table_wrapper table-responsive">
              <table className="table mg_table">
                {oddData?.length > 0 ? (
                  oddData[0]?.map((item, index) => {
                    return (
                      <>
                        <thead>
                          <tr>
                            <th
                              colSpan={5}
                              className="mg_table_title_secondary">
                              {item?.sport_title}: SPREADS - MONEY LN - TOTALS
                            </th>
                          </tr>
                          <tr className="">
                            <th className="mg_table_title_primary">Date</th>
                            <th className="mg_table_title_primary">Team</th>
                            <th className="mg_table_title_primary">M Line</th>
                            <th className="mg_table_title_primary">Spread</th>
                            <th className="mg_table_title_primary">Total</th>
                          </tr>
                        </thead>

                        {item?.bookmakers?.map((items, i) => {
                          return (
                            <>
                              <tbody className="mg_table_body odd">
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="mg_table_title_third">
                                    Bookmakers: {items?.title}
                                  </td>
                                </tr>
                                <tr className="mg_gray_row ">
                                  <td>
                                    <p className="mb-0">
                                      {momentFormat(item?.commence_time)}
                                    </p>
                                  </td>
                                  <td>{item?.home_team}</td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {console.log("market", market)}
                                          {market?.key == "h2h" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.home_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    price:
                                                      market?.outcomes[0]
                                                        ?.price,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,

                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(e, teamObj);
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.home_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[0]?.price}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {market?.key == "spreads" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                className="mt-1 mt-md-0"
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.home_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    price:
                                                      market?.outcomes[0]
                                                        ?.price,
                                                    point:
                                                      market?.outcomes[0]
                                                        ?.point,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,
                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(e, teamObj);
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.home_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[0]?.price}{" "}
                                                {market?.outcomes[0]?.point}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {market?.key == "totals" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                className="mt-1 mt-md-0"
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.home_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    pointType:
                                                      market?.outcomes[0]?.name,
                                                    price:
                                                      market?.outcomes[0]
                                                        ?.price,
                                                    point:
                                                      market?.outcomes[0]
                                                        ?.point,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,
                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(e, teamObj);
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.home_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[0]?.name}{" "}
                                                {market?.outcomes[0]?.price}{" "}
                                                {market?.outcomes[0]?.point}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                </tr>

                                <tr className="mg_gray_row">
                                  <td>
                                    <p className="mb-0">
                                      {momentFormat(item?.commence_time)}
                                    </p>
                                  </td>
                                  <td>{item?.away_team}</td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {console.log("market2", market)}
                                          {market?.key == "h2h" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.away_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    price:
                                                      market?.outcomes[1]
                                                        ?.price,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,
                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(e, teamObj);
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.away_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[1]?.price}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {market?.key == "spreads" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.away_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    price:
                                                      market?.outcomes[1]
                                                        ?.price,
                                                    point:
                                                      market?.outcomes[1]
                                                        ?.point,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,
                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(
                                                    e,
                                                    teamObj,
                                                    index
                                                  );
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.away_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[1]?.price}{" "}
                                                {market?.outcomes[1]?.point}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {items?.markets?.map((market, index) => {
                                      return (
                                        <>
                                          {market?.key == "totals" && (
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                className="mt-1 mt-md-0"
                                                type="checkbox"
                                                id={index}
                                                onChange={(e) => {
                                                  let teamObj = {
                                                    teamName: item.away_team,
                                                    homeTeam: item.home_team,
                                                    awayTeam: item.away_team,
                                                    type: market?.key,
                                                    pointType:
                                                      market?.outcomes[1]?.name,
                                                    price:
                                                      market?.outcomes[1]
                                                        ?.price,
                                                    point:
                                                      market?.outcomes[1]
                                                        ?.point,
                                                    sport_key: item.sport_key,
                                                    sport_title:
                                                      item.sport_title,
                                                    gameTime:
                                                      item.commence_time,
                                                    id: item.id,
                                                    CheckId: `${i}${index}`,
                                                  };
                                                  teamsSelect(e, teamObj);
                                                }}
                                                checked={selectValue?.find(
                                                  (val) =>
                                                    val.teamName ==
                                                      item.away_team &&
                                                    val.CheckId ==
                                                      `${i}${index}`
                                                )}
                                              />
                                              <p className="mb-0">
                                                {market?.outcomes[1]?.name}{" "}
                                                {market?.outcomes[1]?.price}{" "}
                                                {market?.outcomes[1]?.point}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <span>
                    {oddMessage}
                  </span>
                )}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
}

export default TeamCard;
