import key from "../config/config";
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";
import { setAuthToken } from "../lib/localStorage";
import { SET_USER_ACCOUNT } from "../constants";
import { decodeJwt } from "./jsonwebToken";

export const setAccountData = (data) => {
  // console.log('dats----------------------->',data)
  return {
    type: SET_USER_ACCOUNT,
    data: {
      userId: data._id,
      firstName: data.firstName,
      lastName: data.lastName,
      emailId: data.emailId,
      dateOfBirth: data.dateOfBirth,
      password: data.password,
      profile: data.profile,
      google2Fa: data.google2Fa,
    },
  };
};

export const userRegister = async (data) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.register,
      method: "post",
      data: data,
    });
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userRegister_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const sentOtp = async (data) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getCode,
      method: "post",
      data: data,
    });
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userLogin_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const userLogin = async (data, dispatch) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.login,
      method: "post",
      data: data,
    });
    if (respData?.data?.status == true) {
      setAuthorization(respData.data.token);
      decodeJwt(respData.data.token, dispatch);
      setAuthToken(respData.data.token);
      dispatch(setAccountData(respData.data?.result));
    }
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userLogin_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getUserData = async (dispatch) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getUser,
      method: "get",
    });
    // console.log("respData",respData)
    decodeJwt(respData.data.token, dispatch);
    dispatch(setAccountData(respData.data?.result));
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userLogin_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const getUserWalletData = async () => {
  try {
    let respData = await axios({
      url: key.API_URL + api.getUserWallet,
      method: "get",
    });
    console.log(respData, "respData.data?.result");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userLogin_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};

export const sendClaimRequest = async (data) => {
  try {
    let respData = await axios({
      url: key.API_URL + api.senduserClaimRequest,
      method: "post",
      data,
    });
    console.log(respData, "respData.data?.result");
    return {
      status: respData?.data?.status,
      message: respData?.data?.message,
      result: respData?.data?.result,
    };
  } catch (err) {
    // console.log(err, 'userLogin_error')
    return {
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err?.response?.data?.errors,
    };
  }
};
