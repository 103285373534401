export const twoFAStatus = (type, callFrom) => {
    if (type == 'Enabled' && callFrom == 'status') {
        return "ENABLED"
    } else if (type == 'Disabled' && callFrom == 'status') {
        return "DISABLED"
    } else if (type == 'Enabled' && callFrom == 'button') {
        return "DISABLE"
    } else if (type == 'Disabled' && callFrom == 'button') {
        return "ENABLE"
    } else {
        return ""
    }
}