import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import profileImg from "../../../asset/img/baseball.jpg";

import { Link, useHistory } from "react-router-dom";
import { validateProfile } from "../../validations/editProfile";
import { editProfile } from "../../actions/editProfile";
import { isEmpty } from "../../lib/isEmpty";
import { encryptObject } from "../../lib/crypto";
import { toastAlert } from "../../lib/toastAlert";
import { FaPen } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import fileObjectUrl from "../../lib/imgFile";
import EditEmailModal from "../../Modals/EditEmailModal.js";
import { getUserWalletData } from "../../actions/userlogin";

const initialFormValue = {
  firstName: "",
  lastName: "",
  emailId: "",
  profile: "",
};

const EditProfileContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");

  const [editEmail, setEditEmail] = useState(false);
  const [walletData, setWalletData] = useState();
  const { firstName, lastName, emailId, profile } = formValue;

  const accountData = useSelector((state) => state.account);

  useEffect(() => {
    getWalletDetails();
    if (accountData) {
      setFormValue(accountData);
    }
  }, [accountData]);

  const getWalletDetails = async () => {
    try {
      let { status, message, result } = await getUserWalletData();
      if (status == true) {
        setWalletData(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;

    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const Profile = async () => {
    try {
      let value = {
        firstName: firstName,
        lastName: lastName,
        // emailId: emailId,
        profile: profile,
      };
      var validate = validateProfile(value);

      var formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      // formData.append("emailId", emailId);
      formData.append("profile", profile);

      // let encryptToken = {
      //   token: encryptObject(formData),
      // };

      if (isEmpty(validate)) {
        let { status, error, message } = await editProfile(formData, dispatch);
        if (status == true) {
          // setFormValue(initialFormValue);
          toastAlert("success", message, "profile");
          // history.push("/dashboard")
        } else {
          if (error) {
            setErrors(error);
          }
          toastAlert("error", message, "profile");
        }
      } else {
        setErrors(validate);
      }
    } catch (err) {
      console.log("profile_err", err);
    }
  };

  return (
    <div className="mt-5">
      <Row className="justify-content-center ">
        <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
          <p className="text-center ep_tle">Edit Profile</p>
          <Card className="custom_card">
            <Card.Body className="p-3 p-md-5">
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <div className="position-relative">
                    <div className="ep_img_upld">
                      {profile !== "" ? (
                        <img
                          src={fileObjectUrl(profile)}
                          alt="profile"
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={profileImg}
                          alt="profile"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="ep_upl_icn position-absolute d-flex align-items-center justify-content-center">
                      <input
                        type="file"
                        name="profile"
                        className="ep_img_upl_inpt"
                        onChange={(e) => {
                          handleFile(e);
                        }}
                      />

                      <i class="fa-solid fa-pen"></i>
                    </div>
                  </div>
                  {errors.profile && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.profile}
                    </p>
                  )}
                </Col>

                <Col xs={12} sm={6} className="mt-4">
                  <Form className="custom_form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1">
                      <Form.Label className="custom_label">
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        className="custom_input"
                        value={firstName}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                  </Form>
                  {errors.firstName && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.firstName}
                    </p>
                  )}
                </Col>

                <Col xs={12} sm={6} className="mt-4">
                  <Form className="custom_form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1">
                      <Form.Label className="custom_label">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        className="custom_input"
                        value={lastName}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                  </Form>
                  {errors.lastName && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.lastName}
                    </p>
                  )}
                </Col>
                <Col xs={12} className="mt-3">
                  <Form className="custom_form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1">
                      <Form.Label className="custom_label">Email</Form.Label>
                      <Row className="mx-auto">
                        <Col xs={10} sm={11} className="px-0">
                          {" "}
                          {/* <Form.Control
                            type="email"
                            name="emailId"
                            className="custom_input"
                            value={emailId}
                            onChange={(e) => handleChange(e)}
                          /> */}
                          {/* <input
                            type="email"
                            className="form-control custom_input"
                            placeholder="Enter Email"
                            onChange={(e) => handleChange(e)}
                          /> */}
                          <div className="custom_input d-flex align-items-center">
                            {" "}
                            <p
                              className="mb-0 px-3 w-100"
                              style={{
                                display: "inline-block",
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}>
                              {emailId}
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={2}
                          sm={1}
                          className="d-flex align-items-center justify-content-end  custom_input">
                          <div>
                            <FaPen
                              className="edt_pfl_pen_icn"
                              onClick={() => setEditEmail(true)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                  {errors.password && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.password}
                    </p>
                  )}
                </Col>
                {/* <Col xs={12} className="mt-3">
                  <Form className="custom_form">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="custom_label">Password</Form.Label>
                      <div className="custom_input">
                        <Row
                          className="mx-auto align-items-center"
                          style={{ height: "50px" }}
                        >
                          <Col xs={8} sm={9} className="">
                            <Form.Control
                              type="password"
                              className="custom_password_inpt"
                            />
                          </Col>
                          <Col
                            xs={4}
                            sm={3}
                            className="d-flex justify-content-end"
                          >
                            <button className="upt_btn ">Update</button>
                          </Col>
                        </Row>
                      </div>
                    </Form.Group>
                  </Form>
                </Col> */}
              </Row>

              <div className="d-flex align-items-center gap-3 justify-content-center mt-3">
                <Link to="/dashboard">
                  <button className="img_btn_invert">Cancel</button>
                </Link>
                <button
                  className="img_btn"
                  onClick={() => {
                    Profile();
                  }}>
                  Save
                </button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <EditEmailModal
        // emailContent={emailContent}
        editEmail={editEmail}
        setEditEmail={setEditEmail}
      />
    </div>
  );
};

export default EditProfileContent;
