const BASE_URL = "/api";

export const register = BASE_URL + "/register";
export const login = BASE_URL + "/login";
export const getCode = BASE_URL + "/getCode";
export const forgetPassword = BASE_URL + "/forgetPassword";
export const verifyUser = BASE_URL + "/verifyUser";
export const resetPassword = BASE_URL + "/resetPassword";
export const getUser = BASE_URL + "/getUser";
export const editProfile = BASE_URL + "/editProfile";
export const editEmail = BASE_URL + "/editEmail";
export const sentVerifyLink = BASE_URL + "/sentVerifyLink";
export const verifyNewEmail = BASE_URL + "/verifyNewEmail";
export const changePassword = BASE_URL + "/changePassword";
export const sportData = BASE_URL + "/sportsData";
export const enable2fa = BASE_URL + "/enable2fa";
export const disable2fa = BASE_URL + "/disable2fa";
export const get2fa = BASE_URL + "/get2fa";

export const getGamedata = BASE_URL + "/get-Gamedata-User";
export const getGamedatas = BASE_URL + "/get-Gamedata";


export const confirmMail = BASE_URL + "/confirm-mail";
export const contactus = BASE_URL + "/contactus";
export const getticket = BASE_URL + "/getticket";
export const createNewTicket = BASE_URL + "/createNewTicket";
export const usrReplyMsg = BASE_URL + "/usrReplyMsg";
export const closeTicket = BASE_URL + "/closeTicket";
export const getSupportCategory = BASE_URL + "/getSupportCategory";
export const getFaq = BASE_URL + "/getFaq";
export const getCmsList = BASE_URL + "/getCmsList";
export const getSiteSetting = BASE_URL + "/getSiteSetting";
export const getuserCmsImage = BASE_URL + "/getuserCmsImage";
export const newsLetterUserAdd = BASE_URL + "/add-newsLetter-user";
export const getSportsOddList = BASE_URL + "/get-Odds-List";
export const addbetOrder = BASE_URL + "/create-Betting-Order";
export const getbetOrder = BASE_URL + "/getAll-Betting-Orders";
export const getWallet = BASE_URL + "/get-Wallet-Details";
export const getbetHistory = BASE_URL + "/weekly-History";
export const getLiveOdds = BASE_URL + "/get-LiveOdds";
export const getParlayBettingOrders = BASE_URL + "/get-ParlayBetting-Orders";

export const getAllOpenSpots = BASE_URL + "/getparlay-openspots";
export const getOneOpenSpots = BASE_URL + "/get-one-openorder";
export const addOpenSpots = BASE_URL + "/add-OpenSpots";

export const getUserWallet = BASE_URL + "/get-user-wallet";
export const senduserClaimRequest = BASE_URL + "/withdraw-request";

export const getPointsGames = BASE_URL + "/get-Game-ForPoints";
