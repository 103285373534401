import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

// import images

import banner from "../../asset/svg/bannerimg.png";
import baseball from "../../asset/img/baseball.jpg";
import basketball from "../../asset/img/baseketball.jpg";
import football from "../../asset/img/football.jpg";
import footballone from "../../asset/img/footballone.jpg";
import footballtwo from "../../asset/img/footballtwo.jpg";
import basketballone from "../../asset/img/basketballone.jpg";
import basketballtwo from "../../asset/img/basketballtwo.jpg";
import swiper2 from "../../asset/img/swiper2.png";
import swiper3 from "../../asset/img/swiper3.png";
import ReactPlayer from "react-player";

import footballVideo from "../../asset/video/football.mp4";
import VideoSec from "./VideoSec";
import { getCmsImage } from "../actions/cms";
import config from "../config/config";
import { useLocation, useHistory } from "react-router-dom";

const Home = () => {
  const cmsurl = `${config.API_URL}/public/cms/`;
  const [game, setGame] = useState("Basketball");
  const [cmsData, setCmsData] = useState({});
  const [cmsImage, setCmsImage] = useState({});
  const history = useHistory();

  const Data = useSelector((state) => state.cmsData);
  useEffect(() => {
    if (Data) {
      setCmsData(Data?.cmsData);
    }
  }, [Data]);

  useEffect(() => {
    fetchCMSImages();
  }, []);

  const fetchCMSImages = async () => {
    try {
      const { status, loading, error, result } = await getCmsImage();
      if (status == true) {
        setCmsImage(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const rediretDash = async () => {
    history.push("/dashboard");
  };

  const handleGame = (name) => {
    setGame(name);
  };
  return (
    <section className="position-relative">
      <div className="ble_blr_cle position-absolute"></div>
      <div className="ble_blr_cle_sm position-absolute d-none d-xl-block"></div>
      <HomeHeader />

      <div style={{ paddingTop: "70px" }}>
        {/* start of banner section */}
        {cmsData?.length > 0 &&
          cmsData?.map((item) => {
            if (item?.identifier == "Top Content") {
              return (
                <>
                  <Container className="custom_container banner_container">
                    <Row className="align-items-center h-100">
                      {/* start of home banner left  */}

                      <Col
                        xl={6}
                        className=" d-flex align-items-center h-100 px-5 justify-content-center justify-content-xl-start">
                        <div className="hm_banner_left">
                          <h3 className="hm_banner_tle text-center text-xl-start">
                            {item?.title}
                          </h3>
                          <p className="mt-3 hm_ban_lft_txt text-center text-xl-start">
                            {/* We’ve got the best odds, and hundreds of sports for you to
                  choose from. */}
                            <span className="custom_cke_content">
                              {ReactHtmlParser(item?.content)}
                            </span>
                          </p>
                          {/* <Row className="mt-4">
                  <Col sm={6} className="mb-3 ">
                    <div className="d-flex align-items-center">
                      <div className="white_dot me-3"></div>
                      <p className="mb-0 hm_ban_lft_ftrs">spreads</p>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <div className="d-flex align-items-center">
                      <div className="white_dot me-3"></div>
                      <p className="mb-0 hm_ban_lft_ftrs">totals</p>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <div className="d-flex align-items-center">
                      <div className="white_dot me-3"></div>
                      <p className="mb-0 hm_ban_lft_ftrs">moneylines</p>
                    </div>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <div className="d-flex align-items-center">
                      <div className="white_dot me-3"></div>
                      <p className="mb-0 hm_ban_lft_ftrs">futures</p>
                    </div>
                  </Col>
                </Row> */}

                          {/* <Button className="primary_blue_btn">Bet Now</Button> */}
                          <Row className="mt-4">
                            <Col
                              xs={12}
                              sm={6}
                              xxl={5}
                              className="d-flex justify-content-center justify-content-xl-start">
                              {" "}
                              <button
                                className="img_btn img_btn_fw img_btn_xxl"
                                onClick={() => rediretDash()}>
                                Bet Now
                              </button>
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              xxl={5}
                              className="d-flex justify-content-center justify-content-xl-start mt-3 mt-sm-0">
                              {" "}
                              <button
                                className="img_grd_btn img_btn_fw img_btn_xxl"
                                onClick={() => rediretDash()}>
                                Explore Now
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      {/* end of home banner left */}

                      {/* start of home banner right  */}

                      <Col xl={6} className="mt-5 mt-xl-0">
                        {/* <Swiper
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img src={banner} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <img src={swiper2} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <img src={swiper3} alt="" />
                  </SwiperSlide>
                </Swiper> */}
                        {/* <Swiper
                  direction={"vertical"}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img src={banner} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={swiper2} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={swiper3} alt="" />
                  </SwiperSlide>
                </Swiper> */}
                        <Swiper
                          direction={"vertical"}
                          slidesPerView={1}
                          navigation={true}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination, Navigation]}
                          className="mySwiper">
                          <SwiperSlide>
                            <div className="image_holder ">
                              <img src={swiper2} alt="" className="" />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="image_holder ">
                              <img src={banner} alt="" className="" />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="image_holder ">
                              <img src={swiper3} alt="" className="" />
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </Col>

                      {/* end of home banner right */}
                    </Row>
                  </Container>
                </>
              );
            }
          })}

        {/* end of banner section */}

        {/* start of welcome mobius */}

        {/* <Container fluid>
        <Row>
          <Col>
            <Container className="custom_container">
              <Row>
                <Col>1</Col>
              </Row>
            </Container>
          </Col>
          <Col>2</Col>
        </Row>
      </Container> */}

        <div className="position-relative hm_wlc_container mt-5 mt-xl-0 mt-xl-0 pt-3 pt-xl-0">
          {cmsData?.length > 0 &&
            cmsData?.map((item) => {
              if (item?.identifier == "Welcome! Mobius") {
                return (
                  <>
                    <Container className="custom_container cont_100vh">
                      <Row className="hm_wlc_row justify-content-center justify-content-xl-start">
                        <Col
                          lg={10}
                          xl={6}
                          className="d-flex align-items-center">
                          <div>
                            <p className="hm_var_tle text-center text-xl-start">
                              {item?.title}
                              {/* Welcome! Mobius */}
                            </p>
                            {ReactHtmlParser(item?.content)}

                            {/* <p className="hm_p ">
                    Nothing compares to online betting at linescenter.com. We’ve
                    got the best odds, and hundreds of sports for you to choose
                    from.
                  </p>
                  <p className="hm_p">
                    Whether it’s a bet before the big game or during it, on
                    anything from football, basketball, baseball, tennis and
                    horse racing to reality TV and politics, we have got it
                    covered.
                  </p>
                  <p className="hm_p">
                    Feeling to play the casinos, then we have that too. Enjoy
                    state of the art Las Vegas style games with our live dealers
                    or play against the computer.
                  </p> */}

                            <div className="d-flex flex-wrap gap-4 mt-4 mt-xxl-5 justify-content-center justify-content-xl-start">
                              <button
                                className={
                                  game == "Basketball"
                                    ? "common_bg_full_btn wlm_mbs_game"
                                    : "wlm_mbs_game"
                                }
                                onClick={() => handleGame("Basketball")}>
                                Basketball
                              </button>
                              <button
                                // className="img_grd_btn img_btn_xxl"
                                className={
                                  game == "Baseball"
                                    ? "common_bg_full_btn wlm_mbs_game"
                                    : "wlm_mbs_game"
                                }
                                onClick={() => handleGame("Baseball")}>
                                Baseball
                              </button>
                              <button
                                // className="img_grd_btn img_btn_xxl"
                                className={
                                  game == "Football"
                                    ? "common_bg_full_btn wlm_mbs_game"
                                    : "wlm_mbs_game"
                                }
                                onClick={() => handleGame("Football")}>
                                Football
                              </button>
                              <Link
                                to="/dashboard"
                                className="text-decoraion-none">
                                <button className="img_grd_btn img_btn_xxl">
                                  Upcoming
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Col>
                        {/* <Col lg={6}>
              <div className="hm_wlc_cards position-relative">
                <div className="hm_wlc_cardOne">
                  <img src={baseball} alt="" />
                </div>
              </div>
            </Col> */}
                        {/* <Col lg={6} className="hm_wlc_rgt">
              <Row>
                <Col xl={5}>
                  {" "}
                  <div className="hm_wlc_rgt_fir d-flex align-items-center">
                    <img src="" alt="" />1
                  </div>
                </Col>
                <Col xl={4} className="">
                  <div className="hm_wlc_rgt_scd d-flex align-items-center ">
                    <img src="" alt="" />2
                  </div>
                </Col>
                <Col xl={3} className="">
                  <div className="hm_wlc_rgt_trd d-flex align-items-center">
                    <img src="" alt="" />3
                  </div>
                </Col>
              </Row>
            </Col> */}
                      </Row>
                    </Container>
                  </>
                );
              }
            })}

          <Container className="d-xl-none mt-5 pt-3 pt-sm-4 pt-md-5 pt-xl-0 mt-xl-0">
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="hm_wlc_lft_wrp">
                  <Row className="">
                    {/* <Col xs={5} className="">
                      <div className="hm_wlc_lft_frt">
                        <img src={baseball} alt="" />
                      </div>
                    </Col> */}
                    <Col xs={5} className="position-relative hm_wlc_act ">
                      <div className="hm_wlc_rgt_bdr position-absolute"></div>
                      <div className="hm_wlc_lft_frt_wrp mt-3">
                        {" "}
                        <div className="hm_wlc_lft_frt position-relative">
                          <button className="position-absolute hm_wlc_frt_btn hm_wlc_frt_btn_sm mx-auto ">
                            {/* Basketball */}
                            {game}
                          </button>{" "}
                          {/* <img src={basketball} alt="" /> */}
                          {cmsImage && (
                            <img
                              src={
                                game == "Basketball"
                                  ? cmsImage &&
                                    cmsImage?.length > 0 &&
                                    cmsImage?.find(
                                      (val) => val?.identifier == "Basketball"
                                    ).image[0]
                                  : game == "Baseball"
                                  ? cmsImage &&
                                    cmsImage?.length > 0 &&
                                    cmsImage?.find(
                                      (val) => val?.identifier == "Baseball"
                                    ).image[0]
                                  : game == "Football"
                                  ? cmsImage &&
                                    cmsImage?.length > 0 &&
                                    cmsImage?.find(
                                      (val) => val?.identifier == "Football"
                                    ).image[0]
                                  : false
                              }
                              alt={game}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4} className="">
                      <div className="hm_wlc_lft_scd position-relative">
                        {" "}
                        <button className="position-absolute hm_wlc_frt_btn hm_wlc_frt_btn_sm mx-auto">
                          {/* Basketball */}
                          {game == "Basketball"
                            ? "Baseball"
                            : game == "Baseball"
                            ? "Football"
                            : game == "Football"
                            ? "Basketball"
                            : ""}
                        </button>{" "}
                        {/* <img src={baseball} alt="" /> */}
                        <img
                          src={
                            game == "Basketball"
                              ? // ? baseball
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Baseball"
                                ).image[0]
                              : game == "Baseball"
                              ? // ? football
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Football"
                                ).image[0]
                              : game == "Football"
                              ? // ? basketball
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Basketball"
                                ).image[0]
                              : false
                          }
                          alt={game}
                        />
                      </div>
                    </Col>
                    <Col xs={3} className="">
                      <div className="hm_wlc_lft_trd">
                        {" "}
                        {/* <img src={football} alt="" /> */}
                        <img
                          src={
                            game == "Basketball"
                              ? // ? football
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Football"
                                ).image[0]
                              : game == "Baseball"
                              ? // ? basketball
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Basketball"
                                ).image[0]
                              : game == "Football"
                              ? // ? baseball
                                cmsImage &&
                                cmsImage?.length > 0 &&
                                cmsImage?.find(
                                  (val) => val?.identifier == "Baseball"
                                ).image[0]
                              : false
                          }
                          alt={game}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="hm_wlc_lft_wrp d-none d-xl-block">
            <Row className="px-0">
              <Col xs={5} className="position-relative hm_wlc_act ">
                <div className="hm_wlc_rgt_bdr position-absolute"></div>
                <div className="hm_wlc_lft_frt_wrp mt-3">
                  <div className="hm_wlc_lft_frt position-relative">
                    <button className="position-absolute hm_wlc_frt_btn mx-auto">
                      {game}
                    </button>{" "}
                    {/* <img src={basketball} alt="" /> */}
                    <img
                      src={
                        game == "Basketball"
                          ? cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Basketball"
                              ).image[0]
                            }`
                          : game == "Baseball"
                          ? cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Baseball"
                              ).image[0]
                            }`
                          : game == "Football"
                          ? cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Football"
                              ).image[0]
                            }`
                          : false
                      }
                      alt={game}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={4} className="">
                <div className="hm_wlc_lft_scd position-relative">
                  {" "}
                  <button className="position-absolute hm_wlc_frt_btn mx-auto">
                    {/* Basebal */}
                    {game == "Basketball"
                      ? "Baseball"
                      : game == "Baseball"
                      ? "Football"
                      : game == "Football"
                      ? "Basketball"
                      : ""}
                  </button>{" "}
                  {/* <img src={baseball} alt="" /> */}
                  <img
                    src={
                      game == "Basketball"
                        ? // ? baseball
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Baseball"
                            ).image[0]
                          }`
                        : game == "Baseball"
                        ? // ? football
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Football"
                            ).image[0]
                          }`
                        : game == "Football"
                        ? // ? basketball
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Basketball"
                            ).image[0]
                          }`
                        : false
                    }
                    alt={game}
                  />
                </div>
              </Col>
              <Col xs={3} className="pe-0">
                <div className="hm_wlc_lft_trd">
                  {" "}
                  {/* <img src={football} alt="" /> */}
                  <img
                    src={
                      game == "Basketball"
                        ? // ? football
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Football"
                            ).image[0]
                          }`
                        : game == "Baseball"
                        ? // ? basketball
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Basketball"
                            ).image[0]
                          }`
                        : game == "Football"
                        ? // ? baseball
                          cmsImage &&
                          cmsImage?.length > 0 &&
                          `${cmsurl}${
                            cmsImage?.find(
                              (val) => val?.identifier == "Baseball"
                            ).image[0]
                          }`
                        : false
                    }
                    alt={game}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* end of welcome mobius */}

        {/* start of variety options */}

        <Container className="custom_container hm_var_opt mt-5">
          <Row className="align-items-center">
            <Col xs={6} sm={5} lg={6} className="d-none d-sm-block">
              <Row className="align-items-center pe-3">
                <Col sm={12} md={8} lg={5} xl={4}>
                  {/* <Button className="primary_blue_btn">View All</Button> */}
                  <button
                    className="img_btn img_btn_xxl"
                    onClick={() => rediretDash()}>
                    View All
                  </button>
                </Col>
                <Col sm={4} md={4} lg={7} xl={8} className="d-none d-md-block">
                  <div className="blue_line"></div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={7} lg={6} className="">
              <p className="mb-0 hm_var_tle hm_var_xl">
                Variety of options for your Bets{" "}
              </p>
            </Col>
          </Row>

          <Row className="mt-5  hm_var_row">
            {/* <Col xs={12} lg={6} className="pe-xl-4">
              <div className="hm_var_left_wrap position-relative">
                <img src={baseball} alt="" />
                <Button className="gradient_btn  position-absolute hm_var_btn">
                  Play Now
                </Button>
              </div>
            </Col> */}
            <Col xs={12} lg={6} className="position-relative h-100">
              <div className="h-100 hm_var_lft_vdo_wp">
                {" "}
                <div className="hm_var_lft_vdo h-100">
                  {/* <button className="position-absolute hm_wlc_frt_btn hm_wlc_frt_btn_sm mx-auto ">
                    Basketball
                  </button>{" "} */}
                  {/* <img src={basketball} alt="" /> */}
                  {/* <ReactPlayer
                    url={footballVideo}
                    // playing={true}
                    loop={true}
                    controls={true}
                  /> */}
                  <VideoSec />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="ps-xl-4 mt-4 mt-lg-0">
              <Row className="h-100">
                {/* <Col xs={12} md={6} className="mb-xl-5 mb-4 pe-xl-4">
                  {" "}
                  <div className="hm_var_right_wrap position-relative">
                    <img src={baseball} alt="" />
                  </div>
                </Col>
                <Col xs={12} md={6} className="mb-xl-5 mb-4 ps-xl-4">
                  {" "}
                  <div className="hm_var_right_wrap position-relative">
                    <img src={baseball} alt="" />
                  </div>
                </Col>
                <Col xs={12} md={6} className="mb-lg-0 mb-4 pe-xl-4">
                  {" "}
                  <div className="hm_var_right_wrap position-relative hm_var_right_brt">
                    <img src={baseball} alt="" />
                    <Button className="primary_blue_btn  position-absolute hm_var_btn">
                      Play Now
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={6} className="mb-lg-0 mb-4 ps-xl-4">
                  {" "}
                  <div className="hm_var_right_wrap position-relative hm_var_right_brt">
                    <img src={baseball} alt="" />
                    <Button className="primary_blue_btn  position-absolute hm_var_btn">
                      Play Now
                    </Button>
                  </div>
                </Col> */}
                <Col sm={6} xl={6} className="mb-4 hm_var_rgt_col">
                  <div className="h-100">
                    <div className="hm_var_rgt_wp h-100">
                      <div className="hm_var_rgt_cp">
                        <img
                          src={
                            cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Games"
                              ).image[0]
                            }`
                          }
                          alt=""
                          className="hm_var_rgt_img"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xl={6} className="mb-4 hm_var_rgt_col">
                  <div className="h-100">
                    <div className="hm_var_rgt_wp h-100">
                      <div className="hm_var_rgt_cp">
                        <img
                          src={
                            cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Games"
                              ).image[1]
                            }`
                          }
                          alt=""
                          className="hm_var_rgt_img"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xl={6} className="hm_var_rgt_col mt-sm-4 mb-4">
                  <div className="h-100 ">
                    <div className="hm_var_rgt_wp h-100">
                      <div className="hm_var_rgt_cp position-relative">
                        <button className="position-absolute hm_wlc_frt_btn mx-auto">
                          Upcoming
                        </button>
                        <img
                          src={
                            cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Games"
                              ).image[2]
                            }`
                          }
                          alt=""
                          className="hm_var_rgt_img "
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xl={6} className="hm_var_rgt_col mt-sm-4 mb-4">
                  <div className="h-100">
                    <div className="hm_var_rgt_wp h-100">
                      <div className="hm_var_rgt_cp position-absolute">
                        <button className="position-absolute hm_wlc_frt_btn mx-auto">
                          Upcoming
                        </button>
                        <img
                          src={
                            cmsImage &&
                            cmsImage?.length > 0 &&
                            `${cmsurl}${
                              cmsImage?.find(
                                (val) => val?.identifier == "Games"
                              ).image[3]
                            }`
                          }
                          alt=""
                          className="hm_var_rgt_img "
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Button className="primary_blue_btn mx-auto d-sm-none">
            View All
          </Button> */}
          <div className="d-flex justfify-content-center d-sm-none my-4">
            <button className="img_btn img_btn_xxl mx-auto">View All</button>
          </div>
        </Container>

        {/* end of variety options */}

        {/* start of betting options */}
        {cmsData?.length > 0 &&
          cmsData?.map((item) => {
            if (item?.identifier == "Betting Options") {
              return (
                <>
                  <Container className="pt-3 mt-5">
                    <Row className="justify-content-center ">
                      <Col lg={10} xxl={12}>
                        <h5 className="text-center hm_var_tle">
                          {/* Betting Options */}
                          {item?.title}
                        </h5>
                        <p className="text-center mt-3 hm_p">
                          {ReactHtmlParser(item?.content)}
                          {/* Nothing compares to online betting at linescenter.com. We’ve got
                  the best odds, and hundreds of sports for you to choose from.
                  Whether it’s a bet before the big game or during it, on anything
                  from football, basketball, and baseball to reality TV and
                  politics we have got it covered. Feeling to play the casinos,
                  then we have that too. Enjoy state of the art Las Vegas style
                  games with our live dealers or play against the computer. */}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </>
              );
            }
          })}
        {/* end of betting options */}
      </div>

      <HomeFooter />
    </section>
  );
};

export default Home;
