import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { validateEmail, validateVerifyUser } from "../validations/login";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";
import { toastAlert } from "../lib/toastAlert";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ForgotPassword, verifyUser } from "../actions/forgotPassword";


const initialFormValue = {
  emailId: "",
  otp: "",
};


const OTPVerification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [otp, setOtp] = useState("");
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");
  const { emailId, password, otp } = formValue;


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const GetcodeFn = async () => {
    let value = {
      emailId: emailId,
    };
    var validate = validateEmail(value);

    let encryptToken = {
      token: encryptObject(value),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await ForgotPassword(encryptToken);
      if (status == true) {
        toastAlert("success", message, "getcode");
      } else {
        if (error) {
          setErrors(error);
        }
        toastAlert("error", message, "getcode");
      }
    } else {
      setErrors(validate);
    }
  };

  const Verify  = async () => {
    let value = {
      emailId: emailId,
      otp: otp
    };
    var validate = validateVerifyUser(value);

    let encryptToken = {
      token: encryptObject(value),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await verifyUser(encryptToken);
      if (status == true) {
        setFormValue(initialFormValue);
        setErrors(validateVerifyUser(initialFormValue));
        toastAlert("success", message, "verifyUser");
        history.push({ 
        pathname: "/forgot-password",
        state:{emailId:emailId}
      })

      } else {
        if (error) {
          setErrors(error);
        }
        toastAlert("error", message, "verifyUser");
      }
    } else {
      setErrors(validate);
    }
  };


  return (
    <section>
      <HomeHeader />

      <div className="container">
        <div className="signup login allsection h-100vh">
          <div className="row">
            <div className="col-md-10 col-lg-10 mx-auto">
              <h4 className="text-center mb-3">FORGOT PASSWORD</h4>
              <div className="signborder">
                <div className="row">
                  <div className="col-md-12 mt-2">
                    <div class="mb-sm-3 position-relative">
                      <label for="exampleFormControlInput1" class="form-label">
                        Enter Email ID
                      </label>

                      <Row className="align-items-start ">
                        <Col sm={8} lg={9}>
                          {" "}
                          <input
                            type="email"
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter your Email ID"
                            name="emailId"
                            value={emailId}
                            onChange={(e) => handleChange(e)}
                          />
                          
                            <p className="text-danger mt-2 mb-0 d-sm-block">
                              {errors.emailId && errors.emailId}
                            </p>
                        </Col>
                        <Col
                          sm={4}
                          lg={3}
                          className="mt-4 mt-sm-0 d-flex justify-content-center"
                        >
                          {" "}
                          <button className="btn clear_btn "
                            onClick={() => {
                              GetcodeFn();
                            }}>Get Code</button>
                        </Col>
                      </Row>
                      {/* <p className="text-danger mt-2 mb-0 d-none d-sm-block">
                        Error Message
                      </p> */}

                      {/* <div>
                        <span className="getcode">Get Code</span>
                      </div> */}
                    </div>
                  </div>
                  <div className=" ">
                    {/* <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    /> */}
                    <input
                      type="text"
                      class="form-control mt-4"
                      id="name"
                      placeholder="Enter code"
                      name="otp"
                      value={otp}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.otp && (
                      <p className="text-danger mt-2 mb-0 d-sm-block">
                        {errors.otp && errors.otp}
                      </p>
                    )}
                    {/* {errors.otp && (
                      <p className="error-message">error</p>
                    )} */}
                  </div>
                  {/* <Link className="forgot mt-2" to="/forgot-password">
                    Forgot Password
                  </Link> */}

                  <div className="col-md-12 text-center">
                    <div class="mt-4">
                      {/* <button className="btn clear_btn  mt-3 mt-sm-3 me-2 me-sm-4">
                        Clear
                      </button> */}
                      {/* <Link to="/dashboard"> */}
                      <Link to="/login">
                        <button className="btn clear_btn  mt-sm-2 me-sm-2 me-sm-4">
                          Back
                        </button>
                      </Link>
                      {/* <Link to="/forgot-password"> */}
                        <button className="btn img_btn mt-4 mt-sm-2" onClick={()=>{Verify()}} >
                          Verify
                        </button>
                      {/* </Link> */}
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTPVerification;
