import axios, { setAuthorization, removeAuthorization, handleResp } from "../config/axios";
import { removeAuthToken, setAuthToken } from "../lib/localStorage";
import { setAccountData } from "./userlogin"

import {
    REMOVE_AUTHENTICATION,   
  } from "../constants";


export const logout = (history, dispatch) => {
    try {
        removeAuthToken();
        removeAuthorization();
        history.push("/login");
        dispatch({
            type: REMOVE_AUTHENTICATION,
            authData: {
                isAuth: false,
            },
        });
        dispatch(setAccountData({}));
    } catch (err) {
        console.log(err, 'logout')
    }
};