import React, { useState, useRef,useEffect } from "react";
import { FaPlay } from "react-icons/fa6";
import { BsFillPauseFill } from "react-icons/bs";
import footballVideo from "../../asset/video/football.mp4";
import { FaCirclePlay } from "react-icons/fa6";
import { FaCirclePause } from "react-icons/fa6";
import { getCmsImage } from "../actions/cms";
import config from "../config/config";


function VideoSec() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [play, setPlay] = useState(true);
  const [cmsImage, setCmsImage] = useState({});
  const cmsurl = `${config.API_URL}/public/cms/`;

  const videoRef = useRef(null);
  //   const VideoSrc =
  //     "https://player.vimeo.com/progressive_redirect/playback/682048671/rendition/360p?loc=external&oauth2_token_id=57447761&signature=5be12c7ba41b540b1bb6e8ff3cb8f859e9381ece088946bb8257fe3da7201f79";

  useEffect(() => {
    fetchCMSImages();
  },[]);

  const fetchCMSImages = async () => {
    try {
      const { status, loading, error, result } = await getCmsImage();
      if (status == true) {
        setCmsImage(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const VideoSrc = footballVideo;

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      setPlay(false);
      setTimeout(() => {
        document.getElementById("play_pause_btn").style.opacity = 0;
      }, 1000);
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      setPlay(true);
      document.getElementById("play_pause_btn").style.opacity = 1;
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="position-relative videsec_rela">
      <video
        ref={videoRef}
        src={
          cmsImage &&
          cmsImage?.length > 0 &&
          `${cmsurl}${
            cmsImage?.find(
              (val) => val?.identifier == "Video"
            ).image[0]
          }`
        }
        controls={play ? false : true}
        width="100%"
      />
      <div className="position-absolute video-bg-btn">
        {/* {play ? (
          <> */}
        <button onClick={togglePlayPause} id="play_pause_btn">
          {isPlaying ? (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <FaCirclePause className="play_pause_icon me-2" size={13} />
                <span className="play_pause_txt">Pause Now</span>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <FaCirclePlay className="play_pause_icon me-2" size={13} />
                <span className="play_pause_txt">Play Now</span>
              </div>
            </>
          )}
        </button>
        {/* </>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
}
export default VideoSec;
