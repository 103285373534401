const fileObjectUrl = (fileImage) => {
    try {
        // console.log("fileImage",fileImage)
        return URL.createObjectURL(fileImage)
    }
    catch (err) {
        // console.log("err",err)
        return fileImage
    }
}

export default fileObjectUrl;