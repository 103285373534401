import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MobiusCard from "../MobiusCard";
import WagerType from "../WagerType";
import { Button } from "react-bootstrap";
import Teams from "../Teams";
import Amount from "../Amount";
import ConfirmWager from "../ConfirmWager";
import Review from "../Review";
import CommonPrevNextBtn from "../CommonPrevNextBtn";
// const ActiveState = ["first", "second", "third"];

import logo1 from "../../../asset/img/team_logo1.png";
import logo2 from "../../../asset/img/team_logo2.png";
import Mobius from "./Mobius";

function DashboardContent() {
  // const [activeKey, setActiveKey] = useState("first"); // Set the initial active tab

  // const handleSelect = (key) => {
  //   setActiveKey(key);
  // };

  const [activeTab, setActiveTab] = useState(Number(0));

  const handleNextTab = () => {
    if (activeTab == 5) {
      setActiveTab(5);
    } else {
      setActiveTab(activeTab + 1);
    }
  };

  const handlePreviousTab = () => {
    if (activeTab == 0) {
      setActiveTab(0);
    } else {
      setActiveTab(activeTab - 1);
    }
  };

  const hendleGetTab = (k) => {
    setActiveTab(Number(k));
  };

  // const Teams = [
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  // ];

  return (
    <div className="dashboard_content_sec">
      <h2 className="header_txt my-4">Betting Process</h2>
      <div className="tabs_section">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeTab}
          onSelect={(k) => hendleGetTab(k)}
        >
          <Nav variant="pills" className="nav_tabs_bg">
            <Nav.Item>
              <Nav.Link
                // eventKey="first"
                eventKey={0}
                className={
                  activeTab == 0 ||
                  activeTab == 1 ||
                  activeTab == 2 ||
                  activeTab == 3 ||
                  activeTab == 4 ||
                  activeTab == 5
                    ? "active"
                    : ""
                }
              >
                Select Wager Type
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                // eventKey="second"
                eventKey={1}
                className={
                  activeTab == 1 ||
                  activeTab == 2 ||
                  activeTab == 3 ||
                  activeTab == 4 ||
                  activeTab == 5
                    ? "active"
                    : ""
                }
              >
                Select Sport
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                // eventKey="third"
                eventKey={2}
                className={
                  activeTab == 2 ||
                  activeTab == 3 ||
                  activeTab == 4 ||
                  activeTab == 5
                    ? "active"
                    : ""
                }
              >
                Select Teams
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={3}
                className={
                  activeTab == 3 || activeTab == 4 || activeTab == 5
                    ? "active"
                    : ""
                }
              >
                Enter Amount
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                // eventKey="fifth"
                eventKey={4}
                className={activeTab == 4 || activeTab == 5 ? "active" : ""}
              >
                Confirm Wager
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                // eventKey="sixth"
                eventKey={5}
                className={activeTab == 5 ? "active" : ""}
              >
                Review & Print
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-4">
            <Tab.Pane eventKey={0}>
              <div>
                <div className="live_mobi">
                  <p className="mb-0 live_mobius_txt ps-2">
                    Live <span className="mobius">Mobius</span>
                  </p>
                  {/* {Teams.map((item, index) => {
                    return (
                      <> 
                  <MobiusCard />; 
                   </>
                    );
                  })}  */}
                  <Mobius />
                </div>

                <div className="wager mt-5">
                  <p className="mb-0 wager_txt">Current Wager Type</p>
                  <WagerType />
                </div>
              </div>
              <CommonPrevNextBtn
                txt1="Clear"
                txt2="Continue"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={1}>
              <div>
                <div className="live_mobi">
                  <p className="mb-0 live_mobius_txt">
                    Live <span className="mobius">Mobius</span>
                  </p>
                  <MobiusCard />
                </div>
                <div className="wager mt-5">
                  <p className="mb-0 wager_txt">Current Wager Type</p>
                  <WagerType />
                </div>
              </div>
              <CommonPrevNextBtn
                txt1="Clear"
                txt2="Continue"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <Teams />
              <CommonPrevNextBtn
                txt1="Clear"
                txt2="Continue"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <Amount />
              <CommonPrevNextBtn
                txt1="Clear"
                txt2="Continue"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <ConfirmWager />
              <CommonPrevNextBtn
                txt1="Cancel Wager"
                txt2="Continue"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={5}>
              <Review />
              <CommonPrevNextBtn
                txt1="Back"
                txt2="Print Wager"
                handlePreviousTab={handlePreviousTab}
                handleNextTab={handleNextTab}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export default DashboardContent;
