import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

import { IoMdAdd } from "react-icons/im";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";

import Star from "../../../asset/img/star1.png";
import StarFill from "../../../asset/img/star_fill.png";
import Plus from "../../../asset/img/plus1.png";
import Minus from "../../../asset/img/minus1.png";

function CustomToggle({ children, eventKey, icon, handleIconm }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
  });

  // const handleIconn = () => {
  //   setIcon(!icon);
  // };

  return (
    <div type="button" onClick={decoratedOnClick}>
      <span onClick={handleIconm}>{children}</span>
    </div>
  );
}

function Parlay({ Games }) {
  const [isOpen, setIsOpen] = useState("");
  const [icon, setIcon] = useState(true);
  const toggleAccordion = (id) => {
    if (isOpen == id) {
      setIsOpen("");
    } else {
      setIsOpen(id);
    }
  };

  const AccordionSec = [
    {
      id: "1",
      Subject: "Withdraw Issue",
      TicketID: "#1234567",
      Status: "Closed",
    },
    {
      id: "2",
      Subject: "Withdraw Issue",
      TicketID: "#1234567",
      Status: "Closed",
    },
  ];

  // const Games = [
  //   {
  //     id: 1,
  //     name: "NFL",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //     checked: false,
  //   },
  //   {
  //     id: 2,
  //     name: "College Basketball",
  //     star: Star,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //     checked: false,
  //   },
  //   {
  //     id: 3,
  //     name: "NBA",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 4,
  //     name: "College Football",
  //     star: Star,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 5,
  //     name: "NHL",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 6,
  //     name: "Soccer - England",
  //     star: Star,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 7,
  //     name: "Basketball - Intl Leagues",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 8,
  //     name: "Soccer - UEFA",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 9,
  //     name: "NFL",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 10,
  //     name: "College Basketball",
  //     star: Star,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 11,
  //     name: "NBA",
  //     star: StarFill,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  //   {
  //     id: 12,
  //     name: "College Football",
  //     star: Star,
  //     subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
  //     subtext2: "NFL - 1H:SPREADS - TOTALS",
  //     subtext3: "NFL - SUPER BOWL(TO WIN)",
  //   },
  // ];

  const [checkboxarr, SetCheckboxarr] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);

  const handleIcon = () => {
    // setIcon(false);
  };

  const [checkAll, setCheckAll] = useState(false);

  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const handleCheckboxChange = (e, id, index) => {
    SetCheckboxarr([
      ...checkboxarr.slice(0, index),
      ...[{ 0: e.target.checked, 1: e.target.checked, 2: e.target.checked }],
      ...checkboxarr.slice(index + 1, checkboxarr.length),
    ]);
    if (e.target.checked) {
      setSelectedCheckbox([...selectedCheckbox, id]);
    } else {
      let Data = [...selectedCheckbox];
      let index = Data.findIndex((val) => val == id);
      Data.splice(index, 1);
      setSelectedCheckbox(Data);
    }
    // setSelectedCheckbox(id === selectedCheckbox ? null : id);
  };

  const [isOpenn, setIsOpenn] = useState();

  const toggleAccordionn = (id) => {
    if (isOpenn == id) {
      setIsOpenn();
    } else {
      setIsOpenn(id);
    }
  };

  const handleIconss = () => {
    setIcon(!icon);
  };
  const [star, setStar] = useState("");

  const handleStar = (id) => {
    // setStar(!star);
    if (star == id) {
      setStar("");
    } else {
      setStar(id);
    }
  };
  return (
    <div className="parlay_tabs">
      <div className="games_sec">
        <div className="row ">
          {Games &&
            Games?.map((item, index) => {
              return (
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                  <Accordion
                    defaultActiveKey=""
                    className="custom_accordion"
                    eventKey={item.id}
                  >
                    <Card>
                      <Card.Header className="accor_header">
                        <Form.Check
                          type="checkbox"
                          // id={`default-${type}`}
                          // label={`default ${type}`}
                          className="checkbox"
                          checked={
                            checkboxarr?.[index]
                              ? Object.values(checkboxarr?.[index])?.length > 0
                                ? Object.values(checkboxarr?.[index])?.every(
                                  (test) =>
                                    test == true &&
                                    Object.values(checkboxarr?.[index])
                                      ?.length >= 3
                                )
                                : false
                              : false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(e, item.id, index)
                          }
                        />

                        <div className="d-flex align-items-center">
                          <div onClick={() => handleStar(item.id)}>
                            {star == item.id ? (
                              <>
                                <img
                                  src={StarFill}
                                  alt="star"
                                  className="img-fluid me-2 star_logo"
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={Star}
                                  alt="star"
                                  className="img-fluid me-2 star_logo"
                                />
                              </>
                            )}
                          </div>
                          <span>{item.name}</span>
                        </div>
                        <CustomToggle
                          eventKey={item.id}
                          // onClick={handleIcon()}
                          className="open_close_icon"
                          icon
                          handleIconm={handleIconss}
                        >
                          <div
                            // onClick={handleIcon()}
                            onClick={() => toggleAccordionn(item.id)}
                          >
                            {isOpenn == item.id ? (
                              <img
                                src={Minus}
                                alt="icons"
                                className="img-fluid plus_minus_icon"
                              />
                            ) : (
                              <img
                                src={Plus}
                                alt="icons"
                                className="img-fluid plus_minus_icon"
                              />
                            )}
                          </div>
                        </CustomToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey={item.id}>
                        <Card.Body>
                          <div className="accor_body">
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                checked={
                                  checkboxarr?.[index]?.[0] ? true : false
                                }
                                onChange={(e) => {
                                  SetCheckboxarr([
                                    ...checkboxarr.slice(0, index),
                                    ...[
                                      {
                                        ...checkboxarr[index],
                                        ...{ 0: e.target.checked },
                                      },
                                    ],
                                    ...checkboxarr.slice(
                                      index + 1,
                                      checkboxarr.length
                                    ),
                                  ]);
                                }}
                              />
                              <span className="ps-2">{item.subtext1}</span>
                            </div>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                checked={
                                  checkboxarr?.[index]?.[1] ? true : false
                                }
                                onChange={(e) => {
                                  SetCheckboxarr([
                                    ...checkboxarr.slice(0, index),
                                    ...[
                                      {
                                        ...checkboxarr[index],
                                        ...{ 1: e.target.checked },
                                      },
                                    ],
                                    ...checkboxarr.slice(
                                      index + 1,
                                      checkboxarr.length
                                    ),
                                  ]);
                                }}
                              />
                              <span className="ps-2">{item.subtext2}</span>
                            </div>
                            <div className="d-flex">
                              <Form.Check
                                type="checkbox"
                                checked={
                                  checkboxarr?.[index]?.[2] ? true : false
                                }
                                onChange={(e) => {
                                  SetCheckboxarr([
                                    ...checkboxarr.slice(0, index),
                                    ...[
                                      {
                                        ...checkboxarr[index],
                                        ...{ 2: e.target.checked },
                                      },
                                    ],
                                    ...checkboxarr.slice(
                                      index + 1,
                                      checkboxarr.length
                                    ),
                                  ]);
                                }}
                              />
                              <span className="ps-2">{item.subtext3}</span>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Parlay;
