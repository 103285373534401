import React from "react";
import Sidebar from "../dashboard/Sidebar";
import Header from "../dashboard/Header";
import Favoritescont from "./favoritescont";

const FillOpen = () => {
  return (
    <section>
      <div className="dashboard_sec">
        <div className="container-fluid d-flex ps-0 ">
          <div className="left_side_sec">
            <Sidebar />
          </div>
          <div className="right_side_sec px-2 py-2 px-sm-4 py-sm-4">
            <Header />
            <Favoritescont />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FillOpen;
