import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

import { IoMdAdd } from "react-icons/im";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";

import Star from "../../asset/img/star1.png";
import StarFill from "../../asset/img/star_fill.png";
import Plus from "../../asset/img/plus1.png";
import Minus from "../../asset/img/minus1.png";
import TeamCard from "./TeamCard";
import { useDispatch, useSelector } from "react-redux";

function CustomToggle({ children, eventKey, icon, handleIconm }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
  });

  return (
    <div type="button" onClick={decoratedOnClick}>
      <span onClick={handleIconm}>{children}</span>
    </div>
  );
}

function SelectDisplay({ SelectDisplay }) {
  const [icon, setIcon] = useState(true);



  const { gameData } = useSelector((state) => state.gameData);
  const Games = [
    {
      id: 1,
      name: "NFL",
      subtext1: "1st half / 2nd half    18",
      subtext2: "Teams / Game Total    18",
      subtext3: "Prop   10",
      count: 40,
    },
    {
      id: 2,
      name: "NBA",
      subtext1: "1st half / 2nd half    18",
      subtext2: "Teams / Game Total    18",
      subtext3: "Prop   10",
      count: 56,
    },
    {
      id: 3,
      name: "Baseball",
      subtext1: "1st half / 2nd half    18",
      subtext2: "Teams / Game Total    18",
      subtext3: "Prop   10",
      count: 20,
    },
  ];

  const [isOpenn, setIsOpenn] = useState();

  const toggleAccordionn = (id) => {
    if (isOpenn == id) {
      setIsOpenn();
    } else {
      setIsOpenn(id);
    }
  };

  const handleIconss = () => {
    setIcon(!icon);
  };
  return (
    <div className="parlay_tabs all_events mb-5">
      <div className="games_sec ">
        {gameData &&
          gameData?.map((item, index) => {
            return (
              <div className="d-flex align-items-center accor_heading_title">
                <span>{item.gameName}</span>
              </div>
            )
          })
        }
        {Games &&
          Games?.map((item, index) => {
            return (
              <div className="row">
                <div className="col-xxl-5 col-xl-6 col-lg-5 col-md-7 col-sm-8 col-12">
                  <Accordion
                    defaultActiveKey={1}
                    className="custom_accordio custom_all_event_accor"
                    eventKey={item.id}
                  >
                    <Card>
                      <Card.Header className="accor_header">
                        <CustomToggle
                          eventKey={item.id}
                          // onClick={handleIcon()}
                          className="open_close_icon"
                          icon
                          handleIconm={handleIconss}
                        >
                          <div
                            // onClick={handleIcon()}
                            onClick={() => toggleAccordionn(item.id)}
                            className="accor_collapse"
                          >
                            {isOpenn == item.id ? (
                              <img
                                src={Minus}
                                alt="icons"
                                className="img-fluid plus_minus_icon"
                              />
                            ) : (
                              <img
                                src={Plus}
                                alt="icons"
                                className="img-fluid plus_minus_icon"
                              />
                            )}
                          </div>
                        </CustomToggle>

                        <div className="d-flex align-items-center accor_heading_title">
                          <span>{item.name}</span>
                        </div>
                        <span>{item.count}</span>
                      </Card.Header>

                      <Accordion.Collapse eventKey={item.id}>
                        <Card.Body>
                          <div className="accor_body">
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                checked={item.id == 1 ? true : false}
                              />
                              <span className="ps-2">All {item.count}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                checked={item.id == 1 ? true : false}
                              />
                              <span className="ps-2">{item.subtext1}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                checked={item.id == 1 ? true : false}
                              />
                              <span className="ps-2">{item.subtext2}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2 check_box">
                              <Form.Check
                                type="checkbox"
                                checked={item.id == 1 ? true : false}
                              />
                              <span className="ps-2">{item.subtext3}</span>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default SelectDisplay;
