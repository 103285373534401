import {isEmpty} from '../lib/isEmpty';


export const validateEmail = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
   

    if (isEmpty(value.emailId)) {
        errors.emailId = "Email ID field is required"
    } else if (!(emailRegex.test(value.emailId))) {
        errors.emailId = "Invalid Email ID"
    }

    return errors;
}


export const validateLogin = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;


    if (isEmpty(value.emailId)) {
        errors.emailId = "Email ID field is required"
    } else if (!(emailRegex.test(value.emailId))) {
        errors.emailId = "Invalid Email ID"
    }


    if (isEmpty(value.password)) {
        errors.password = "Password field is required"
    }

    // if (isEmpty(value.otp)) {
    //     errors.otp = "Code field is required"
    // }

    return errors;
}

export const validateVerifyUser = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;


    if (isEmpty(value.emailId)) {
        errors.emailId = "Email ID field is required"
    } else if (!(emailRegex.test(value.emailId))) {
        errors.emailId = "Invalid Email ID"
    }

    if (isEmpty(value.otp)) {
        errors.otp = "Code field is required"
    }

    return errors;
}


export const validatePassword = (value) => {
    let errors = {};
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;

    if (isEmpty(value.password)) {
        errors.password = "Password field is required"
    }else if ((value.password.length < 6) || (value.password.length > 18)) {
        errors.password = "Password must be between 6-18 characters"
    }else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, one lowercase, one number, one special character"
    } 

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm Password field is required"
    } else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "Password and confirm password should match"
    }

    return errors;
}