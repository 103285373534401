import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import logo1 from "../../asset/img/team_logo1.png";
import logo2 from "../../asset/img/team_logo2.png";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Select from "react-select";
import { isEmpty } from "../lib/isEmpty";
import { addOrder, getWalletDetails } from "../actions/gameSettings";
import { useSelector } from "react-redux";
import { toastAlert } from "../lib/toastAlert";

function Ticket({
  isChecked,
  handleChecked,
  handleselect,
  checkInd,
  addNewOrder,
  errors,
  ticket,
  setTicket,
  setselectTicket,
  isSelect,
  setOpenSpotsCount,
  openSpot,
}) {
  const [AddBet, setAddBet] = useState([]);
  const [selectionQueue, setSelectionQueue] = useState(true);
  // const [ticket, setTicket] = useState(0);
  const [CheckInd, setCheckInd] = useState([]);

  const [BetPrice, setBetPrice] = useState();
  const [BetPoint, setBetPoint] = useState();

  const [Price, setPrice] = useState();

  const [Payout, setPayout] = useState();

  const accountData = useSelector((state) => state.account);
  const [walletData, setwalletData] = useState([]);
  const [errors1, setErrors] = useState("");

  useEffect(() => {
    if (accountData) {
      fetchWalletData();
    }
  }, [accountData]);

  const fetchWalletData = async () => {
    try {
      let { status, message, result } = await getWalletDetails({
        email: accountData.emailId,
      });
      if (status == true) {
        setwalletData(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    setCheckInd(checkInd);
  }, [checkInd]);
  const handleAddBet = () => {
    setAddBet([...AddBet, { id: Math.random().toString().slice(2, 6) }]);
  };
  const options = [
    { value: "", label: "Select Bet Type" },
    { value: "Straight", label: "Straight" },
    { value: "Parlay", label: "Parlay" },
  ];
  useEffect(() => {
    if (isChecked) {
      handleselect(options[0]);
    }
  }, [isChecked, checkInd]);
  const handleRemoveBet = (id, index) => {
    const newAdd = [...AddBet];
    newAdd.splice(index, 1);
    setAddBet(newAdd);
  };

  const handleNewTicket = () => {
    setTicket();
  };

  const payOutCalc = (value, price, index, ind) => {
    let CHeckInd = [...CheckInd];
    setPrice(value);
    CHeckInd[ind][index]["Price"] = value;
    let wagerAmount = value;
    let oddSelection = price;
    let winAmount;
    let payout;
    if (price > 1) {
      winAmount = (oddSelection / wagerAmount) * 100;
    } else {
      winAmount = (100 / price) * -wagerAmount;
    }
    payout = (parseFloat(wagerAmount) + parseFloat(winAmount)).toFixed();
    CHeckInd[ind][index]["payout"] = (
      parseFloat(wagerAmount) + parseFloat(winAmount)
    ).toFixed();
    setCheckInd(CHeckInd);
    setPayout(payout);
  };

  // const addNewOrder = async (data) => {
  //   try {
  //     let { status, message, result } = await addOrder(data);
  //     if (status == true) {
  //       // setGameData(result);
  //     }
  //   } catch (err) {
  //   }
  // };

  const options1 = [
    // { value: "", label: "" },
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
  ];

  // const handleselect = (val) => {
  // };

  const handlePoints = (value, index, ind) => {
    console.log(index, ind, "VALUE");
    let CHeckInd = [...CheckInd];
    CHeckInd[ind][index]["point"] = value.point;
    CHeckInd[ind][index]["price"] = value.price;
    setCheckInd(CHeckInd);
    setBetPrice(value.price);
    setBetPoint(value.point);
  };

  return (
    <div className="new_ticket">
      <div className="tickt_btn">
        <Button className="common_bg_full_btn" onClick={handleNewTicket}>
          + New Ticket
        </Button>
      </div>

      <div className="ticket_page_max_width">
        {[...Array(ticket)].map((item, ind) => {
          let CheckInd = checkInd[ind];
          return (
            <div
              className="ticket_content px-4"
              onClick={() => {
                setselectTicket(ind);
              }}>
              <Select
                options={options}
                defaultValue={options[0]}
                className="pet_type mb-4"
                value={
                  !isEmpty(isSelect[ind])
                    ? options.find((val) => val.value == isSelect[ind]?.value)
                    : ""
                }
                onChange={handleselect}
              />
              {console.log(openSpot, "openSpot")}

              {!isEmpty(isSelect) && isSelect[ind]?.value == "Parlay" ? (
                <div className="col-12 col-sm-6 col-lg-12 col-xl-7 selection mb-4 mb-sm-0 mt-lg-4 mt-xl-0">
                  <label className="mb-2">Open Spots</label>
                  <div className="d-flex align-items-center gap-2">
                    <p className="mb-0">Add</p>
                    <Form.Control
                      placeholder="0"
                      className="custom_input_field input_max_wid px-2"
                      value={openSpot}
                      onChange={(e) => {
                        setOpenSpotsCount(e.target.value);
                      }}
                    />
                    <p className="mb-0 ">Open Spots</p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {console.log(CheckInd, "CheckIndCheckInd")}
              {CheckInd &&
                CheckInd?.map((item, index) => {
                  let pointOptions = [
                    {
                      price: item.price,
                      point: item.point,
                      label: `${item.point} ${item.price}`,
                    },

                    {
                      price: item.price - 10,
                      point: item.point + 0.5,
                      label: `${item.point} ${item.price}
                        Buy 
                        0.5
                      for ${item.point + 0.5} ${item.price - 10}`,
                    },

                    {
                      price: item.price - 20,
                      point: item.point + 1,
                      label: `${item.point} ${item.price}
                        Buy 
                        1
                      for ${item.point + 1} ${item.price - 20}`,
                    },
                    {
                      price: item.price - 30,
                      point: item.point + 1.5,
                      label: `${item.point} ${item.price}
                        Buy 
                        1.5
                      for ${item.point + 1.5} ${item.price - 30}`,
                    },
                    {
                      price: item.price - 40,
                      point: item.point + 2,
                      label: `${item.point} ${item.price}
                        Buy 
                        2
                      for ${item.point + 2} ${item.price - 40}`,
                    },
                    {
                      price: item.price - 50,
                      point: item.point + 2.5,
                      label: `${item.point} ${item.price}
                        Buy 
                        2.5
                      for ${item.point + 2.5} ${item.price - 50}`,
                    },
                    {
                      price: item.price - 60,
                      point: item.point + 3,
                      label: `${item.point} ${item.price}
                        Buy 
                        3
                      for ${item.point + 3} ${item.price - 60}`,
                    },
                  ];
                  return (
                    <>
                      <div className="row mb-4">
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 selection mb-4 mb-sm-0">
                          <label className="mb-2">Selection</label>
                          <Form.Control
                            className="custom_input_field "
                            placeholder="Username"
                            value={item.teamName}
                          />
                        </div>

                        {item.type == "h2h" ? (
                          ""
                        ) : item.type != "h2h" && item.buypoint == true ? (
                          <div className="col-12 col-sm-6 col-xl-12 mt-xl-3">
                            <label className="mb-2">Buy Points</label>

                            <Select
                              options={pointOptions}
                              defaultValue={pointOptions[0]}
                              className="mb-4"
                              onChange={(e) => {
                                handlePoints(e, index, ind);
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="row mb-4">
                        <div className="col-3 col-xl-6 selection">
                          <label className="mb-2">Odds Type</label>
                          <Form.Control
                            placeholder="Odd"
                            value={item.type}
                            className="custom_input_field"
                          />
                        </div>

                        <div className="col-3 col-xl-6 selection">
                          <label className="mb-2">Odds Price $</label>
                          <Form.Control
                            placeholder="Odd"
                            value={BetPrice ? BetPrice : item.price}
                            className="custom_input_field"
                          />
                        </div>

                        <div className="col-3 col-xl-6 selection mt-3">
                          <label className="mb-2">Bet Amount</label>
                          <Form.Control
                            placeholder="Bet"
                            value={item?.Price}
                            className="custom_input_field"
                            onChange={(e) => {
                              payOutCalc(
                                e.target.value,
                                BetPrice ? BetPrice : item.price,
                                index,
                                ind
                              );
                            }}
                          />
                          <span className="text_danger">
                            {errors && errors?.data?.length > 0
                              ? errors?.data[index]?.Price
                              : ""}
                          </span>
                        </div>
                        <div className="col-3 col-xl-6 selection mt-3">
                          <label className="mb-2">Payout $</label>
                          <Form.Control
                            className="custom_input_field"
                            value={item?.payout}
                            placeholder="Payout"
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          );
        })}

        {AddBet.map((item, index) => {
          return (
            <>
              <div className="mb-3">
                <div className="make_it_parlay">
                  <div className="px-4 d-flex align-items-center justify-content-between">
                    <p className="txt mb-0">Remove</p>
                    <span onClick={(e) => handleRemoveBet(item.id, index)}>
                      <MdDeleteOutline size={20} className="delete_icon" />
                    </span>
                  </div>
                </div>

                <Select
                  options={options}
                  defaultValue={options[0]}
                  className="pet_type mb-4"
                  onChange={handleselect}
                  value={
                    !isEmpty(isSelect)
                      ? options.find(
                          (val) => val.value == isSelect[index].value
                        )
                      : ""
                  }
                />
                <div className="row mb-4">
                  <div className="col-12 col-sm-6 selection mb-4 mb-sm-0">
                    <label className="mb-2">Selection</label>
                    <Form.Control
                      placeholder="Username"
                      value={item.teamName}
                    />
                  </div>
                  {item.type == "h2h" ? (
                    ""
                  ) : item.type != "h2h" && item.buypoint == true ? (
                    <div className="col-12 col-sm-6 ">
                      <label className="mb-2">Buy Points</label>

                      <Select
                        options={options1}
                        defaultValue={options1[0]}
                        className="pet_type mb-4"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row mb-4">
                  <div className="col-3 selection">
                    <label className="mb-2">Odd</label>
                    <Form.Control placeholder="Odd" />
                  </div>
                  <div className="col-3 selection">
                    <label className="mb-2">Bet</label>
                    <Form.Control placeholder="Bet" />
                  </div>
                  <div className="col-6 selection">
                    <label className="mb-2">Payout</label>
                    <Form.Control placeholder="Payout" />
                  </div>
                </div>
              </div>
            </>
          );
        })}

        {isEmpty(CheckInd) ? (
          ""
        ) : (
          <div className="continue_btn mt-4">
            <Button
              className="common_bg_full_btn"
              onClick={() => {
                let data = {
                  gameName: "",
                  keyName: checkInd[0].sport_key,
                  title: checkInd[0].sport_title,
                  teamName: checkInd[0].teamName,
                  gameTime: checkInd[0].gameTime,
                  oddType: checkInd[0].type,
                  price: checkInd[0].price,
                  homeTeam: checkInd[0].homeTeam,
                  awayTeam: checkInd[0].awayTeam,
                  gameId: checkInd[0].id,
                  wagerAmount: Price,
                  payOut: Payout,
                };
                addNewOrder(CheckInd);
              }}>
              Place Bet
            </Button>
          </div>
        )}

        {isChecked && (
          <div>
            <div className="selection_queue mt-4">
              <p className="txt">Selection Queue</p>
            </div>
            <div className="selec_teams px-4 mt-4">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-12 col-sm-6 col-md-4 col-12 col mb-4 selec_que">
                  <Form.Check
                    type="checkbox"
                    className="mt-0"
                    checked={isChecked}
                    onClick={(e) => handleChecked(e.target.checked, 0)}
                  />
                  <div className="">
                    <div className="team_logo">
                      <img src={logo1} alt="logo" className="img-fluid" />
                      <span>VS</span>
                      <img src={logo2} alt="logo" className="img-fluid" />
                    </div>
                    <div>
                      <p className="mb-0 mt-1 ms-3">-7</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="continue_btn">
              <Link to="/print" className="text-decoration-none">
                <Button
                  className="common_bg_full_btn"
                  onClick={() => {
                    let data = {
                      gameName: "",
                      keyName: checkInd[0].sport_key,
                      title: checkInd[0].sport_title,
                      teamName: checkInd[0].teamName,
                      gameTime: checkInd[0].gameTime,
                      oddType: checkInd[0].type,
                      price: checkInd[0].price,
                      homeTeam: checkInd[0].homeTeam,
                      awayTeam: checkInd[0].awayTeam,
                      gameId: checkInd[0].id,
                      wagerAmount: Price,
                      payOut: Payout,
                    };
                    addNewOrder(CheckInd);
                  }}>
                  Continue
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Ticket;
