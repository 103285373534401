import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { editEmailId } from "../actions/editProfile";
import { validateEmail } from "../validations/login";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";
import { toastAlert } from "../lib/toastAlert";
import { FaEye } from "react-icons/fa";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


const initialFormValue = {
   emailId:""
};

const EditEmailModal = ({ editEmail, setEditEmail, emailContent }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");
  const {
    emailId
  } = formValue;

  const handleClose = () => setEditEmail(false);


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };



  const changeEmail = async () => {
    try{
    let value = {
      emailId: emailId,
    };
    var validate = validateEmail(value);
    if (isEmpty(validate)) {
      let data = {
        newEmail: emailId,
      };
      let { status, error, message } = await editEmailId(data,dispatch);
      if (status == true) {
        // setFormValue(initialFormValue);
        // setErrors(validateEmail(initialFormValue));
        toastAlert("success", message, "changeEmail");
        handleClose()
      } else {
        if (error) {
          setErrors(error);
        }
        toastAlert("error", message, "changeEmail");
      }
    } else {
      setErrors(validate);
    }
  }
  catch(err){
    console.log("changeEmail_err",err)
  }
  };


  return (
    <Modal
      show={editEmail}
      className="cstm_mdls"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="align-items-center">
            <Col xs={12} sm={3}>
              <p className="mdl_lbl mb-0">Email</p>
            </Col>
            <Col xs={12} sm={9}>
              <input
                type="email"
                name="emailId"
                placeholder="Enter you new email id"
                className="mdl_cust_inpts w-100 px-3"
                value={emailId}
                onChange={(e) => handleChange(e)}

              />
              {errors.emailId && (
                      <p className="error-message text-danger mt-2 mb-0">
                        {errors?.emailId}
                      </p>
                    )}
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column-reverse flex-sm-row ">
        <button className="img_btn_invert" onClick={handleClose}>
          Cancel
        </button>

        <button className="img_btn" onClick={()=>{changeEmail()}}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmailModal;
