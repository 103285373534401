import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import blustar from "../../asset/img/blustar.png";
import team_logo1 from "../../asset/img/team_logo1.png";
import team_logo2 from "../../asset/img/team_logo2.png";
import leftvector from "../../asset/img/leftvector.svg";

const LiveCards = () => {
  const [favorites, setFavorites] = useState([
    { id: 1, name: "NHL" },
    { id: 2, name: "Soccer - England" },
    { id: 3, name: "Basketball - Intl Leagues" },
    { id: 4, name: "NHL" },
  ]);
  return (
    <div className="row">
      {favorites.map((e, i) => (
        <div className="col-md-6  mb-xxl-3 mt-5 mt-xxl-0">
          <div className="fborder">
            <div>
              <img src={team_logo1} className="img-fluid teamlogo me-2" />{" "}
              <img src={team_logo2} className="img-fluid teamlogo" />
              <h6 className="mt-3 text-uppercase mb-2">EFL TROPH</h6>
              <p className="sprt mb-0">Football / England</p>
              <p className="sprt">Premiere League</p>
              <button type="button" class="btn btn-light">
                <img src={leftvector} className="img-fluid me-2" /> Live Match
              </button>
            </div>
            <div className="text-end">
              <img src={blustar} className="img-fluid mb-2" />
              <p className="teamp mt-3 mb-3">73.60 Mins</p>
              <p className="teamp">
                <span className="me-2">ALK : 0</span> -{" "}
                <span className="ms-2">3 : PLS</span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LiveCards;
