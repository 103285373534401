import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import { Button } from "react-bootstrap";
import Home from "./components/pages/Home";
import Faq from "./components/pages/faq";
import Signup from "./components/pages/signup";
import Login from "./components/pages/login";
import Openbet from "./components/pages/Openbet/openbet";
import Favorites from "./components/pages/Favorites/favorites";
import { Provider } from "react-redux";
import Dashboard from "./components/pages/dashboard/Dashboard";
import "./Style.css";
import FillOpen from "./components/pages/FillOpen/FillOpen";
import EditProfile from "./components/pages/EditProfile.js/EditProfile";
import Live from "./components/pages/Live/Live";
import History from "./components/pages/History/History";
import LiveMobius from "./components/pages/LiveMobius/LiveMobius";
import ForgotPassword from "./components/pages/ForgotPassword";
import Review from "./components/pages/Review";
import Print from "./components/pages/Print";
import { ToastContainer } from "react-toastify";
import OTPVerification from "./components/pages/OTPVerification";
import ConditionRoute from "./conditionalRoute";
// bootstrap import
import store from "./store";
import isLogin from "../src/components/lib/isLogin";
import { getAuthToken } from "../src/components/lib/localStorage";
import { decodeJwt } from "../src/components/actions/jsonwebToken";
import HelperRoute from "./helperRoute";
import ChangePassword from "./components/pages/ChangePassword/ChangePassword";
import Wallet from "./components/pages/Wallet";
import EmailVerification from "./components/pages/emailVerification";

import TwoFactorAuth from "./components/pages/TwoFactorAuth/TwoFactorAuth";
import ContactUs from "./components/pages/ContactUs";
import SupportTicket from "./components/pages/SupportTicket";

// import Context
import SocketContext from "./components/context/SocketContext";
import { socket } from "./components/config/socketConnectivity";

const App = () => {
  // const { isAuth } = store.getState().auth;

  // useEffect(() => {
  //     if (isAuth != true && isLogin()) {
  //         decodeJwt(getAuthToken(), store.dispatch)
  //     }
  // }, [])

  return (
    // <div className="mt-5">
    //   Select Wager Type
    //   <Button className="custom_btn">
    //     <div className="py-1 px-2 btn_container">start with gaming</div>
    //   </Button>
    //   <Button className="custom_btn">
    //     <div className="py-1 px-2 btn_container">gaming</div>
    //   </Button>
    // </div>

    // <div className="app">
    <div>
      <Provider store={store}>
        <BrowserRouter>
        <SocketContext.Provider value={{ socket }}>
          <ToastContainer />
          <HelperRoute />
          <Switch>
            <ConditionRoute exact path="/" component={Home} type={"public"} />
            <ConditionRoute
              exact
              path="/football"
              component={Home}
              type={"public"}
            />
            {/* <Route path="/" element={<Home />} />
        <Route path="/football" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/fillopen" element={<FillOpen />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/live" element={<Live />} />
        <Route path="/livemobius" element={<LiveMobius />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/history" element={<History />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/openbet" element={<Openbet />} />
        <Route path="/print" element={<Print />} />
        <Route path="/otpverification" element={<OTPVerification />} /> */}
            <ConditionRoute
              exact
              path="/dashboard"
              component={Dashboard}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/dashboard/:id"
              component={Dashboard}
              type={"private"}
            />
            <Route
              exact
              path="/verify-old-email/:authToken"
              component={EmailVerification}
            />
            <Route
              exact
              path="/verify-new-email/:authToken"
              component={EmailVerification}
            />
            <Route
              exact
              path="/email-verification/:authToken"
              component={EmailVerification}
            />

            <ConditionRoute
              exact
              path="/fillopen"
              component={FillOpen}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/profile"
              component={EditProfile}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/twofactorauth"
              component={TwoFactorAuth}
              type={"private"}
            />
            <ConditionRoute exact path="/live" component={Live} type={"auth"} />
            {/* <Route exact path="/livemobius" component={LiveMobius} /> */}

            <ConditionRoute
              exact
              path="/livemobius"
              component={LiveMobius}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/favorites"
              component={Favorites}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/history"
              component={History}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/faq"
              component={Faq}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/signup"
              component={Signup}
              type={"auth"}
            />
            <ConditionRoute
              exact
              path="/login"
              component={Login}
              type={"auth"}
            />
            <ConditionRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/openbet"
              component={Openbet}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/print"
              component={Print}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/otpverification"
              component={OTPVerification}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/changepassword"
              component={ChangePassword}
              type={"private"}
            />
              <ConditionRoute
              exact
              path="/wallet"
              component={Wallet}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/contact_us"
              component={ContactUs}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/support_ticket"
              component={SupportTicket}
              type={"private"}
            />
          </Switch>
          </SocketContext.Provider>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default App;
