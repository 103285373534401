import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdOutlineFileUpload } from "react-icons/md";
import { Link, useHistory, useLocation } from "react-router-dom";
import { createContact } from "../actions/contactus";
import { isEmpty } from "../lib/isEmpty";
import { toastAlert } from "../lib/toastAlert";
import { validateContactUs } from "../validations/contact";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const initialFormValue = {
  email: "",
  name: "",
  PhoneNo: "",
  message: "",
  attachment: "",
};

function ContactUs() {
  const history = useHistory();
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [errors, setErrors] = useState("");
  const { email, name, PhoneNo, message, attachment } = formValue;
  const [MobileNumber, setMobileNumber] = useState();
  const [CountryCode, setCountryCode] = useState('1');

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };
  const handleFile = async (e) => {
    const { name, files } = e.target;

    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const handleContact = async () => {
    try {
      let value = {
        email: email,
        name: name,
        PhoneNo: MobileNumber,
        message: message,
        attachment: attachment,
      };
      var validate = validateContactUs(value);

      var formData = new FormData();
      formData.append("name", name);
      formData.append("PhoneNo", MobileNumber);
      formData.append("phoneCode", CountryCode);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("attachment", attachment);

      if (isEmpty(validate)) {
        let { status, error, message } = await createContact(formData);
        if (status == true) {
          setFormValue(initialFormValue);
          setMobileNumber("");
          setCountryCode("");
          setErrors("");
          toastAlert("success", message, "profile");
          // history.push("/dashboard")
        } else {
          if (error) {
            setErrors(error);
          }
          toastAlert("error", message, "profile");
        }
      } else {
        setErrors(validate);
      }
    } catch (err) {
      console.log("profile_err", err);
    }
  };
  return (
    <>
      <section className="position-relative">
        <div className="ble_blr_cle position-absolute"></div>
        <div className="ble_blr_cle_sm position-absolute d-none d-xl-block"></div>
        <HomeHeader />

        <div style={{ paddingTop: "70px" }}>
          <Container className="custom_container contact_us">
            <div className="header_sec pt-5">
              <p className="hm_var_tle text-center text-xl-start">Contact Us</p>
              <p className="mb-0">
                We are here to help! If you have any questions, please don't
                hesitate to let us know. We'll do our best to provide a prompt
                and helpful response.
              </p>
            </div>
            <div className="contact_info mt-4">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div class="">
                    <label for="name" class="form-label">
                      Your Full Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Full Name"
                      value={name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.name && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.name}
                    </p>
                  )}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div class="">
                    <label for="email" class="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  {errors.email && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.email}
                    </p>
                  )}
                </div>
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div class="">
                    <label for="subject" class="form-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject"
                    />
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div class="">
                    <label for="mobile" class="form-label">
                      Mobile Number
                    </label>
                    {/* <input
                      type="number"
                      class="form-control"
                      id="mobile"
                      name="PhoneNo"
                      value={PhoneNo}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Mobile Number"
                    /> */}

                    <PhoneInput
                      placeholder="Enter Phone Number"
                      country={"us"}
                      value={CountryCode + MobileNumber}
                      onChange={(value, event) => {
                        const { dialCode } = event;
                        let newPhoneNo = value;

                        setMobileNumber(newPhoneNo.slice(dialCode.length));
                        setCountryCode(dialCode);
                      }}
                    />
                  </div>
                  {errors.PhoneNo && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.PhoneNo}
                    </p>
                  )}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div class="">
                    <label for="file" class="form-label">
                      Choose File
                    </label>
                    <div class="upload-btn-wrapper contact_us_file">
                      <button className="btn">
                        <span>
                          {attachment && attachment.name ? (
                            <small>{attachment.name}</small>
                          ) : (
                            <small>Choose File</small>
                          )}
                        </span>
                        <span className="upload_icon">
                          <MdOutlineFileUpload size={20} />
                        </span>
                      </button>
                      <input
                        type="file"
                        name="attachment"
                        onChange={(e) => {
                          handleFile(e);
                        }}
                      />
                    </div>
                    {errors.attachment && (
                      <p className="error-message text-danger mt-2 mb-0">
                        {errors.attachment}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                  <div class="">
                    <label for="message" class="form-label">
                      Message
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="message"
                      name="message"
                      value={message}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter Message"
                      rows={5}
                    />
                  </div>
                  {errors.message && (
                    <p className="error-message text-danger mt-2 mb-0">
                      {errors.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                <Button
                  className="submit_btn img_btn"
                  onClick={() => {
                    handleContact();
                  }}>
                  Submit
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <HomeFooter />
      </section>
    </>
  );
}

export default ContactUs;
