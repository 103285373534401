let key = {};
var env = "demo";

if (env === "production") {
    // console.log("Set Production Config")
    const API_URL = 'https://api.tradingonecapital.com/';
    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        API_URL: 'https://api.tradingonecapital.com/',
        FRONT_URL: 'https://tradingonecapital.com/ ',
        ADMIN_URL: 'https://controls-ztdqb.tradingonecapital.com/',
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        }
    };

}
else if (env === "demo") {
    // console.log("Set Demo Config")
    const API_URL = 'https://backend-mobius.maticz.in';
    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        API_URL: API_URL,
        FRONT_URL: 'https://mobius-gaming.maticz.in',
        ADMIN_URL: 'https://admin-mobius.maticz.in',
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        }

    };

}
else {
    // console.log("Set Development Config")

    const API_URL = 'http://localhost'
    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        API_URL: `${API_URL}:2078`,
        FRONT_URL: 'http://localhost',
        ADMIN_URL: 'http://localhost:3001/admin',
        SOCKET_URL: `${API_URL}:2078`,
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        }

    };
}


export default {
    ...key
};