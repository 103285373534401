import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import img1 from "../../asset/img/img1.png";
import img2 from "../../asset/img/img2.png";
import img3 from "../../asset/img/img3.png";
import headphone from "../../asset/img/headphone.gif";
import AOS from "aos";
import "aos/dist/aos.css";
import { getFaq } from "../actions/faq";
import { addNewsLetterUser } from "../actions/faq";
import { toastAlert } from "../lib/toastAlert";
import { validateEmail, validateLogin } from "../validations/login";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";

const Faq = () => {
  const [getFaqdata, setGetFaqdata] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    fetchFaq();
  }, []);

  const fetchFaq = async () => {
    try {
      const { status, loading, error, result } = await getFaq();
      if (status == true) {
        setGetFaqdata(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const newsLetterAdd = async () => {
    try {
      let value = {
        emailId: email,
      };
      var validate = validateEmail(value);

      let encryptToken = {
        token: encryptObject(value),
      };

      const { status, error, result, message } = await addNewsLetterUser(
        encryptToken
      );
      if (status == true) {
        // setGetFaqdata(result);
        toastAlert("success", message);
      } else {
        toastAlert("error", message);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section>
      <HomeHeader />

      <div className="faq allsection">
        <div className="container">
          <div className="row faqbanner text-center pt-4 pb-5">
            <div
              className="col-md-8 mx-auto"
              data-aos="fade-up"
              data-aos-duration="1000">
              <h3>FAQ</h3>
              <h1 className="mb-3">We’re here to help</h1>
              <p>
                We’ve got the best odds, and hundreds of sports for you to
                choose from.
              </p>
              <button className="btn img_btn getbtn mt-2">Get Started</button>
            </div>
          </div>

          <div className="row fq text-center pt-5 pb-5">
            <div className="col-md-8 pt-5 mx-auto">
              <h1 className="mb-3">Frequently asked questions</h1>
              <p>
                Nothing compares to online betting at linescenter.com. We’ve got
                the best odds, and hundreds
              </p>
              {getFaqdata && getFaqdata.length > 0
                ? getFaqdata?.map((item, key) => {
                    return (
                      <>
                        <div className="pt-4 pb-4">

                          <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                              <h2
                                class="accordion-header"
                                id={`headingOne${key}`}>
                                <button
                                  collapsed
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapseOne${key}`}
                                  aria-expanded="true"
                                  aria-controls={`collapseOne${key}`}>
                                  {item?.question}
                                </button>
                              </h2>
                              <div
                                id={`collapseOne${key}`}
                                class="accordion-collapse collapse "
                                aria-labelledby={`headingOne${key}`}
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body text-start">
                                  <p className="text-start">{item?.answer}</p>
                                </div>
                              </div>
                            </div>
                            {/* <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Lorem Ipsum is simply dummy text ?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body text-start">
                        <p className="text-start">
                          Nothing compares to online betting at linescenter.com.
                          We’ve got the best odds, and hundreds of sports for
                          you to choose from.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Lorem Ipsum is simply dummy text ?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body text-start">
                        <p className="text-start">
                          Nothing compares to online betting at linescenter.com.
                          We’ve got the best odds, and hundreds of sports for
                          you to choose from.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Lorem Ipsum is simply dummy text ?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body text-start">
                        <p className="text-start">
                          Nothing compares to online betting at linescenter.com.
                          We’ve got the best odds, and hundreds of sports for
                          you to choose from.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Lorem Ipsum is simply dummy text ?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body text-start">
                        <p className="text-start">
                          Nothing compares to online betting at linescenter.com.
                          We’ve got the best odds, and hundreds of sports for
                          you to choose from.
                        </p>
                      </div>
                    </div>
                  </div> */}
                          </div>
                        </div>
                      </>
                    );
                  })
                : "No Faq's found"}
            </div>
          </div>

          <div
            className="question mb-5"
            data-aos="zoom-in"
            data-aos-duration="1200">
            <div className="row text-center">
              <div className="col-md-8  mx-auto">
                <div className="qimg">
                  <img src={img1} className="img1 img-fluid" />
                  <img src={img2} className="img2 img-fluid" />
                  <img src={img3} className="img3 img-fluid" />
                </div>
                <h4 className="mt-4">Still have questions?</h4>
                <p>
                  Nothing compares to online betting at linescenter.com.We’ve
                  got the best odds, and hundreds
                </p>
                <button className="btn img_btn mt-1 fw-500">
                  Get in touch
                </button>
              </div>
            </div>
          </div>

          <div className="row newsletter text-center pt-4 pb-4">
            <div className="col-md-8 mx-auto">
              <h1 className="fw-500">Sign up for Newsletter</h1>
              <p>
                Nothing compares to online betting at linescenter.com. We’ve got
                the best odds, and hundreds
              </p>
              <div class="row g-3 mt-2 align-items-baseline justify-content-center">
                <div class="col-auto W-50 text-start">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <span className="text-start smalltxt">
                    We care about your data in our{" "}
                    <a href="/" target="_blank">
                      {" "}
                      Privacy policy
                    </a>
                  </span>
                </div>
                <div class="col-auto">
                  <span id="passwordHelpInline" class="form-text">
                    <button
                      className="btn img_btn mt-0 fw-500"
                      onClick={() => newsLetterAdd()}>
                      Subscribe
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="earphone">
          <img src={headphone} className="headphone img-fluid" />
        </div>
      </div>

      <HomeFooter />
    </section>
  );
};

export default Faq;
