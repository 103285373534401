import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getUserWalletData, sendClaimRequest } from "../actions/userlogin";
import Sidebar from "./dashboard/Sidebar";
import Header from "./dashboard/Header";
import { toastAlert } from "../lib/toastAlert";

const Wallet = () => {
  const [walletData, setWalletData] = useState();
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    getWalletDetails();
  }, []);

  const getWalletDetails = async () => {
    try {
      let { status, message, result } = await getUserWalletData();
      console.log(
        "🚀 ~ getWalletDetails ~ status, message, result:",
        status,
        message,
        result
      );
      if (status == true) {
        setWalletData(result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    console.log("qhgfefuew", amount);
    try {
      if (amount <= 0) {
        toastAlert(
          "error",
          "Claim Amount Should Greater Than Zero",
          "add agent"
        );
      } else if (amount >= walletData?.userBalance) {
        toastAlert(
          "error",
          "Claim Amount Should Lesser Than Balance",
          "add agent"
        );
      } else {
        let respData = {
          id: walletData.userId,
          amount: amount,
        };
        let { status, error, message } = await sendClaimRequest(respData);
        if (status == true) {
          toastAlert("success", message);
          setAmount(0);
          getWalletDetails();
        } else {
          toastAlert("error", message);
        }
      }
    } catch (error) {
      console.log(error, "handleSubmit");
    }
  };

  return (
    <div className="dashboard_sec">
      <div className="container-fluid d-flex px-0">
        <div className="left_side_sec">
          <Sidebar />
        </div>
        <div className="right_side_sec px-2 py-2 px-sm-4 py-sm-4">
          <Header />

          <div className="container">
            <div className="signup login allsection h-100vh">
              <div className="row">
                <div className="col-md-10 col-lg-10 mx-auto">
                  <h4 className="text-center mb-3">Wallet Details</h4>
                  <div className="signborder">
                    <div className="row">
                      <div className="col-md-12 mt-4 mt-sm-4">
                        <div class="">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Wallet Id
                          </label>
                          <Row className="mx-auto">
                            <Col xs={10} sm={11} className="px-0">
                              <input
                                class="form-control"
                                id="inputPassword"
                                placeholder="Enter your password"
                                name="password"
                                value={walletData?.walletAddress}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4 mt-sm-4">
                        <div class="">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            User Balance
                          </label>
                          <Row className="mx-auto">
                            <Col xs={10} sm={11} className="px-0">
                              <input
                                class="form-control"
                                id="inputPassword"
                                placeholder="Enter your new password"
                                name="newPassword"
                                value={walletData?.userBalance}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="col-md-12 mt-4 mt-sm-4">
                        <div class="">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label">
                            Claim Amount
                          </label>
                          <Row className="mx-auto">
                            <Col xs={10} sm={11} className="px-0">
                              <input
                                class="form-control"
                                id="amount"
                                value={amount}
                                type="text"
                                onChange={(e) => handleChange(e)}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="col-md-12 text-center">
                        <div class="mt-0 d-flex flex-column-reverse align-items-center flex-sm-row justify-content-sm-end">
                          <button
                            className="btn img_btn mt-3 mt-sm-3 me-2"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}>
                            Claim Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
