import React, { useEffect, useState } from "react";
import HomeHeader from "../common-components/HomeHeader";
import HomeFooter from "../common-components/HomeFooter";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { validatePassword } from "../validations/login";
import { isEmpty } from "../lib/isEmpty";
import { encryptObject } from "../lib/crypto";
import { toastAlert } from "../lib/toastAlert";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { resetPassword } from "../actions/forgotPassword";


const initialFormValue = {

  password: "",
  confirmPassword: "",
};


const ForgotPassword = () => {

  const history = useHistory();
  const [formValue, setFormValue] = useState({ initialFormValue });
  const [passwordType, setPasswordType] = useState("password");
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);
  const [errors, setErrors] = useState("");
  const {
    password,
    confirmPassword,
  } = formValue;
  const location = useLocation()
  const handlePasswordIconChange = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
    setPasswordView(!passwordView);
  };
  const handleConfirmPasswordIconChange = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
    setConfirmPasswordView(!confirmPasswordView);
  };


  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let error = { ...errors, ...{ [name]: "" } };
    setErrors(error);
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };


  var resetPasswordFn = async () => {
    let value = {
      password: password,
      confirmPassword: confirmPassword,
    };
    var validate = validatePassword(value);
    let data = {
      password: password,
      emailId: location.state.emailId
    };

    let encryptToken = {
      token: encryptObject(data),
    };

    if (isEmpty(validate)) {
      let { status, error, message } = await resetPassword(encryptToken);
      if (status == true) {
        setFormValue(initialFormValue);
        setErrors(validatePassword(initialFormValue));
        toastAlert("success", message, "resetPassword");
        history.push("/login");
      } else {
        if (error) {
          console.log("errr", error)
          setErrors(error);
        }
        toastAlert("error", error, "resetPassword");
      }
    } else {
      setErrors(validate);
    }
  };


  useEffect(() => { }, []);

  return (
    <section>
      <HomeHeader />

      <div className="container">
        <div className="signup forgot_pwd allsection h-100vh">
          <div className="row">
            <div className="col-md-10 col-lg-10 mx-auto">
              <h4 className="text-center mb-3">RESET PASSWORD</h4>
              <div className="signborder">
                <div className="row">
                  <div className="col-md-12  mt-2">
                    <div class="mb-3 position-relative">
                      <label for="exampleFormControlInput1" class="form-label">
                        Password
                      </label>
                      <Row className="mx-auto">
                        <Col xs={10} className="px-0">

                          <input
                            type={passwordType}
                            class="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Password"
                            name="password"
                            value={password}
                            onChange={(e) => handleChange(e)}
                          />  </Col>

                        <Col

                          className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0"
                          xs={2}
                        >
                          <div onClick={handlePasswordIconChange}>
                            {passwordView ? (
                              <FaEye className="" />
                            ) : (
                              <FaEyeSlash className="" />
                            )}
                          </div>
                        </Col>
                      </Row>

                      {errors.password && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.password}
                        </p>
                      )}


                      {/* <span className="getcode">Get Code</span> */}
                    </div>
                    {/* <input
                      type="text"
                      class="form-control mt-3"
                      id="name"
                      placeholder="Enter code"
                    /> */}
                  </div>
                  <div className="col-md-12 mt-4">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Confirm Password
                      </label>
                      <Row className="mx-auto">

                      <Col xs={10} className="px-0">
                    
                      <input
                        type={confirmPasswordType}
                        class="form-control"
                        id="inputPassword"
                        placeholder="Re-Enter Your Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => handleChange(e)}
                      />
                        </Col>
                      <Col
                        className="sgn_inpt_eye_icn  d-flex align-items-center justify-content-end ps-0"
                        xs={2}
                      >
                        <div onClick={handleConfirmPasswordIconChange}>
                          {confirmPasswordView ? (
                            <FaEye className="" />
                          ) : (
                            <FaEyeSlash className="" />
                          )}
                        </div>
                      </Col>
                      </Row>
                      {errors.confirmPassword && (
                        <p className="error-message text-danger mt-2 mb-0">
                          {errors.confirmPassword}
                        </p>
                      )}
                      <Link
                        to="/login"
                        className="text-decoration-none forgot mt-2"
                      >
                        Back to Login
                      </Link>
                      {/* <a className="forgot mt-2" href="/login">
                        
                      </a> */}
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    <div class="mt-3">
                      {/* <Link to="/login"> */}
                      <button className="btn img_btn mt-1" onClick={() => { resetPasswordFn() }}>
                        Reset Password
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
