import React from "react";
import Sidebar from "../pages/dashboard/Sidebar";
import Header from "../pages/dashboard/Header";
import Review from "./Review";

function Print() {
  return (
    <div>
      <div className="dashboard_sec">
        <div className="container-fluid d-flex px-0">
          <div className="left_side_sec">
            <Sidebar />
          </div>
          <div className="right_side_sec px-2 py-2 px-sm-4 py-sm-4">
            <Header />
            <Review />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Print;
