// import constant
import { SET_ODDS_DATA } from "../constants";

const initialValue = {
  isAuthenticated: false,
  oddData: [],
  oddMessage:""
};
const oddData = (state = initialValue, action) => {
  console.log(action.message,"action+++++++++++++++")
  switch (action.type) {
    case SET_ODDS_DATA:
      return {
        ...state,
        oddData: action.data,
        oddMessage:action.message
      };

    default:
      return state;
  }
};

export default oddData;
