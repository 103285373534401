import {isEmpty} from '../lib/isEmpty';

var numbers = /^[0-9]+$/;


export const validateRegister = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/;
    var letters = /^[A-Za-z\s]*$/;

    if (isEmpty(value.firstName)) {
        errors.firstName = "First Name field is required"
    } else if (!(letters.test(value.firstName))) {
        errors.firstName = "Invalid First Name"
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "Last Name field is required"
    } else if (!(letters.test(value.lastName))) {
        errors.lastName = "Invalid Last Name"
    }

    if (isEmpty(value.emailId)) {
        errors.emailId = "Email ID field is required"
    } else if (!(emailRegex.test(value.emailId))) {
        errors.emailId = "Invalid Email ID"
    }


    if (isEmpty(value.dateOfBirth)) {
        errors.dateOfBirth = "Date of birth field is required"
    }

    if (isEmpty(value.password)) {
        errors.password = "Password field is required"
    }else if ((value.password.length < 6) || (value.password.length > 18)) {
        errors.password = "Password must be between 6-18 characters"
    }else if (!(passwordRegex.test(value.password))) {
        errors.password = "Password should contain atleast one uppercase, one lowercase, one number, one special character"
    } 

    if (isEmpty(value.confirmPassword)) {
        errors.confirmPassword = "Confirm Password field is required"
    } else if (!(isEmpty(value.confirmPassword)) && value.password != value.confirmPassword) {
        errors.confirmPassword = "Password and confirm password should match"
    }

    return errors;
}