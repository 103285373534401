import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import qrcode from "../../../asset/img/footballone.jpg";
import { toastAlert } from "../../lib/toastAlert";
import { useDispatch } from "react-redux";
import { twoFAvalidation } from "../../validations/2fa";
import { isEmpty } from "../../lib/isEmpty";
import { twoFAStatus } from "../../lib/displayStatus"
import { encryptObject } from "../../lib/crypto";
import { get2faCode, enable2faCode, disable2faCode } from "../../actions/2FaSettings";
import { isMobile, mobileType } from '../../lib/browser'
import key from '../../config/config';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const TwoFactorAuthContent = () => {

  const dispatch = useDispatch()
  // state
  const [secretData, setSecretData] = useState();
  const [loader, setLoader] = useState();
  const [code, setCode] = useState('')
  const [validateError, setValidateError] = useState({});
  const [checkValue, setCheckValue] = useState(false)

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!(value == '' || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
      return
    }
    setCode(value)
    if (!isEmpty(validateError)) {
      setValidateError({})
    }
  }





  const fetchTwoFA = async () => {
    try {
      const { status, loading, message, result } = await get2faCode();

      if (status == true) {
        setSecretData(result)
      }
    } catch (err) {
      console.log("fetchTwoFA", err)
    }
  }

  const disableTwoFa = async (data) => {
    let reqData = {
      "secret": secretData.secret,
      "uri": secretData.uri,
      "code": code
    }
    let validationError = twoFAvalidation(reqData)

    if (!isEmpty(validationError)) {
      setValidateError(validationError)
      return
    }

    let encryptToken = {
      token: encryptObject(reqData),
    };


    try {
      const { status, loading, error, message, result } = await disable2faCode(encryptToken, dispatch);
      if (status == true) {
        toastAlert('success', message, 'twoFA')
        setCode('')
        setSecretData(result)
      } else {
        if (error) {
          setValidateError(error)
        // } else if (error) {
          toastAlert('error', error, 'twoFA')
        }
      }
    } catch (err) {
      console.log("disableTwoFa", err)

    }
  }

  const enableTwoFa = async () => {

    let reqData = {
      "secret": secretData.secret,
      "uri": secretData.uri,
      "code": code
    }
    let validationError = twoFAvalidation(reqData)
    console.log("validationError", validationError)
    if (!isEmpty(validationError)) {
      setValidateError(validationError)
      return
    }


    let encryptToken = {
      token: encryptObject(reqData),
    };

    try {
      const { status, loading, error, message, result } = await enable2faCode(encryptToken, dispatch);
      setLoader(loading)
      if (status == true) {
        toastAlert('success', message, 'twoFA')
        setCode('')
        setSecretData(result)
      } else {
        if (error) {
          setValidateError(error)
        // } else if (error) {
          toastAlert('error', error, 'twoFA')
        }
      }
    }
    catch (err) {
      console.log("enableTwoFa", err)

    }
  }


  useEffect(() => {
    fetchTwoFA();
  }, [])




  return (
    <div>
      <Card className="custom_card">
        <Card.Body>
          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
            <p className=" ep_tle ">Security settings</p>
            {/* <p className="text-secondary" style={{ fontSize: "14px" }}>
              29-01-2024, chrome, 3i434979we8r79w8er9we8r9we9r89er9{" "}
              <span className="text-white">- Last Login</span>
            </p> */}
          </div>
          <div className="two_stp_tle_wrp">
            <p className="two_stp_tle">2 Step Authentication</p>
          </div>
          <Row className="mx-auto mt-4">
            <Col className="ps-0" xs={12} xxl={6}>
              <div>
                <p className="two_stp_tle_wrp pb-3">
                  2 Factor Authentication{" "}
                  <span className="text-secondary">Status -</span>{" "}
                  <span className="text-success">
                    {secretData?.twoFaStatus}</span>
                </p>

                <Row>
                  <Col xs={12} md={6} lg={5}>
                    <div>
                      <p className="text-center " style={{ fontSize: "14px" }}>
                        Scan QR Code
                      </p>
                      <div className="two_stp_qr_wrp mx-auto">
                        <img src={secretData && secretData?.imageUrl}
                          alt="qr code" className="img-fluid" />


                        {/* <img src={qrcode} alt="qr code" className="img-fluid" /> */}
                      </div>
                      {
                        validateError.imageUrl && <p className="error-message text-danger mt-2 mb-0">{validateError.imageUrl}</p>
                      }
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={7}>
                    <div className="d-flex flex-column">
                      <label className="text-secondary fw-bold mb-2">
                        Your 32 Digit Security Code
                      </label>
                      <Row className="mb-3 two_stp_cbn_inpt mx-auto w-100 border border-secondary rounded">
                        <Col
                          xs={9}
                          sm={10}
                          className="border border-top-0 border-left-0 border-bottom-0 border-start-0 border-secondary px-3"
                        >
                          <input
                            type="text"
                            className="border-0 h-100 bg-transparent w-100 p-0"
                            value={secretData && secretData?.secret}
                            disabled
                          />



                        </Col>
                        <Col
                          xs={3}
                          sm={2}
                          className="d-flex align-items-center justify-content-center"
                        >

                          <div className="input-group-append">
                            <CopyToClipboard
                              text={secretData && secretData?.secret}
                              onCopy={() => { toastAlert("success", "Copied!", 'twoFa') }}
                            >
                              <a href="#" className="btn btnType1 py-0 my-0 px-2">
                                <i class="fas fa-copy"></i>
                              </a>
                            </CopyToClipboard>

                          </div>

                          {/* <FaCopy /> */}
                        </Col>
                      </Row>

                      {
                        validateError.secret && <p className="error-message text-danger mt-2 mb-0">{validateError.secret}</p>
                      }
                      <label className="text-secondary fw-bold mb-2">
                        Enter 6 Digit 2FA Code
                      </label>
                      <input
                        type="text"
                        className="mb-0 border border-secondary rounded bg-transparent two_stp_cbn_inpt px-3"
                        name="code"
                        value={code}
                        onChange={handleChange}
                      />


                      {
                        validateError?.code && <p className="error-message text-danger mt-2 mb-0">{validateError?.code}</p>
                      }

                      {
                        secretData && secretData?.twoFaStatus == "Disabled" && <button
                          className="img_btn mx-auto mt-3"
                          type="button"
                          onClick={enableTwoFa}
                        >

                          {twoFAStatus(secretData?.twoFaStatus, 'button')}
                        </button>
                      }
                      {
                        secretData && secretData?.twoFaStatus == "Enabled" && <button
                          className="img_btn mx-auto mt-3"
                          type="button"
                          onClick={disableTwoFa}
                        >

                          {twoFAStatus(secretData?.twoFaStatus, 'button')}
                        </button>
                      }

                      {/* <button className="two_stp_btn mt-2">Disabled</button> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="mt-4" xs={12} xxl={6}>
              <div>
                <p className="two_stp_tle">Notes</p>
                <p>
                  Enable Two Factor Authentication as an additional security
                  measure, to protect your funds and be able to withdraw.Please
                  use Google Authenticator which you can{" "}
                  <button className="border-0 bg-transparent two_stp_dwn_btn font-italic">
                    {
                      isMobile() && mobileType() == 'android' && <a href={key.AUTHENTICATOR_URL.PLAY_STORE}>Download here</a>
                    }
                    {
                      isMobile() && mobileType() == 'ios' && <a href={key.AUTHENTICATOR_URL.APP_STORE}>Download here</a>
                    }
                    {
                      !isMobile() && <a
                        href={mobileType() == 'mac' ? key.AUTHENTICATOR_URL.APP_STORE : key.AUTHENTICATOR_URL.PLAY_STORE}
                        target="_blank"
                      >
                        Download here
                      </a>
                    }
                  </button>
                </p>

                <p className="two_stp_tle">How to enable ?</p>
                <p>
                  Please download the google authenticator by clicking "Download
                  here" and scan the QR code.You will be receiving a 6 digit
                  code, which need to enter in 2FA box.
                </p>
                <p>
                  Right down the 32 digit code in a white paper for your
                  security if you forgot the 2FA code to withdraw funds
                </p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TwoFactorAuthContent;
