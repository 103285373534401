import { isEmpty } from "../src/components/lib/isEmpty";
import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../src/components/actions/userlogin";
import { sportData } from "../src/components/actions/sportData";
import { weekcalculation } from "../src/components/lib/weekCal";
import { getGamedata } from "../src/components/actions/gameSettings";
import { getCmsList } from "../src/components/actions/cms";
import { getsiteSettings } from "../src/components/actions/siteSettings";
import SocketContext from "../src/components/context/SocketContext";
import { useHistory } from "react-router-dom";
import { logout } from "./components/actions/logout";
const HelperRoute = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  const socketContext = useContext(SocketContext);
  const { isAuth, userId } = useSelector((state) => state.auth);
  // console.log('HelperRoute', isAuth, userId)

  useEffect(() => {
    // console.log("sportData")
    var data = {
      week: weekcalculation(),
      season: new Date().getFullYear(),
      // season: "2023",
    };
    sportData(data, dispatch);
    getGamedata(dispatch);
    getCmsList(dispatch);
    getsiteSettings(dispatch);
    // console.log('HelperRoute', isAuth, userId)
    if (!isEmpty(localStorage.getItem("user_token"))) {
      getUserData(dispatch);
    }
  }, [isAuth]);

  useEffect(() => {
    socketContext.socket.on("FORCE_LOGOUT", (result) => {
      let token = localStorage.getItem("user_token");
      if (result && token != result) {
        logout(history, dispatch);
      }
    });
  }, [socketContext]);
};

export default HelperRoute;
