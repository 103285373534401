import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";
import {
    SET_SPORT_DATA
  } from "../constants";

export const setsportData = (data) => {
    // console.log('setsportDatadats----------------------->',data)
    return {
  
      type: SET_SPORT_DATA,
      data: {
        data: data,
     
      },
    };  
  };


export const sportData = async (data,dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.sportData,
            'method': 'post',
            'data': data
        });
        // console.log('sportData',respData.data.data)
        dispatch(setsportData(respData.data.data))

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'sportData_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}