import React, { useEffect, useState } from "react";
import MobiusCard from "../MobiusCard";
import logo1 from "../../../asset/img/team_logo1.png";
import logo2 from "../../../asset/img/team_logo2.png";
import { useSelector } from "react-redux";
import { getLiveOdds } from "../../actions/gameSettings";

const LiveMobiusContent = () => {
  // const Teams = [
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "1nd half 60’’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  //   {
  //     logo1: logo1,
  //     logo2: logo2,
  //     half: "2nd half 67’",
  //     leaguage: "Football / England Premiere Leaguage",
  //     name1: "Atlanta Falcons",
  //     name1No: 3,
  //     name2: "Houston Texans",
  //     name2No: 1,

  //     date: "DEC 01",
  //     dateNo: 305,
  //     dateName: "OREGON1",
  //     number1: "-10-110",
  //     number11: "o66-110",
  //     number111: "-400",

  //     time: "8:00 PM",
  //     timeNo: 306,
  //     timeName: "WASHINGTON",
  //     number2: "+10-110",
  //     number22: "u66-110",
  //     number222: "+308",
  //   },
  // ];

  const[Teams,setTeams] = useState()

  const accountData = useSelector((state) => state.account);
  useEffect(() => {
    fetchLiveOdds();
  }, [accountData]);

  const fetchLiveOdds = async () => {
    try {
      let { status, message, result } = await getLiveOdds();
      if (status == true) {
        setTeams(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };



  return (
    <div>
      <MobiusCard Teams={Teams} />
    </div>
  );
};

export default LiveMobiusContent;
