// import constant
import { SET_ORDER_DATA } from "../constants";

const initialValue = {
  isAuthenticated: false,
  ordersData: [],
};
const ordersData = (state = initialValue, action) => {
  switch (action.type) {
    case SET_ORDER_DATA:
      return {
        ...state,
        ordersData: action.data,
      };

    default:
      return state;
  }
};

export default ordersData;
