import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { momentFormat } from "../lib/dateFormat";

function WagerModal({ show, handleClose, item }) {
  const Wager = [
    {
      Details: "[129] TENNESSEE TITANS +14-110",
      Status: "Pending",
      GameDate: "DEC 11 2023 08:15",
      Teams: "TV BROADCAST: ESPN[129] TENNESSEE TITANS[130] MIAMI DOLPHINS",
      Score1: "-1",
      Score2: "-1",
    },
    {
      Details: "[130] TOTAL U46-110 (TENNESSEE TITANS VRS MIAMI DOLPHINS)",
      Status: "Pending",
      GameDate: "DEC 11 2023 08:15",
      Teams: "TV BROADCAST: ESPN[129] TENNESSEE TITANS[130] MIAMI DOLPHINS",
      Score1: "-1",
      Score2: "-1",
    },
    {
      Details: "OPEN PLAY",
      Status: "Pending",
      GameDate: "DEC 11 2023 04:39",
      Teams: "",
      Score1: "",
      Score2: "",
    },
    {
      Details: "OPEN PLAY",
      Status: "Pending",
      GameDate: "DEC 11 2023 04:39",
      Teams: "",
      Score1: "",
      Score2: "",
    },
  ];
  return (
    <div className="wager_popup">
      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="wager_modal"
        size="xl">
        <Modal.Header closeButton>
          <Modal.Title>WAGER DETAILS FOR TICKET  #{item.ticketId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row wager_details">
            <div className="col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Wager Type</span>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="content"
                    readOnly
                    value={item.betType}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Date Placed</span>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="content"
                    readOnly
                    value={momentFormat(item.createdAt)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Settled Dated</span>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="content"
                    readOnly
                    value={momentFormat(item.updatedAt)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Risk Amount $</span>
                </div>
                <div className="col-8">
                  {item.betType == "Straight" ? (
                    <input
                      type="text"
                      className="content"
                      readOnly
                      value={item.wagerAmount}
                    />
                  ) : (
                    <input
                      type="text"
                      className="content"
                      readOnly
                      value={item.totalLossamount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Win Amount $</span>
                </div>
                <div className="col-8">
                {item.betType == "Straight" ? (
                    <input
                      type="text"
                      className="content"
                      readOnly
                      value={item.payOut}
                    />
                  ) : (
                    <input
                      type="text"
                      className="content"
                      readOnly
                      value={item.totalWinamount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 cols">
              <div className="row">
                <div className="col-4">
                  <span className="heading">Tax Amount $</span>
                </div>
                <div className="col-8">
                  <input type="text" className="content" readOnly value="0" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
              <div className="row">
                <div className="col-4">
                  <span className="heading">PTS purchased</span>
                </div>
                <div className="col-8">
                  <input type="text" className="content" readOnly value="0" />
                </div>
              </div>
            </div>
          </div>
          <div className="table_data mt-3">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Status</th>
                  <th className="game_date">Game Date</th>
                  <th>Teams</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {
                  item.betType == "Straight" ? (
                    <tr>
                      <td>
                        {item.teamName} <br></br>
                        {"Price "}
                        {item.price}
                        <br></br>
                        {item.point ? item.point : ""}
                        <br></br>
                        {item.pointType}
                      </td>
                      <td>{item.winStatus ? item.winStatus : "Pending"}</td>
                      <td>{momentFormat(item.gameTime)}</td>
                      <td>
                        {item.homeTeam}
                        <br></br>
                        {item.awayTeam}
                      </td>
                      <td>
                        <p>{item.score1}</p>
                        <p>{item.score2}</p>
                      </td>
                    </tr>
                  ) : (
                    item.bettingData &&
                    item.bettingData?.map((bettingItem, index) => {
                      return (
                        <tr>
                          <td>
                            {bettingItem.teamName} <br></br>
                            {"Price "}
                            {bettingItem.price}
                            <br></br>
                            {bettingItem.point ? bettingItem.point : ""}
                            <br></br>
                            {bettingItem.pointType}
                          </td>
                          <td>
                            {bettingItem.winStatus
                              ? bettingItem.winStatus
                              : "Pending"}
                          </td>
                          <td>{momentFormat(bettingItem.gameTime)}</td>
                          <td>
                            {bettingItem.homeTeam}
                            <br></br>
                            {bettingItem.awayTeam}
                          </td>
                          <td>
                            <p>{bettingItem.score1}</p>
                            <p>{bettingItem.score2}</p>
                          </td>
                        </tr>
                      );
                    })
                  )

                  //  {Wager.map((item, index) => {
                  //   return (
                  //     <tr>
                  //       <td>{item.Details}</td>
                  //       <td>{item.Status}</td>
                  //       <td>{item.GameDate}</td>
                  //       <td>{item.Teams}</td>
                  //       <td>
                  //         <p>{item.Score1}</p>
                  //         <p>{item.Score2}</p>
                  //       </td>
                  //     </tr>
                  //   );
                  // })}
                }

                {/* {Wager.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.Details}</td>
                      <td>{item.Status}</td>
                      <td>{item.GameDate}</td>
                      <td>{item.Teams}</td>
                      <td>
                        <p>{item.Score1}</p>
                        <p>{item.Score2}</p>
                      </td>
                    </tr>
                  );
                })} */}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="close_btn common_bg_full_btn"
            onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default WagerModal;
