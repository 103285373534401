import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";
import { setAccountData } from "./userlogin"

export const editProfile = async (data, dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.editProfile,
            'method': 'post',
            'data': data
        });
        dispatch(setAccountData(respData?.data?.result))

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'editProfile_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}


export const editEmailId = async (data) => {
    // console.log("sentVerifyLink", data);
    try {
        let respData = await axios({
            'url': key.API_URL + api.editEmail,
            'method': 'post',
            'data': data
        });
   
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'editEmail_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}


export const sentVerifyLink = async (data) => {
    // console.log("sentVerifyLink", data);
    try {
        let respData = await axios({
            'url': key.API_URL + api.sentVerifyLink,
            'method': 'post',
            'data': data
        });
   
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'sentVerifyLink_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const verifyNewEmail = async (data) => {
    // console.log("verifyNewEmail", data);
    try {
        let respData = await axios({
            'url': key.API_URL + api.verifyNewEmail,
            'method': 'post',
            'data': data
        });
   
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'verifyNewEmail_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const changePassword = async (data) => {
    // console.log("verifyNewEmail", data);
    try {
        let respData = await axios({
            'url': key.API_URL + api.changePassword,
            'method': 'post',
            'data': data
        });
   
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'verifyNewEmail_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const confirmMail = async (data) => {
    // console.log("confirmMail", data);
    try {
        let respData = await axios({
            'url': key.API_URL + api.confirmMail,
            'method': 'post',
            'data': data
        });
   
        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'confirmMail_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}