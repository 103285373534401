import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";
import {
    SET_CMS_DATA
  } from "../constants";


export const setCmsData = (data) => {
    // console.log('setcmsdata---->',data)
    return {
      type: SET_CMS_DATA,
      data: data,
    };  
  };



export const getCmsList = async (dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.getCmsList,
            'method': 'get',
        });
        // console.log("getCmsList",respData)
        dispatch(setCmsData(respData?.data?.result))

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'getCmsList_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const getCmsImage = async (dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.getuserCmsImage,
            'method': 'get',
        });
        // console.log("getuserCmsImage",respData)
        // dispatch(setCmsData(respData?.data?.result))

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'getCmsList_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}