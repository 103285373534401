import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";

export const createContact = async (data, dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.contactus,
            'method': 'post',
            'data': data
        });

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'contactus_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}
