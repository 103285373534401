import React, { useState, useEffect } from "react";

import { useAccordionButton } from "react-bootstrap/AccordionButton";
import {
  getGamedata,
  getOddsList,
  getGamedatas,
} from "../actions/gameSettings";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../lib/isEmpty";

function SingelEvents({ gameType, handleselect }) {
  const [icon, setIcon] = useState(true);
  const dispatch = useDispatch();
  const { selectedSport } = useSelector((state) => state.sportData);

  useEffect(() => {
    // fetchGameData();
  }, []);

  const [isOpenn, setIsOpenn] = useState();
  const [getGameData, setGameData] = useState([]);
  const [getGames, setGames] = useState([]);
  const [eventmessage, setEventmessage] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkInd, setcheckInd] = useState([]);

  const handleSelectAll = (e) => {
    // let IsCheckAll = isCheckAll;
    let CheckInd = [...checkInd];
    console.log("🚀 ~ handleSelectAll ~ CheckInd:", CheckInd);
    if (e.target.checked) {
      //   IsCheckAll.push(index);
      setIsCheckAll(true);
      let ind = CheckInd;
      getGames.map((val, index) => {
        let isExist = ind.find((val1) => val1 == val.key);
        if (isEmpty(isExist)) {
          ind.push(val.key);
        }
      });
      setcheckInd(ind);
      handleselect(true);
      fetchOddsDetails({ sport_key: ind });
    } else {
      setIsCheckAll(false);
      getGames.map((it, index) => {
        let fidIndxx = CheckInd.findIndex((val) => val == it.key);
        CheckInd.splice(fidIndxx, 1);
        setcheckInd(CheckInd);
      });
      fetchOddsDetails({ sport_key: CheckInd });
    }
  };

  function handleChange(e, item, index) {
    let CheckInd = [...checkInd];
    console.log("🚀 ~ handleChange ~ CheckInd:", CheckInd, item);
    // let IsCheckAll = [...isCheckAll];
    // console.log("🚀 ~ handleChange ~ IsCheckAll:", IsCheckAll);
    if (e.target.checked) {
      CheckInd.push(item);
      setcheckInd(CheckInd);
      handleselect(true);
      console.log("🚀 ~ handleChange ~ CheckInd:", CheckInd, item);
      fetchOddsDetails({ sport_key: CheckInd });
    } else {
      //   let fidIndxx = IsCheckAll.findIndex((val) => val == index);
      //   IsCheckAll.splice(fidIndxx, 1);
      //   setIsCheckAll(IsCheckAll);
      let fidIndx = CheckInd.findIndex((val) => val == item);
      CheckInd.splice(fidIndx, 1);
      setcheckInd(CheckInd);
      handleselect(true);
      fetchOddsDetails({ sport_key: CheckInd });
    }
  }

  const fetchGameDatas = async () => {
    try {
      let { status, message, result } = await getGamedata(
        { game: gameType },
        dispatch
      );
      console.log(
        status,
        message,
        result,
        "status, message, resultstatus, message, result"
      );
      if (status == true) {
        setGames(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchOddsDetails = async (data) => {
    try {
      let { status, message, result } = await getOddsList(data, dispatch);
      if (status == true) {
        // setGameData(result);
      } else {
        setEventmessage(message);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    console.log(gameType, "gameType");
    fetchGameDatas();
  }, [gameType]);

  return (
    <>
      {/* <div className="accor_body"> */}
      <div className="d-flex align-items-center mb-2 check_box">
        <input
          type="checkbox"
          onChange={(e) => {
            handleSelectAll(e);
          }}
          checked={isCheckAll}
          //   id={index}
          // checked={SelectDisplay && SelectDisplay}
        />
        <span className="ps-2">All</span>
      </div>
      {/* </div> */}

      {getGames &&
        getGames?.map((item, i) => {
          return (
            <div className="d-flex align-items-center mb-2 check_box">
              <input
                type="checkbox"
                checked={
                  checkInd && !isEmpty(checkInd.find((val) => val == item.key))
                    ? true
                    : false
                }
                onChange={(e) => {
                  handleChange(e, item.key, i);
                }}
                id={`child${i}`}
              />
              <span className="ps-2">{item.title}</span>
            </div>
          );
        })}
    </>
  );
}

export default SingelEvents;
