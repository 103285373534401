// import constant
import { SET_GAME_DATA } from "../constants";

const initialValue = {
  isAuthenticated: false,
  gameData: []
};
const gameData = (state = initialValue, action) => {
  switch (action.type) {
    case SET_GAME_DATA:
      return {
        ...state,
        gameData: action.data,
      };

    default:
      return state;
  }
};

export default gameData
