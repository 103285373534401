import React, { useEffect, useState } from "react";
import HomeHeader from "../../common-components/HomeHeader";
import HomeFooter from "../../common-components/HomeFooter";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import team_logo1 from "../../../asset/img/team_logo1.png";
import team_logo2 from "../../../asset/img/team_logo2.png";
import leftvector from "../../../asset/img/leftvector.svg";
import star from "../../../asset/img/star.png";
import starFill from "../../../asset/img/star_fill.png";
import blustar from "../../../asset/img/star_fill.png";
import Parlay from "../TabSection/Parlay";
import Star from "../../../asset/img/star1.png";
import StarFill from "../../../asset/img/star_fill.png";
const Favoritescont = () => {
  useEffect(() => {}, []);

  // const [favorites, setFavorites] = useState([
  //   { id: 1, name: "NHL" },
  //   { id: 2, name: "Soccer - England" },
  //   { id: 3, name: "Basketball - Intl Leagues" },
  //   { id: 4, name: "NHL" },
  //   { id: 5, name: "NHL" },
  // ]);
  const [favLogo, setFavLogo] = useState(false);
  const handleFavourite = () => {
    setFavLogo(!favLogo);
  };

  const Games = [
    {
      id: 1,
      name: "NFL",
      star: StarFill,
      subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
      subtext2: "NFL - 1H:SPREADS - TOTALS",
      subtext3: "NFL - SUPER BOWL(TO WIN)",
      checked: false,
    },
    {
      id: 2,
      name: "College Basketball",
      star: Star,
      subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
      subtext2: "NFL - 1H:SPREADS - TOTALS",
      subtext3: "NFL - SUPER BOWL(TO WIN)",
      checked: false,
    },
    {
      id: 3,
      name: "NBA",
      star: StarFill,
      subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
      subtext2: "NFL - 1H:SPREADS - TOTALS",
      subtext3: "NFL - SUPER BOWL(TO WIN)",
    },
    {
      id: 4,
      name: "NBA",
      star: Star,
      subtext1: "NFL - GM:SPREADS-MONEY LN - TOTALS",
      subtext2: "NFL - 1H:SPREADS - TOTALS",
      subtext3: "NFL - SUPER BOWL(TO WIN)",
    },
  ];

  const Teams = [
    {
      id: 0,
      logo1: team_logo1,
      logo2: team_logo2,
      efl: "EFL TROPH",
      teamname: "England",
      gamename: "Football",
      leaguage: "Premiere League",
      mins: "73.60 Mins",
      alk: 0,
      pls: 3,
    },
    {
      id: 1,
      logo1: team_logo1,
      logo2: team_logo2,
      efl: "EFL TROPH",
      teamname: "England",
      gamename: "Football",
      leaguage: "Premiere League",
      mins: "73.60 Mins",
      alk: 0,
      pls: 3,
    },
    {
      id: 2,
      logo1: team_logo1,
      logo2: team_logo2,
      efl: "EFL TROPH",
      teamname: "England",
      gamename: "Football",
      leaguage: "Premiere League",
      mins: "73.60 Mins",
      alk: 0,
      pls: 3,
    },
    {
      id: 3,
      logo1: team_logo1,
      logo2: team_logo2,
      efl: "EFL TROPH",
      teamname: "England",
      gamename: "Football",
      leaguage: "Premiere League",
      mins: "73.60 Mins",
      alk: 0,
      pls: 3,
    },
  ];

  const [stars, setStars] = useState();

  const handleStar = (id) => {
    // setStar(!star);
    if (stars == id) {
      setStars();
    } else {
      setStars(id);
    }
  };
  return (
    <section>
      <div className="dashboard_content_sec">
        <div className="favorites mt-5 px-3">
          <h5 className="mb-0">
            Favorites <span className="gray">Matches</span>
          </h5>
          <div className="mt-4">
            <Parlay Games={Games} />
          </div>

          <div className="row">
            {Teams.map((item, index) => {
              return (
                <>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-3">
                    <div className="card_custom_head">
                      <div class="fborder text-start">
                        <div>
                          <img
                            src={item.logo1}
                            className="img-fluid teamlogo me-2"
                          />{" "}
                          <img
                            src={item.logo2}
                            className="img-fluid teamlogo"
                          />
                          <h6 className="mt-3 text-uppercase mb-2">
                            {item.efl}
                          </h6>
                          <p className="sprt mb-0">
                            {item.gamename} / {item.teamname}
                          </p>
                          <p className="sprt">{item.leaguage}</p>
                          <button type="button" class="btn btn-light">
                            <img src={leftvector} className="img-fluid me-2" />{" "}
                            Live Match
                          </button>
                        </div>
                        <div className="text-end">
                          <img
                            src={stars == item.id ? starFill : star}
                            className="img-fluid mb-2 custom_star_fill_icon"
                            // onClick={handleFavourite}
                            onClick={() => handleStar(item.id)}
                          />
                          <p className="teamp mt-3 mb-3">{item.mins}</p>
                          <p className="teamp">
                            <span className="me-2">ALK : {item.alk}</span> -{" "}
                            <span className="ms-2">3 : {item.pls}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-3">
              <div className="card_custom_head">
                <div class="fborder text-start">
                  <div>
                    <img src={team_logo1} className="img-fluid teamlogo me-2" />{" "}
                    <img src={team_logo2} className="img-fluid teamlogo" />
                    <h6 className="mt-3 text-uppercase mb-2">EFL TROPH</h6>
                    <p className="sprt mb-0">Football / England</p>
                    <p className="sprt">Premiere League</p>
                    <button type="button" class="btn btn-light">
                      <img src={leftvector} className="img-fluid me-2" /> Live
                      Match
                    </button>
                  </div>
                  <div className="text-end">
                    <img
                      src={favLogo ? star : starFill}
                      className="img-fluid mb-2 custom_star_fill_icon"
                      // onClick={handleFavourite}
                    />
                    <p className="teamp mt-3 mb-3">73.60 Mins</p>
                    <p className="teamp">
                      <span className="me-2">ALK : 0</span> -{" "}
                      <span className="ms-2">3 : PLS</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-3">
              <div className="card_custom_head">
                <div class="fborder text-start">
                  <div>
                    <img src={team_logo1} className="img-fluid teamlogo me-2" />{" "}
                    <img src={team_logo2} className="img-fluid teamlogo" />
                    <h6 className="mt-3 text-uppercase mb-2">EFL TROPH</h6>
                    <p className="sprt mb-0">Football / England</p>
                    <p className="sprt">Premiere League</p>
                    <button type="button" class="btn btn-light">
                      <img src={leftvector} className="img-fluid me-2" /> Live
                      Match
                    </button>
                  </div>
                  <div className="text-end">
                    <img
                      src={favLogo ? star : starFill}
                      className="img-fluid mb-2 custom_star_fill_icon"
                      // onClick={handleFavourite}
                    />
                    <p className="teamp mt-3 mb-3">73.60 Mins</p>
                    <p className="teamp">
                      <span className="me-2">ALK : 0</span> -{" "}
                      <span className="ms-2">3 : PLS</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mb-3">
              <div className="card_custom_head">
                <div class="fborder text-start">
                  <div>
                    <img src={team_logo1} className="img-fluid teamlogo me-2" />{" "}
                    <img src={team_logo2} className="img-fluid teamlogo" />
                    <h6 className="mt-3 text-uppercase mb-2">EFL TROPH</h6>
                    <p className="sprt mb-0">Football / England</p>
                    <p className="sprt">Premiere League</p>
                    <button type="button" class="btn btn-light">
                      <img src={leftvector} className="img-fluid me-2" /> Live
                      Match
                    </button>
                  </div>
                  <div className="text-end">
                    <img
                      src={favLogo ? star : starFill}
                      className="img-fluid mb-2 custom_star_fill_icon"
                      // onClick={handleFavourite}
                    />
                    <p className="teamp mt-3 mb-3">73.60 Mins</p>
                    <p className="teamp">
                      <span className="me-2">ALK : 0</span> -{" "}
                      <span className="ms-2">3 : PLS</span>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Favoritescont;
