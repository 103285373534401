import key from '../config/config';
import * as api from "../routes/userroutefront";
import axios, { setAuthorization } from "../config/axios";
import {
    SET_SETTINGS_DATA
  } from "../constants";


export const setSiteSetting = (data) => {
    // console.log('setSiteSetting---->',data)
    return {
      type: SET_SETTINGS_DATA,
      data: data,
    };  
  };



export const getsiteSettings = async (dispatch) => {
    try {
        let respData = await axios({
            'url': key.API_URL + api.getSiteSetting,
            'method': 'get',
        });
        // console.log("setSiteSetting",respData)
        dispatch(setSiteSetting(respData?.data?.result))

        return {
            status: respData?.data?.status,
            message: respData?.data?.message,
            result: respData?.data?.result
        }
    }
    catch (err) {
        // console.log(err, 'setSiteSetting_error')
        return {
            status: err?.response?.data?.status,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}