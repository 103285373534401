import React, { useEffect, useState } from "react";
import Logo from "../../../asset/img/logo.png";
import search from "../../../asset/img/search.png";
import { useLocation, useHistory } from "react-router-dom";

import Home from "../../../asset/img/home.png";
import Live from "../../../asset/img/live.png";
import Favourite from "../../../asset/img/favourite.png";
import Football from "../../../asset/img/football.png";
import Basketball from "../../../asset/img/basketball.png";
import Baseball from "../../../asset/img/baseball.png";
import Upcoming from "../../../asset/img/upcoming.png";
import ticket from "../../../asset/img/ticket.svg";
import { Link } from "react-router-dom";
import closeBtn from "../../../asset/img/modal_close_btn.png";
import { getGamedata } from "../../actions/gameSettings";
import { useDispatch } from "react-redux";
import { SELECT_SPORT } from "../../constants";
function Sidebar({ onhide }) {
  const location = useLocation();

  const Location = location.pathname;
  const [Gamedata, setGameData] = useState();
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    fetchGameData();
  }, []);

  const fetchGameData = async () => {
    try {
      let { status, message, result } = await getGamedata(dispatch);
      if (status == true) {
        setGameData(result);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const RedirectDash = async () => {
    history.push("/dashboard");
  };

  return (
    <div className="sidebar_sec">
      <div className="logo_sec">
        <Link to="/">
          <img src={Logo} alt="logo" className="img-fluid logo" />
        </Link>
        <img
          src={closeBtn}
          alt="close Btn"
          className="img-fluid close_offcanvas_logo"
          onClick={onhide}
        />
      </div>
      {/* <div className="search_input">
        <img src={search} alt="search" className="img-fluid" />
        <input type="text" placeholder="Search" />
      </div> */}
      <div className="navigation heading">
        <p className="heading_txt">Navigation</p>
        <div className="sub_heading_list">
          <Link to="/" className="text-decoration-none">
            <div
              className={
                Location == "/"
                  ? "mb-3 cmn_sub_heading_active"
                  : " mb-3 cmn_sub_heading"
              }>
              <div className="d-flex align-items-center">
                <img src={Home} alt="home" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Home</span>
              </div>
            </div>
          </Link>
          <Link to="/livemobius" className="text-decoration-none">
            <div
              className={
                Location == "/live"
                  ? "mb-3 cmn_sub_heading_active"
                  : " mb-3 cmn_sub_heading"
              }>
              <div className="d-flex align-items-center">
                <img src={Live} alt="live" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Live</span>
              </div>
            </div>
          </Link>
          {/* <Link to="/favorites" className="text-decoration-none">
            <div
              // className="mb-0 cmn_sub_heading"
              className={
                Location == "/favorites"
                  ? "mb-3 cmn_sub_heading_active"
                  : " mb-3 cmn_sub_heading"
              }>
              <div className="d-flex align-items-center">
                <img src={Favourite} alt="favorites" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Favorites</span>
              </div>
            </div>
          </Link> */}
          <Link to="/support_ticket" className="text-decoration-none">
            <div
              // className="mb-0 cmn_sub_heading"
              className={
                Location == "/support_ticket"
                  ? "mb-0 cmn_sub_heading_active"
                  : " mb-0 cmn_sub_heading"
              }>
              <div className="d-flex align-items-center">
                <img src={ticket} alt="favorites" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Support Ticket</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="Sports heading">
        <p className="heading_txt">Sports</p>
        <div
          className="mb-0 cmn_sub_heading"
          onClick={(event) => {
            dispatch({
              type: SELECT_SPORT,
              data: "All",
            });
            RedirectDash();
          }}>
          <div
            className="d-flex align-items-center"
            onClick={() => {
              RedirectDash();
            }}>
            {/* <img src={Upcoming} alt="Upcomings" className="img-fluid" /> */}
            <span className="sub_heading_txt ps-2">A - Z BETTING</span>
          </div>
        </div>
        <div className="sub_heading_list">
          {Gamedata &&
            Gamedata.map((item, index) => {
              return (
                <Link to="/dashboard" className="text-decoration-none">
                  <div
                    className="mb-3 cmn_sub_heading"
                    onClick={(event) => {
                      dispatch({
                        type: SELECT_SPORT,
                        data: item._id,
                      });
                    }}>
                    <div className="d-flex align-items-center">
                      {/* <img
                        src={Football}
                        alt="Football"
                        className="img-fluid"
                      /> */}

                      <span className="sub_heading_txt ps-2">{item._id}</span>
                    </div>
                  </div>
                </Link>
              );
            })}

          {/* <Link to="/dashboard" className="text-decoration-none">
            <div className="mb-3 cmn_sub_heading">
              <div className="d-flex align-items-center">
                <img src={Basketball} alt="Basketball" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Basketball</span>
              </div>
            </div>
          </Link>
          <Link to="/dashboard" className="text-decoration-none">
            <div className="mb-3 cmn_sub_heading">
              <div className="d-flex align-items-center">
                <img src={Baseball} alt="Baseball" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Baseball</span>
              </div>
            </div>
          </Link> */}
          {/* <Link to="/dashboard" className="text-decoration-none">
            <div className="mb-0 cmn_sub_heading">
              <div className="d-flex align-items-center">
                <img src={Upcoming} alt="Upcomings" className="img-fluid" />
                <span className="sub_heading_txt ps-2">Upcomings</span>
              </div>
            </div>
          </Link> */}
        </div>
        {/* <div className="AtoZ_bettings mt">A - Z BETTING</div> */}
      </div>
    </div>
  );
}

export default Sidebar;
