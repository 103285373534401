import { isEmpty } from '../lib/isEmpty';



export const validateContactUs = (value) => {
    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
    var letters = /^[A-Za-z\s]*$/
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i
    var phoneNo = /^(\+\d{1,3}[- ]?)?\d{10}$/




    if (isEmpty(value.name)) {
        errors.name = "Name field is required"
    } else if (!(letters.test(value.name))) {
        errors.name = "Invalid Name"
    }

    if (isEmpty(value.message)) {
        errors.message = "Message field is required"
    }

    if (isEmpty(value.email)) {
        errors.email = "Email ID field is required"
    } else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid Email ID"
    }

    if (isEmpty(value.PhoneNo)) {
        errors.PhoneNo = "Phone Number field is required"
    } else if (!(phoneNo.test(value.PhoneNo))) {
        errors.PhoneNo = "Invalid Phone Number"
    }

    if (isEmpty(value?.attachment?.name?.match(allowedExtensions))) {
        errors.attachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
    }
    if (value?.attachment !== "" && typeof value.attachment  == 'object') {
        if (isEmpty(value?.attachment?.name?.match(allowedExtensions))) {
            errors.attachment = "Please upload file having extensions  .png,.jpeg,.jpg only."
        }
        else {
            var filesize = value?.attachment?.size
 
            if (filesize > 0.5 * 1024 * 1024) {
                errors.attachment = "Image size should be less than 500 Kb"
            }
        }
    }

    return errors;
}